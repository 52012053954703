import { ReactNode } from 'react';
import { Audit, AuditableAction, AuditableType } from '../api/AuditsApiClient';
import localize from '../localize';
import { Link } from '@mui/material';

type ParsedAuditedChange = {
  label: string;
  content: ReactNode[];
};

type GroupedAudits = {
  [key: string]: Audit[];
};

const orderFields: { [key: string]: string } = {
  user_id: 'Клієнт (дропшипер)',
  customer_id: 'Замовник',
  promotion_id: 'Промо-акція',
  friendly_id: 'Ідентифікатор',
  sum: 'Сума',
  last_status: 'Cтатус',
  files: 'Файли',
  delivery_operator: 'Оператор доставки',
  delivery_address: 'Адреса доставки',
  delivery_ttn: 'ТТН',
  delivery_recipient_name: 'Отримувач - імʼя',
  delivery_recipient_phone: 'Отримувач - телефон',
  fixed_client_discount: 'Знижка клієнта (дропшипера)',
  fixed_customer_discount: 'Знижка замовника (покупця)',
};

const orderItemFields: { [key: string]: string } = {
  product_modification_id: 'Продукт (модифікація)',
  count: 'Кількість',
  fixed_price: 'Ціна (за одиницю)',
  friendly_id: 'Ідентифікатор',
};

const auditedFields: { [key in AuditableType]: { [key: string]: string } } = {
  Order: orderFields,
  OrderItem: orderItemFields,
};

const modifiers: { [key: string]: (v?: string) => string } = {
  last_status: (value?: string): string =>
    value ? localize.order_statuses.values[value] : value,
};

const auditKeyPathMapper: { [key: string]: string } = {
  product_modification_id: '/cp/products/',
  promotion_id: '/cp/promotions/',
  customer_id: '/cp/customers/',
  user_id: '/cp/users/unknown/',
};

export const auditedTypeLabels: { [key in AuditableType]: string } = {
  Order: 'Замовлення',
  OrderItem: 'Елемент замовлення',
};

export const auditedActionLabels: { [key in AuditableAction]: string } = {
  create: 'створено',
  update: 'оновлено',
  destroy: 'видалено',
};

export const groupByRequestUUID = (items: Audit[]): GroupedAudits => {
  const grouped: GroupedAudits = {};

  items.forEach((item) => {
    if (!grouped[item.request_uuid]) {
      grouped[item.request_uuid] = [];
    }
    grouped[item.request_uuid].push(item);
  });

  return grouped;
};

export const parsedAuditedChanges = (audit: Audit): ParsedAuditedChange[] => {
  if (!audit.changes || !Object.keys(audit.changes)?.length) return [];

  const res: ParsedAuditedChange[] = [];
  const fields = auditedFields[audit.auditable_type];

  try {
    Object.keys(audit.changes).map((auditKey) => {
      if (Object.keys(fields).includes(auditKey)) {
        const response: string[] = (
          Array.isArray(audit.changes[auditKey])
            ? audit.changes[auditKey]
            : [audit.changes[auditKey]]
        ) as string[];
        let result: ReactNode[] = response.map((el: string) => {
          if (modifiers[auditKey]) {
            return modifiers[auditKey](el);
          } else {
            const match = (el?.toString() || '').match(/^\[(.+?)\]\((.+?)\)$/);
            if (match) {
              return (
                <Link href={`${auditKeyPathMapper[auditKey]}${match[2]}`}>
                  {match[1]}
                </Link>
              );
            } else {
              return el;
            }
          }
        });

        if (!!result?.filter((v) => !!v)?.length)
          res.push({
            label: fields[auditKey],
            content: result,
          });
      }
    });
  } catch {}

  return res;
};
