import { FC, useContext, useEffect, useMemo, useState } from 'react';

import { styled } from '@mui/material/styles';
import {
  Typography,
  Paper,
  IconButton,
  InputBase,
  Tabs,
  Tab,
} from '@mui/material';

import { AnyObject } from '../../api/anyObjectTypes';
import { RequestFormsApiClient } from '../../api/RequestFormsApiClient';
import { CommonContext } from '../../contexts/CommonContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import { BasicTable } from '../../components/BaseTable/BaseTable';
import Loading from '../../components/Loading/Loading';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import { useAsyncError } from '../../hooks/useAsyncError';
import localize from '../../localize';

import { ReactComponent as SearchIconSvg } from '../../assets/search.svg';
import { formatDateTime } from '../../utils/time';

const InnerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  height: '100%',
  width: '100%',
}));

const NumberText = styled('span')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'Montserrat, sans-serif',
  fontSize: '9px',
  lineHeight: '11px',
  fontWeight: 700,
  color: '#464646',
  borderRadius: '50%',
  width: '32px',
  height: '32px',
  marginLeft: '5px',
}));

const statusArray = ['all', 'new', 'done'];
const columns = [
  {
    field: 'created_at',
    align: 'right',
    headerName: `${localize.api_integration.api_keys.table_fields.created_at}`,
    valueGetter: (row: any) => row.created_at && formatDateTime(row.created_at),
    width: '150px',
  },
  {
    field: 'name',
    align: 'left',
    headerName: `${localize.api_integration.request_form.table_fields['name']}`,
    width: '150px',
  },
  {
    field: 'email',
    align: 'left',
    headerName: `${localize.api_integration.request_form.table_fields['email']}`,
  },
  {
    field: 'phone_number',
    align: 'right',
    headerName: `${localize.api_integration.request_form.table_fields['phone_number']}`,
  },
  {
    field: 'status',
    align: 'right',
    headerName: `${localize.api_integration.request_form.table_fields['status']}`,
    valueGetter: (row: any) =>
      row.status === 'new' ? (
        <Typography component='span' color='#FFBF1C'>
          {localize.api_integration.request_form.status.new}
        </Typography>
      ) : (
        <Typography component='span' color='#29D745'>
          {localize.api_integration.request_form.status.done}
        </Typography>
      ),
  },
  {
    field: 'comment',
    align: 'right',
    headerName: `${localize.api_integration.request_form.table_fields['comment']}`,
    valueGetter: (row: any) =>
      row.comment && <Typography component='span'>{row.comment}</Typography>,
  },
];

export const RequestFormsApiClientPage: FC = () => {
  const { currentProject } = useContext(CommonContext);
  const throwError = useAsyncError();
  const [rows, setRows] = useState<AnyObject[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [tabsValue, setTabsValue] = useState(statusArray[0]);
  const { setNotification } = useContext(NotificationContext);

  const preparePageData = async (): Promise<void> => {
    const apiRows = await RequestFormsApiClient.getIndex(
      currentProject.id
    ).catch((e) => {
      throwError(e);
    });

    setRows(apiRows || []);
  };

  const deleteItem = (item: AnyObject) => {
    RequestFormsApiClient.remove(currentProject.id, item.id)
      .then(() => {
        setRows(rows.filter((row) => row.id !== item.id));
        setNotification({
          severity: 'success',
          message: localize.general.successDeleteMessage,
        });
      })
      .catch((e) => {
        setNotification({
          severity: 'warning',
          message: localize.general.unexpectedError,
        });
      });
  };

  const updateItem = (item: AnyObject) => {
    RequestFormsApiClient.update(
      currentProject.id,
      { ...item, status: 'done' },
      item.id
    )
      .then((newItem) => {
        setRows(rows.map((row) => (row.id !== item.id ? row : newItem)));
        setNotification({
          severity: 'success',
          message: localize.general.successUpdateMessage,
        });
      })
      .catch((e) => {
        setNotification({
          severity: 'warning',
          message: localize.general.unexpectedError,
        });
      });
  };

  const filteredList = useMemo(() => {
    const filteredBySearch = () => {
      if (searchQuery) {
        return rows.filter(
          (row) =>
            !!Object.values(row).find((value) =>
              typeof value === 'string'
                ? `${value.toLowerCase()}`.search(searchQuery.toLowerCase()) !==
                  -1
                : false
            )
        );
      }
      return rows;
    };

    if (tabsValue === 'all') {
      return filteredBySearch();
    } else {
      return filteredBySearch().filter((row) => row.status === tabsValue);
    }
  }, [rows, tabsValue, searchQuery]);

  useEffect(() => {
    setLoading(true);
    preparePageData().finally(() => setLoading(false));
  }, [currentProject]);

  if (loading) return <Loading />;

  return (
    <InnerContainer>
      <Paper
        component='form'
        sx={{
          height: 40,
          display: 'flex',
          alignItems: 'center',
          width: 228,
          backgroundColor: '#fff',
          borderRadius: '8px',
          border: '1px solid #DFDDF9',
          '&:hover, &:focus': {
            border: '1px solid #AFABEF',
          },
        }}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <IconButton type='button' aria-label='search' sx={{ p: '8px' }}>
          <SearchIconSvg />
        </IconButton>
        <InputBase
          sx={{ flex: 1 }}
          placeholder={localize.sidebar.search}
          name={localize.sidebar.search}
          value={searchQuery}
          onChange={(event) => {
            setSearchQuery(event.target.value);
          }}
        />
      </Paper>
      {!!rows.length && (
        <Tabs
          value={tabsValue}
          onChange={(event, newValue) => {
            setTabsValue(newValue);
          }}
        >
          {statusArray.map((item) => (
            <Tab
              key={item}
              value={item}
              label={
                <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                  {localize.api_integration.request_form.tabs_btn[item]}{' '}
                  <NumberText
                    sx={{
                      background: item === tabsValue ? '#C8FFD7' : '#f2f2f2',
                    }}
                  >
                    {item === 'all'
                      ? rows.length
                      : rows.filter((row) => row.status === item).length}
                  </NumberText>
                </Typography>
              }
            />
          ))}
        </Tabs>
      )}
      {!!rows.length ? (
        <BasicTable
          rows={filteredList}
          columns={columns}
          onDeleteClick={deleteItem}
          onDoneClick={updateItem}
        />
      ) : (
        <EmptyContent />
      )}
    </InnerContainer>
  );
};

export default RequestFormsApiClientPage;
