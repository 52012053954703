import { FC, ReactNode, useMemo } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

type LoadingProps = {
  fullPage?: boolean;
  text?: ReactNode;
};

const InnerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
  justifySelf: 'center',
  minWidth: '100%',
}));

const FullPageContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  minWidth: '100vw',
}));

export const Loading: FC<LoadingProps> = ({ fullPage = false, text }) => {
  const Container = useMemo(
    () => (fullPage ? FullPageContainer : InnerContainer),
    [fullPage]
  );

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <CircularProgress color='primary' />
        <br />
        {text}
      </Box>
    </Container>
  );
};

export default Loading;
