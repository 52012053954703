import { get, destroy, post, put } from './request';
import { AnyObject } from './anyObjectTypes';

export type ManufactureRequest = {
  id: string;
  count: number;
  status: string;
  comment: string;
  product_title: string;
  product_modification_title: string;
};

export class ManufactureRequestApiClient {
  static getIndex(projectId: string): Promise<ManufactureRequest[]> {
    return get(`projects/${projectId}/manufacture_requests`);
  }

  static getShow(
    projectId: string,
    itemId: string
  ): Promise<ManufactureRequest> {
    return get(`projects/${projectId}/manufacture_requests/${itemId}`);
  }

  static remove(projectId: string, itemId: string): Promise<string> {
    return destroy(`projects/${projectId}/manufacture_requests/${itemId}`);
  }

  static create(
    projectId: string,
    requestBody: AnyObject
  ): Promise<ManufactureRequest> {
    return post(`projects/${projectId}/manufacture_requests`, requestBody);
  }

  static update(
    projectId: string,
    requestBody: AnyObject,
    itemId: string
  ): Promise<ManufactureRequest> {
    return put(
      `projects/${projectId}/manufacture_requests/${itemId}`,
      requestBody
    );
  }
}
