import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import { UserApiClient } from '../api/UserApiClient';
import { CommonContext } from './CommonContext';

type UserType = 'dropshipper' | 'manufacturer';
type OnboardingContextType = {
  step: number;
  nextStep: () => void;
  prevStep: () => void;
  goToStep: (stepNumber: number) => void;
  resetSteps: () => void;
  handlePath: (selectedPath: UserType) => void;
  getTotalSteps: () => number;
  path: UserType;
};

const OnboardingMultiStepContext = createContext<
  OnboardingContextType | undefined
>(undefined);

export const useOnboardingMultiStep = (): OnboardingContextType => {
  const context = useContext(OnboardingMultiStepContext);
  if (!context) {
    throw new Error('useMultiStep must be used within a OnboardingProvider');
  }
  return context;
};

export const OnboardingProvider = ({ children }: { children: ReactNode }) => {
  const { currentUser } = useContext(CommonContext);
  const [step, setStep] = useState<number>(0);
  const [path, setPath] = useState<UserType>('manufacturer');

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step > 0 ? step - 1 : step);
  const goToStep = (stepNumber: number) => setStep(stepNumber);
  const resetSteps = () => setStep(0);

  const getTotalSteps = () => {
    if (path === 'manufacturer') {
      return 4;
    } else if (path === 'dropshipper') {
      return 3;
    }

    return 0;
  };

  const handlePath = (selectedPath: UserType) => setPath(selectedPath);

  useEffect(() => {
    if (currentUser?.id)
      UserApiClient.updateSelf(
        {
          user: {
            onboardstatus: `${path} - ${step}`,
            ...(getTotalSteps() === step ? { onboarded: true } : {}),
          },
        },
        currentUser?.id
      );
  }, [step, path, currentUser]);

  return (
    <OnboardingMultiStepContext.Provider
      value={{
        step,
        nextStep,
        prevStep,
        goToStep,
        getTotalSteps,
        resetSteps,
        handlePath,
        path,
      }}
    >
      {children}
    </OnboardingMultiStepContext.Provider>
  );
};
