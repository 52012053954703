import { FC, useContext } from 'react';
import Page from '../components/Page/Page';
import { Grid } from '@mui/material';
import { BasicTable } from '../components/BaseTable/BaseTable';
import { CommonContext } from '../contexts/CommonContext';
import { baseMainFields } from './users';
import { AnyObject } from '../api/anyObjectTypes';
import localize from '../localize';

type ProfilePageProps = {};

export const ProfilePage: FC<ProfilePageProps> = () => {
  const { currentUser } = useContext(CommonContext);

  return (
    <Page
      title={localize.profile.show.title}
      actions={[
        { title: localize.profile.show.editButton, link: `/cp/profile-edit` },
      ]}
    >
      <Grid
        container
        spacing={10}
        alignItems='flex-start'
        alignSelf='flex-start'
      >
        <Grid item xs={12}>
          <BasicTable
            rows={[{ ...currentUser, id: currentUser.auth0_id }]}
            columns={baseMainFields as AnyObject[]}
            colorRow
          />
        </Grid>
      </Grid>
    </Page>
  );
};

export default ProfilePage;
