import { FC } from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import localize from '../../localize';

type ErrorContainerProps = {
  message: string;
  code: number;
};

const InnerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
  justifySelf: 'center',
  flexDirection: 'column',
  minWidth: '100%',
  minHeight: '80%',
}));

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  minHeight: '100vh',
  flexDirection: 'column',
  height: '100vh',
  maxHeight: '100vh',
  margin: '0',
  backgroundColor: 'rgba(255,255,255,1)',
  flex: '1 1 auto',
}));

export const ErrorContainer: FC<ErrorContainerProps> = ({ code, message }) => {
  return (
    <Container>
      <InnerContainer>
        <Typography variant='h1' sx={{ opacity: '0.4', fontSize: '100px' }}>
          {code}
        </Typography>
        <Typography variant='h4' sx={{ opacity: '0.4' }}>
          {message}
        </Typography>
        {code === 500 && (
          <Typography variant='h4' sx={{ opacity: '0.4' }}>
            {localize.errors.contactUs}
          </Typography>
        )}
      </InnerContainer>
    </Container>
  );
};

export default ErrorContainer;
