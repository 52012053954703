import React from 'react';
import localize from '../../../../localize';
import { ColorSection, ContainerInner, MainText, MainTitle } from '../../Price';

export const PlanTextSection = () => {
  return (
    <ColorSection>
      <ContainerInner sx={{ justifyContent: 'center' }}>
        <MainTitle variant='h2'>
          {localize.landing.pricingPage.everyPlanSectionTitle}
        </MainTitle>
        <MainText>
          {localize.landing.pricingPage.everyPlanSectionDescription}
        </MainText>
      </ContainerInner>
    </ColorSection>
  );
};
