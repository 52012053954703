import React, { useContext, useRef } from 'react';
import { Box, Button, Modal, styled, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import localize from '../../../localize';
import { create } from '../api/RequestForm';
import { NotificationContext } from '../../../contexts/NotificationContext';

type ModalProps = {
  open: boolean;
  handleClose: () => void;
  title: string;
  description?: string;
  imgPath: string;
  note?: string;
  handleSubmit: () => void;
};

const StyledBox = styled(Box)(() => ({
  position: 'relative',
  top: 0,
  left: 0,
  display: 'flex',
  flexDirection: 'column',
  width: '950px',
  margin: '16px',
  backgroundColor: '#fff',
  fontFamily: 'Montserrat, sans-serif',
  borderRadius: '32px',
  padding: '32px 32px 64px 32px',
}));

const ContactForm = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '550px',
  width: '100%',
  alignItems: 'center',
  label: {
    display: 'flex',
    fontSize: '16px',
    width: '100%',
    fontWeight: 600,
    flexDirection: 'column',
    gap: '8px',
    fontFamily: 'Montserrat, sans-serif',
  },
  input: {
    display: 'flex',
    fontSize: '16px',
    border: '1px solid #d8d8d8',
    borderRadius: '20px',
    outline: '1px solid transparent',
    fontWeight: 400,
    width: '100%',
    padding: '8px 16px',
    fontFamily: 'Montserrat, sans-serif',
    '&::placeholder': {
      color: '#B3B3B3',
    },
  },
}));

const CloseButton = styled('button')(() => ({
  position: 'absolute',
  top: '32px',
  right: '32px',
  width: '48px',
  height: '48px',
  backgroundColor: 'transparent',
  border: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
}));
const ModalMainContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '10px',
  alignItems: 'flex-start',
}));

const FormInner = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: '16px',
  width: '100%',
}));

const StyledImg = styled('img')(() => ({
  maxWidth: '100%',
}));

export const ContactModal = ({
  open,
  handleClose,
  handleSubmit,
  imgPath,
  title,
  description,
  note,
}: ModalProps) => {
  const nameInputRef = useRef<HTMLInputElement>(null);
  const emailOrPhoneInputRef = useRef<HTMLInputElement>(null);
  const messageInputRef = useRef<HTMLInputElement>(null);
  const { setNotification } = useContext(NotificationContext);

  const onFormSubmit = () => {
    if (
      emailOrPhoneInputRef.current?.value ||
      nameInputRef.current?.value ||
      messageInputRef.current?.value
    )
      create({
        name: nameInputRef.current?.value,
        email: emailOrPhoneInputRef.current?.value,
        comment: messageInputRef.current?.value,
      }).then(() => {
        setNotification({
          severity: 'success',
          message: localize.landing.successSentMessage,
        });
        if (nameInputRef.current?.value) nameInputRef.current.value = '';
        if (emailOrPhoneInputRef.current?.value)
          emailOrPhoneInputRef.current.value = '';
        if (messageInputRef.current?.value) messageInputRef.current.value = '';
        handleClose();
      });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <StyledBox>
        <CloseButton onClick={handleClose}>
          <Close sx={{ fontSize: '28px' }} />
        </CloseButton>
        <Typography
          sx={{
            textAlign: 'center',
            fontWeight: 500,
            marginBottom: '48px',
            fontSize: '34px',
            fontFamily: 'Montserrat, sans-serif',
          }}
          variant='h2'
          component='h4'
        >
          {title}
        </Typography>
        {description && (
          <Typography
            id='modal-modal-description'
            variant='body1'
            sx={{
              marginBottom: '32px',
              fontSize: '14px',
              textAlign: 'center',
              fontFamily: 'Montserrat, sans-serif',
            }}
          >
            {description}
          </Typography>
        )}

        <ModalMainContent>
          <StyledImg src={imgPath} width={280} height={280} alt={title} />
          <ContactForm onSubmit={handleSubmit}>
            <FormInner>
              <label>
                {localize.landing.modal.formLabels.name} {'*'}
                <input
                  type='text'
                  ref={nameInputRef}
                  id='name'
                  placeholder={localize.landing.modal.formLabels.name}
                  required
                />
              </label>
              <label>
                {localize.landing.modal.formLabels.emailOrPhone} {'*'}
                <input
                  type='text'
                  ref={emailOrPhoneInputRef}
                  pattern='^([\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6})|([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)$'
                  id='email-phone'
                  placeholder={localize.landing.modal.formLabels.emailOrPhone}
                  required
                />
              </label>
              <label>
                {localize.landing.modal.formLabels.message}{' '}
                {messageInputRef.current?.required && '*'}
                <input
                  ref={messageInputRef}
                  type='text'
                  id='message'
                  placeholder={localize.landing.modal.formLabels.message}
                />
              </label>
            </FormInner>
            {note && (
              <Typography
                sx={{
                  marginTop: '24px',
                  fontSize: '12px',
                  textAlign: 'left',
                  fontFamily: 'Montserrat, sans-serif',
                }}
              >
                {note}
              </Typography>
            )}
            <Button
              variant='rounded'
              sx={{ marginTop: '48px' }}
              onClick={onFormSubmit}
            >
              {localize.landing.modal.formLabels.submit}
            </Button>
          </ContactForm>
        </ModalMainContent>
      </StyledBox>
    </Modal>
  );
};
