import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import Page from '../../components/Page/Page';
import { useNavigate } from 'react-router';
import { BasicTable } from '../../components/BaseTable/BaseTable';
import { CommonContext } from '../../contexts/CommonContext';
import Loading from '../../components/Loading/Loading';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import { BookingApiClient } from '../../api/BookingApiClient';
import { AnyObject } from '../../api/anyObjectTypes';
import { useAsyncError } from '../../hooks/useAsyncError';
import localize from '../../localize';
import { timeLeft } from '../../utils/time';
import { baseMainFields } from './BookingShowPage';
import { Chip } from '@mui/material';

const InnerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
}));

const FilterContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
}));

const FilterButton = styled(Chip)(({ theme }) => ({
  marginRight: '20px',
  boxShadow:
    'rgb(234, 236, 240) 0px 0px 1px, rgba(29, 41, 57, 0.08) 0px 1px 2px',
  border: 0,
}));

export const BookingIndexPage: FC = () => {
  const defaultColumns = [
    {
      field: 'product_title',
      align: 'left',
      headerName: localize.bookings.columns.product_title,
    },
    {
      field: 'product_modification_title',
      align: 'left',
      headerName: localize.bookings.columns.product_modification_title,
    },
    ...baseMainFields,
  ];
  const { currentProject, currentUser } = useContext(CommonContext);
  const throwError = useAsyncError();
  const navigate = useNavigate();
  const [rows, setRows] = useState<AnyObject[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [activeStatusFilter, setActiveStatusFilter] = useState('All');
  const rowsToDisplay = useMemo(
    () =>
      activeStatusFilter === 'All'
        ? rows
        : activeStatusFilter === 'Active'
          ? rows.filter(
              (item) =>
                item.status === activeStatusFilter &&
                timeLeft(new Date(item.enddate)) !== '0'
            )
          : activeStatusFilter === 'Disabled'
            ? rows.filter(
                (item) =>
                  item.status === activeStatusFilter ||
                  (item.status === 'Active' &&
                    timeLeft(new Date(item.enddate)) === '0')
              )
            : rows.filter((item) => item.status === activeStatusFilter),
    [rows, activeStatusFilter]
  );

  const onFilterItemClick = (status: string) => {
    setActiveStatusFilter(status);
  };

  const actions = useMemo(() => {
    const addAction = {
      title: localize.bookings.index.addButton,
      link: `/cp/bookings/create`,
    };

    if (!currentUser.user_permissions.booking?.create?.length) return [];

    return [addAction];
  }, [currentUser]);

  const preparePageData = async (): Promise<void> => {
    const apiRows = await BookingApiClient.getIndex(currentProject.id).catch(
      (e) => {
        throwError(e);
      }
    );

    setRows(apiRows || []);
  };

  useEffect(() => {
    setLoading(true);
    preparePageData().finally(() => setLoading(false));
  }, [currentProject]);

  if (loading)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page title={localize.bookings.index.title} actions={actions}>
      <InnerContainer>
        <FilterContainer>
          {['All', 'Active', 'Disabled', 'Stock updated'].map((el) => (
            <FilterButton
              key={el}
              label={`
                ${localize.bookings.status[el]} ${
                  el === 'All'
                    ? rows.length
                    : el === 'Active'
                      ? rows.filter(
                          (item) =>
                            item.status === el &&
                            timeLeft(new Date(item.enddate)) !== '0'
                        ).length
                      : el === 'Disabled'
                        ? rows.filter(
                            (item) =>
                              item.status === el ||
                              (item.status === 'Active' &&
                                timeLeft(new Date(item.enddate)) === '0')
                          ).length
                        : rows.filter((item) => item.status === el).length
                }`}
              variant='filled'
              color={activeStatusFilter === el ? 'primary' : 'default'}
              disabled={activeStatusFilter === el}
              onClick={() => onFilterItemClick(el)}
            />
          ))}
        </FilterContainer>
        <br />
        {!!rowsToDisplay.length ? (
          <BasicTable
            rows={rowsToDisplay}
            columns={defaultColumns}
            onRowClick={(row: AnyObject) => navigate(`/cp/bookings/${row.id}`)}
            onColapsedRowClick={(row: AnyObject) =>
              navigate(`/cp/bookings/${row.id}`)
            }
          />
        ) : (
          <EmptyContent />
        )}
      </InnerContainer>
    </Page>
  );
};

export default BookingIndexPage;
