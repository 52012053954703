import { Typography } from '@mui/material';
import localize from '../../localize';
import { styled } from '@mui/material/styles';

const SoonTextStyled = styled(Typography)(() => ({
  padding: '4px 16px',
  background: '#E8F9ED',
  borderRadius: '999px',
}));

export const SoonText = () => (
  <SoonTextStyled variant='subtitle1' color='#464646'>
    {localize.general.soon}
  </SoonTextStyled>
);
