import { post } from './request';
import { AnyObject } from './anyObjectTypes';

export class ProjectUploadApiClient {
  static create(
    projectId: string,
    params: string,
    requestBody: AnyObject
  ): Promise<any> {
    return post(`projects/${projectId}/uploads?${params}`, requestBody);
  }
}
