import { AnyObject } from './anyObjectTypes';
import { get, destroy, post, put } from './request';

export type RequestForms = {
  // :name, :phone_number, :email, :comment, :status, :created_at, :updated_at
};

export class RequestFormsApiClient {
  static getIndex(projectId: string): Promise<RequestForms[]> {
    return get(`projects/${projectId}/request_forms`);
  }

  static remove(projectId: string, itemId: string): Promise<string> {
    return destroy(`projects/${projectId}/request_forms/${itemId}`);
  }

  static update(
    projectId: string,
    requestBody: any,
    itemId: string
  ): Promise<RequestForms> {
    return put(`projects/${projectId}/request_forms/${itemId}`, requestBody);
  }

  static create(projectId: string, requestBody: any): Promise<AnyObject> {
    return post(`projects/${projectId}/request_forms`, requestBody);
  }
}
