import { FC, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CommonContext } from '../../contexts/CommonContext';
import Page from '../../components/Page/Page';
import { get } from '../../api/request';
import {
  TimelineOppositeContent,
  TimelineDot,
  TimelineContent,
  TimelineConnector,
  TimelineSeparator,
  TimelineItem,
  Timeline,
} from '@mui/lab';
import { timeSince } from '../../utils/time';
import { AnyObject } from '../../api/anyObjectTypes';
import localize from '../../localize';
import Loading from '../../components/Loading/Loading';

type StatusHistoryPageProps = {};

export const availableStatuses = ['Pending', 'Complete', 'Canceled', 'Blocked'];

export const StatusHistoryPage: FC<StatusHistoryPageProps> = () => {
  const { currentProject } = useContext(CommonContext);
  const { id } = useParams();
  const [statuses, setStatuses] = useState<AnyObject[]>([]);
  const [loading, setLoading] = useState(false);
  const TimelineComponent = Timeline as any;

  const preparePageData = async (id: string): Promise<void> => {
    const res = await get(
      `projects/${currentProject.id}/orders/${id}/order_statuses`
    );
    setStatuses(res);
  };

  useEffect(() => {
    if (!id) return;

    setLoading(true);
    preparePageData(id).finally(() => setLoading(false));
  }, [id]);

  if (loading)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page title={localize.order_statuses.index.title}>
      <TimelineComponent>
        {statuses.map((status, i) => (
          <TimelineItem key={status.id}>
            <TimelineOppositeContent color='text.secondary'>
              {timeSince(new Date(status.created_at))}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              {i !== statuses.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent variant='h4'>
              {localize.order_statuses.values[status.title]}
            </TimelineContent>
          </TimelineItem>
        ))}
      </TimelineComponent>
    </Page>
  );
};

export default StatusHistoryPage;
