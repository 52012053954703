import { useAuth0 } from '@auth0/auth0-react';
import {
  FC,
  createContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
  PropsWithChildren,
} from 'react';
import { AnyObject } from '../api/anyObjectTypes';
import { get } from '../api/request';
import { Template, TemplateApi } from '../api/TemplateApi';
import { useAsyncError } from '../hooks/useAsyncError';
import { useNavigate } from 'react-router-dom';

const emptyFun = (): void => {
  return;
};

type CommonProps = {
  setCurrentUser: (currentUser: AnyObject) => void;
  currentUser: AnyObject;
  setProjects: (projects: AnyObject[]) => void;
  projects: AnyObject[];
  setCurrentProject: (currentProject: AnyObject) => void;
  currentProject: AnyObject;
  loading: boolean;
  templates: Template[];
  switchProject: (projectId: string) => void;
  fetchTemplates: () => Promise<void>;
  fetchMainData: () => Promise<void>;
};

export const CommonContext = createContext<CommonProps>({
  setCurrentUser: emptyFun,
  currentUser: {},
  setProjects: emptyFun,
  projects: [],
  setCurrentProject: emptyFun,
  switchProject: emptyFun,
  fetchTemplates: () => Promise.resolve(),
  fetchMainData: () => Promise.resolve(),
  currentProject: {},
  loading: true,
  templates: [],
});

export const CommonProvider: FC<PropsWithChildren> = (props) => {
  const [currentUser, setCurrentUser] = useState<AnyObject>({});
  const [templates, setTemplates] = useState<Template[]>([]);
  const [projects, setProjects] = useState<AnyObject[]>([]);
  const [currentProject, setCurrentProject] = useState<AnyObject>({});
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    user,
  } = useAuth0();
  const throwError = useAsyncError();

  const switchProject = useCallback(
    (projectId: string) => {
      const projectToSwitchTo = projects.find((pr) => pr.id === projectId);
      localStorage.setItem('currentProjectId', projectId);
      if (projectToSwitchTo) {
        setCurrentProject(projectToSwitchTo);
        window.location.reload();
      }
    },
    [projects]
  );

  const fetchTemplates = useCallback(
    () =>
      TemplateApi.getIndex(currentProject.id).then((res) => setTemplates(res)),
    [currentProject]
  );

  const fetchMainData = async () => {
    const up = await get(`projects`);
    const au = await get(`users/${user?.sub}`);

    setProjects(up);

    const currProject =
      up.find(
        (up: AnyObject) => up.id === localStorage.getItem('currentProjectId')
      ) ||
      up[0] ||
      {};
    setCurrentProject(currProject);
    if (!currProject?.id) {
      setCurrentUser({ user_permissions: {}, ...user, ...au });
    } else {
      const projectUser = await get(
        `projects/${currProject.id}/users/${user?.sub}`
      );
      setCurrentUser({ ...projectUser, ...user, ...au });
    }
    localStorage.setItem('locale', 'ua');
  };

  useEffect(() => {
    setLoading(true);
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({ redirectUri: `${window.origin}/cp` });
    } else if (user?.sub) {
      const getMainData = async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            audience: `https://factorywise.com.ua`,
          });
          localStorage.setItem('accessToken', accessToken);
          await fetchMainData();
        } catch (e: any) {
          throwError(e);
        }
      };

      getMainData().finally(() => setLoading(false));
    }
  }, [isLoading, isAuthenticated, user?.sub]);

  useEffect(() => {
    if (!currentProject?.id) return;

    fetchTemplates();
  }, [currentProject]);

  const value = useMemo(
    () => ({
      loading,
      templates,
      currentUser,
      setCurrentUser,
      fetchTemplates,
      fetchMainData,
      projects,
      setProjects,
      currentProject,
      setCurrentProject,
      switchProject,
    }),
    [
      templates,
      currentUser,
      fetchTemplates,
      setCurrentUser,
      fetchMainData,
      projects,
      setProjects,
      currentProject,
      setCurrentProject,
      switchProject,
      loading,
    ]
  );

  return (
    <CommonContext.Provider value={value}>
      {props.children}
    </CommonContext.Provider>
  );
};

CommonProvider.displayName = 'CommonProvider';
