import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    elevation={0}
    sx={{ borderRadius: '4px' }}
    disableGutters
    {...props}
  />
))(({ theme }) => ({
  width: '100%',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:last-child > div + div': {
    boxShadow: '0 5px 5px -5px #e0e0e077',
  },
  '&:nth-of-type(even) > *[role="button"]': {
    backgroundColor: '#DFDDF9',
    borderBottom: 0,
  },

  '& .MuiCollapse-root': {
    borderBottom: 'none',
  },

  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<KeyboardArrowDownSharpIcon sx={{ fontSize: '1.2rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#F7F7FA',
  borderRadius: '4px',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': {
    transition: 'transform 0.2s ease',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(-180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(2),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  border: 'none',
}));

const AccordionWraper = styled('div')(({ theme }) => ({
  padding: '0 13px',
  display: 'flex',
  gap: '2px',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
}));

type AccordionData = {
  id: number;
  title: string;
  description: string;
};

export const CustomAccordion = ({ data }: { data: AccordionData[] }) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <AccordionWraper>
      {data.map(({ title, description, id }) => (
        <Accordion
          expanded={expanded === title}
          onChange={handleChange(title)}
          key={id}
        >
          <AccordionSummary
            aria-controls={`panel${id}d-content`}
            id={`panel${id}d-header`}
          >
            <Typography sx={{ fontWeight: '500' }}>{title}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ paddingLeft: '60px' }}>
            <Typography variant='body1' component={'p'}>
              {description}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </AccordionWraper>
  );
};
