import { get, destroy, post } from './request';

export type ApiKey = {
  [key: string]: any;
};

export class ApiKeysApiClient {
  static getIndex(projectId: string): Promise<ApiKey[]> {
    return get(`projects/${projectId}/api_keys`);
  }

  static remove(projectId: string, itemId: string): Promise<string> {
    return destroy(`projects/${projectId}/api_keys/${itemId}`);
  }

  static create(projectId: string): Promise<ApiKey> {
    return post(`projects/${projectId}/api_keys`, {});
  }
}
