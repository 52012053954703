import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Button, CssBaseline, Link, Typography } from '@mui/material';
import pinkBlobMain from '../../../assets/landing/pinkBlob.svg';
import creamBlobMain from '../../../assets/landing/creamBlob.svg';
import saly1 from '../../../assets/landing/salyFirst.png';
import doodle1 from '../../../assets/landing/doodle1.png';
import doodle2 from '../../../assets/landing/doodle2.png';
import doodle3 from '../../../assets/landing/doodle3.png';
import doodle4 from '../../../assets/landing/doodle4.png';
import localize from '../../../localize';
import HeroTextLine from '../../../assets/landing/line1.svg';
import { Header } from './Header';

const HeroContainer = styled('div')(({ theme }) => ({
  fontFamily: 'Montserrat, Rubik, sans-serif',
  width: '100%',
  position: 'relative',
  height: '100vh',
  color: '#0c0c0c',
  background: `
  url(${doodle2}) left 45% top 10% no-repeat,
  url(${pinkBlobMain}) no-repeat right top / 900px,
  url(${creamBlobMain}) no-repeat center top / 900px,
  url(${doodle4}) no-repeat right 5% top 5%,
  url(${doodle3}) no-repeat center bottom 5%,
  url(${saly1}) right 20% top 3% no-repeat,
  url(${doodle1}) right 40% top 36% no-repeat
  `,
}));

const ContainerInner = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 20px',
  width: '1280px',
  maxWidth: '100%',
  minWidth: '700px',
}));

const MainScreen = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  height: 'calc(100% - 125px)',
  flexDirection: 'column',
  marginTop: 'auto',
  alignItems: 'center',
  width: '100%',
}));

const MainScreenTextContent = styled('div')(({ theme }) => ({
  lineHeight: 1.2,
  fontWeight: 400,
  display: 'flex',
  rowGap: '40px',
  flexDirection: 'column',
  maxWidth: '495px',
  'span.word': {
    color: theme.palette.primary.main,
    display: 'block',
    position: 'relative',
    fontWeight: 700,
    '&:after': {
      content: `url(${HeroTextLine})`,
      position: 'absolute',
      top: '65%',
      display: 'block',
      width: '100%',
    },
  },

  p: {
    marginTop: '20px',
    lineHeight: '20px',
    marginBottom: 0,
  },
}));

export const Hero: FC = () => {
  return (
    <HeroContainer>
      <CssBaseline />
      <Header />
      <MainScreen>
        <ContainerInner>
          <MainScreenTextContent>
            <Typography
              variant='h1'
              sx={{ fontSize: '70px', position: 'relative' }}
            >
              {localize.landing.heading}
              <span className='word'>{localize.landing.headingWord}</span>
            </Typography>
            <p>{localize.landing.subText}</p>
            <Button
              component={Link}
              href='/cp'
              sx={{ alignSelf: 'flex-start' }}
              variant='rounded'
            >
              {localize.landing.headCTA}
            </Button>
          </MainScreenTextContent>
        </ContainerInner>
      </MainScreen>
    </HeroContainer>
  );
};
