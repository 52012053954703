import { AnyObject } from '../api/anyObjectTypes';

type RequestBody = {
  mainItem: AnyObject;
  mainFields: AnyObject[];
  subItems?: AnyObject[];
  subItemFields?: AnyObject[];
  subItemsKey?: string;
  basicMainLabels?: AnyObject;
  basicSubItemLabels?: AnyObject;
  template?: AnyObject;
  subTemplate?: AnyObject;
};

export const preparedRequestBody = ({
  mainItem,
  mainFields,
  subItems,
  subItemFields,
  subItemsKey,
  basicMainLabels,
  basicSubItemLabels,
}: RequestBody) => {
  let requestData: AnyObject = {};
  Object.keys(mainItem).forEach((mi) => {
    if (
      mainFields.find((mf) => mf.internal_name === mi)?.hidden ||
      mi === 'id'
    ) {
      requestData[mi] = mainItem[mi];
    } else {
      requestData['additional_fields'] = requestData['additional_fields'] || {};
      requestData['additional_fields'][mi] = mainItem[mi];
    }
  });

  if (basicMainLabels)
    Object.keys(basicMainLabels).forEach(
      (bml) =>
        (requestData[bml] = mainItem[bml]
          ? mainItem[bml]['id'] || mainItem[bml]
          : null)
    );

  if (subItemsKey && subItems?.length) {
    requestData[subItemsKey] = [];
    subItems.forEach((si, i) => {
      requestData[subItemsKey][i] = {};
      Object.keys(si).forEach((sik) => {
        if (
          subItemFields?.find((mf) => mf.internal_name === sik)?.hidden ||
          sik === 'id' ||
          sik === 'product_modification_id' ||
          sik === '_destroy'
        ) {
          requestData[subItemsKey][i][sik] = si[sik];
        } else {
          requestData[subItemsKey][i]['additional_fields'] =
            requestData[subItemsKey][i]['additional_fields'] || {};
          requestData[subItemsKey][i]['additional_fields'][sik] = si[sik];
        }
      });
      if (basicSubItemLabels)
        Object.keys(basicSubItemLabels).forEach(
          (bsil) =>
            (requestData[subItemsKey][i][bsil] = si[bsil]
              ? si[bsil]['id']
              : null)
        );
    });
  }
  return requestData;
};
