import { ChangeEvent, useState, useRef, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import {
  IconButton,
  InputBase,
  Paper,
  List,
  Typography,
  ListItemButton,
} from '@mui/material';

import { navLinksForApp } from '../../utils/navigationLinks';
import localize from '../../localize';
import { CommonContext } from '../../contexts/CommonContext';
import { useCloseOnClickOutside } from '../../hooks/useCloseOnClickOutside';

import { ReactComponent as SearchIconSvg } from '../../assets/search.svg';

const LinksList = styled(List)(() => ({
  width: 228,
  padding: '10px',
  background: '#fff',
  borderRadius: '8px',
  border: '1px solid #AFABEF',
  position: 'absolute',
  zIndex: 10,
  top: '110%',
  left: '0',
}));

const linksArray = Object.entries(navLinksForApp.uk);

export const SearchBox = () => {
  const searchRef = useRef<HTMLFormElement>(null);
  const [search, setSearch] = useState('');
  const [linksList, setLinksList] = useState<
    [string, { link: string; name: string }][]
  >([]);

  const { currentUser } = useContext(CommonContext);

  const userPermissions = useMemo(
    () => Object.keys(currentUser?.user_permissions),
    [currentUser]
  );

  const navigate = useNavigate();

  const searchResult = (event: ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.trim();
    setSearch(query);
    const newList = linksArray
      .filter(([key, value]) =>
        value.name === 'profile' ||
        value.name === 'project' ||
        value.name.includes('users')
          ? key.toLowerCase().search(query.toLowerCase()) !== -1
          : key.toLowerCase().search(query.toLowerCase()) !== -1 &&
            userPermissions.includes(value.name) &&
            Object.values(currentUser.user_permissions[value.name]).some(
              (a_p: any) => a_p.length > 0
            )
      )
      .filter(([key, value]) =>
        !value.name.includes('users')
          ? true
          : value.name === 'users-drop'
            ? currentUser.user_permissions['user']?.read?.includes('all') ||
              currentUser.user_permissions['user']?.read?.includes('relative')
              ? true
              : false
            : value.name === 'users-admin'
              ? currentUser.user_permissions['user']?.read?.includes('all')
              : false
      );

    setLinksList(newList);
  };

  useCloseOnClickOutside(searchRef, (e) => {
    setSearch('');
  });

  return (
    <Paper
      component='form'
      ref={searchRef}
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        width: 228,
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '4px 8px 32px 0px #5043CF14',
        margin: '0 auto 10px',
        border: '1px solid transparent',
        '&:hover': {
          border: '1px solid #AFABEF',
        },
      }}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <IconButton type='button' sx={{ p: '10px' }} aria-label='search'>
        <SearchIconSvg />
      </IconButton>
      <InputBase
        sx={{ flex: 1 }}
        placeholder={localize.sidebar.search}
        name={localize.sidebar.search}
        value={search}
        onChange={searchResult}
      />
      {search &&
        (linksList.length > 0 ? (
          <LinksList>
            {linksList.map(([key, value]) => (
              <ListItemButton
                key={key}
                onClick={() => {
                  setSearch('');
                  navigate(value.link);
                }}
              >
                <Typography color='#464646' variant='subtitle2'>
                  {key}
                </Typography>
              </ListItemButton>
            ))}
          </LinksList>
        ) : (
          <LinksList>
            <Typography color='#777777' variant='subtitle2'>
              {localize.sidebar.searchEmpty}
            </Typography>
          </LinksList>
        ))}
    </Paper>
  );
};
