import { FC, useState, useRef, useEffect } from 'react';

import { styled } from '@mui/material/styles';
import { Box, IconButton, ListItem, Tooltip, Typography } from '@mui/material';

import { AnyObject } from '../../api/anyObjectTypes';
import { ReactComponent as EditItemIconSvg } from '../../assets/edit-item.svg';
import { ReactComponent as DeleteItemIconSvg } from '../../assets/delete-item.svg';
import { ReactComponent as UploadItemIconSvg } from '../../assets/document-upload.svg';
import { useCloseOnClickOutside } from '../../hooks/useCloseOnClickOutside';

type ListItemForAddFilesProps = {
  file: AnyObject;
  fileUrl?: string;
  deleteItem: (file: string) => void;
  isLast: boolean;
  changeFileKey: (name: string, newFile: AnyObject) => void;
};

const TextareaForFileName = styled('input')(() => ({
  width: '150px',
  padding: '0',
  border: 'none',
  outline: 'none',
  background: 'none',
  color: '#AFABEF',
  fontFamily: 'Montserrat, sans-serif',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '17.07px',
}));

const ListItemForAddFiles: FC<ListItemForAddFilesProps> = ({
  file,
  fileUrl,
  deleteItem,
  changeFileKey,
  isLast,
}) => {
  const [fileName, setFileName] = useState<string>(file.fileName as string);
  const [changeFile, setChangeFile] = useState(false);
  const textareaRef = useRef<HTMLInputElement>(null);

  const renameKeyForFile = () => {
    const renameFile = () => {
      const extension = file.name.split('.').slice(1).join('');

      const renamedFile: AnyObject = new File(
        [file.path],
        `${fileName}.${extension}`,
        { type: file.path.type }
      );
      renamedFile.apiNumber = file.path.apiNumber;
      return renamedFile;
    };

    if (changeFile) {
      fileUrl
        ? changeFileKey(file.fileName, { name: fileName, pathURL: file.path })
        : changeFileKey(file.fileName, renameFile());
    }
    setChangeFile((prev) => !prev);
  };

  useEffect(() => {
    if (changeFile && textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(
        0,
        textareaRef.current.value.length
      );
    }
  }, [changeFile]);

  useCloseOnClickOutside(textareaRef, (e) => {
    renameKeyForFile();
  });

  return (
    <ListItem
      sx={{
        borderBottom: isLast ? 'none' : '1px dashed #686565',
        padding: '8px 0',
      }}
    >
      <form
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        onSubmit={(e) => {
          e.preventDefault();
          renameKeyForFile();
        }}
      >
        <Typography
          variant='body1'
          color='#AFABEF'
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {'"'}&nbsp;
          {changeFile ? (
            <TextareaForFileName
              name='key_name'
              value={fileName}
              readOnly={!changeFile}
              ref={textareaRef}
              onChange={(event) => {
                setFileName(event.target.value);
              }}
            />
          ) : (
            <Typography
              component='span'
              variant='body1'
              color='#AFABEF'
              onClick={() => {
                setChangeFile(true);
              }}
            >
              {fileName}
            </Typography>
          )}
          &nbsp;{'": "'}&nbsp;
          <Typography component='span' variant='body1' color='#fff'>
            {typeof file.path === 'string'
              ? `.../${file.path.split('/').pop()}`
              : file.path.apiNumber}
          </Typography>
          &nbsp;{'"'}
        </Typography>
        <Box display='flex' gap='10px' justifyContent='flex-end'>
          {/* <IconButton
              type='submit'
              sx={{ width: 20, height: 20, padding: '0' }}
            >
              <EditItemIconSvg fill='#DFDDF9' />
            </IconButton> */}
          {fileUrl && fileUrl !== '#' && (
            <IconButton
              component='a'
              disabled={!fileUrl || fileUrl === '#'}
              href={fileUrl || '#'}
              target='_blank'
              rel='noreferrer'
              sx={{ width: 20, height: 20, padding: '0' }}
            >
              <UploadItemIconSvg fill='#DFDDF9' />
            </IconButton>
          )}
          <IconButton
            sx={{ width: 20, height: 20, padding: '0' }}
            onClick={() => {
              deleteItem(fileName);
            }}
          >
            <DeleteItemIconSvg fill='#FA3B3B' />
          </IconButton>
        </Box>
      </form>
    </ListItem>
  );
};

export default ListItemForAddFiles;
