import { FC, useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Page from '../../components/Page/Page';
import { CommonContext } from '../../contexts/CommonContext';
import ApiKeysAddonCard from '../../containers/ApiKeysAddonCard';
import TelegramAddonCard from '../../containers/TelegramAddonCard';

const InnerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
}));

export const AddonsDashboardPage: FC = () => {
  const { currentProject } = useContext(CommonContext);

  useEffect(() => {}, [currentProject]);

  return (
    <Page title='Інтеграції'>
      <InnerContainer>
        <ApiKeysAddonCard />
        <br />
        <br />
        <TelegramAddonCard />
      </InnerContainer>
    </Page>
  );
};

export default AddonsDashboardPage;
