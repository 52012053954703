import { Snackbar, Alert, AlertColor } from '@mui/material';
import {
  FC,
  createContext,
  useMemo,
  useState,
  useEffect,
  PropsWithChildren,
} from 'react';

const emptyFun = (): void => {
  return;
};

type NotificationProps = {
  setNotification: (notification: Notification) => void;
  notification?: Notification;
};

type Notification = {
  message: string;
  severity?: AlertColor;
};

export const NotificationContext = createContext<NotificationProps>({
  setNotification: emptyFun,
  notification: { severity: 'success', message: '322' },
});

export const NotificationProvider: FC<PropsWithChildren> = (props) => {
  const [notification, setNotification] = useState<Notification>();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const handleClose = () => {
    setNotification(undefined);
  };

  useEffect(() => {
    setOpenSnackbar(!!notification);
  }, [notification]);

  const value = useMemo(
    () => ({
      setNotification,
      notification,
    }),
    [notification, setNotification]
  );

  return (
    <NotificationContext.Provider value={value}>
      {props.children}
      {openSnackbar && (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={handleClose}
            severity={notification?.severity}
            sx={{ width: '100%' }}
          >
            {notification?.message}
          </Alert>
        </Snackbar>
      )}
    </NotificationContext.Provider>
  );
};

NotificationProvider.displayName = 'NotificationProvider';
