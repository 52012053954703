import { styled } from '@mui/material';
import React from 'react';
import localize from '../../../../localize';
import { ContainerInner } from '../../Price';
import { CustomAccordion } from './Accordion';
export const FAQContainer = styled('section')(() => ({
  position: 'relative',
  color: '#0c0c0c',
  fontSize: '16px',
  fontWeight: 400,
  fontFamily: 'Montserrat, sans-serif',
  padding: '72px 0',
}));

type AccordionData = {
  id: number;
  title: string;
  description: string;
}[];

export const FAQSection = () => {
  return (
    <FAQContainer>
      <ContainerInner sx={{ justifyContent: 'center' }}>
        <CustomAccordion
          data={localize.landing.pricingPage.features as AccordionData}
        />
      </ContainerInner>
    </FAQContainer>
  );
};
