import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  styled,
} from '@mui/material';
import { QuestionMark as QuestionMarkIcon } from '@mui/icons-material';
import { Period } from './Tarrifs';

export const TableWrapper = styled(TableContainer)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
  gap: '20px',
  width: '100%',
  fontSize: '14px',
}));

const TarrifsNamesTable = styled(Table)(() => ({
  width: '33.333333%',
  maxWidth: '410px',
  fontFamily: 'Montserrat, sans-serif',
  border: 'none',
  '& td, & th': {
    border: 'none',
  },

  tbody: {
    border: 'none',
    borderRadius: '14px',
    boxShadow: '11px 10px -10px 0px #606CD614',
    backgroundColor: '#AFABEF',
    fontWeight: 700,

    '& tr td:last-of-type': {
      paddingRight: '8px',
    },

    '& tr:first-of-type td:first-of-type': {
      borderTopLeftRadius: '14px',
      paddingTop: '8px',
    },

    '& tr:first-of-type td:last-of-type': {
      borderTopRightRadius: '14px',
      paddingTop: '8px',
    },

    '& tr:last-of-type td:first-of-type': {
      borderBottomLeftRadius: '14px',
      paddingBottom: '8px',
    },

    '& tr:last-of-type td:last-of-type': {
      borderBottomRightRadius: '14px',
      paddingBottom: '8px',
    },
  },
}));

const TarrifsDataTable = styled(Table)(() => ({
  width: '66.666666%',
  maxWidth: '810px',
  fontFamily: 'Montserrat, sans-serif',
  color: '#0c0c0c',
  fontSize: '14px',
  border: 'none',
  '& td, & th': {
    border: 'none',
  },

  '.data-section': {
    '&:before': {
      content: '""',
      height: '25px',
      display: 'table-row',
    },
    '&:first-of-type': {
      '&:before': {
        height: '8px',
      },
    },
    '.data-row': {
      '.data-cell': {
        backgroundColor: '#DFDDF9',
      },
    },

    '&:nth-of-type(2)': {
      '&:before': {
        height: '44px',
      },
    },

    '&:nth-of-type(3)': {
      '&:before': {
        height: '36px',
      },
    },

    '&:last-of-type': {
      '&:after': {
        content: '""',
        display: 'table-row',
        height: '8px',
      },
      '&:before': {
        content: '""',
        display: 'table-row',
        height: '25px',
      },
    },
    '& tr:first-of-type td:first-of-type': {
      borderTopLeftRadius: '4px',
    },

    '& tr:first-of-type td:last-of-type': {
      borderTopRightRadius: '4px',
    },

    '& tr:last-of-type td:first-of-type': {
      borderBottomLeftRadius: '4px',
    },

    '& tr:last-of-type td:last-of-type': {
      borderBottomRightRadius: '4px',
    },
  },
}));

const CustomChip = styled('div')(({ theme }) => ({
  minWidth: '78px',
  width: 'fit-content',
  margin: 'auto',
  color: '#0c0c0c',
  fontSize: '16px',
  border: '1px solid #7B75CB',
  borderRadius: '16px',
  padding: '10px',
  display: 'flex',
  justifyContent: 'center',
  gap: '10px',

  '.green-text': {
    fontWeight: 700,
    color: '#53C17F',
  },
  '.strikethrough-text': {
    textDecoration: 'line-through',
  },
}));

type PricingPlan = {
  currentPrice: string;
  oldPrice?: string;
};

type Costs = {
  monthly: {
    basic: PricingPlan;
    standard: PricingPlan;
    business: PricingPlan;
    custom: PricingPlan;
  };
  yearly: {
    basic: PricingPlan;
    standard: PricingPlan;
    business: PricingPlan;
    custom: PricingPlan;
  };
};

type Feature = {
  feature: string;
  tooltip?: string;
  basic: number | string;
  standard: number | string;
  business: number | string;
  custom: string;
};

type PricingPlansTable = {
  headers: {
    feature: string;
    plans: string[];
  };
  features: Feature[];
  costs: Costs;
};

export const TarrifsTable = ({
  period,
  data,
}: {
  period: Period;
  data: PricingPlansTable;
}) => {
  return (
    <TableWrapper>
      <TarrifsNamesTable>
        <TableHead>
          <TableRow>
            <TableCell sx={{ opacity: 0, padding: '16px' }} align='right'>
              <span>&nbsp;</span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                border: 'none',
                paddingRight: 0,
                color: '#0c0c0c',
                padding: '16px',
                height: '58px',
                fontSize: '14px',
              }}
              align='right'
            >
              {data.headers.feature}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.features.map((f) => (
            <TableRow>
              <TableCell
                sx={{
                  color: '#fff',
                  fontWeight: 700,
                  fontSize: '16px',
                  padding: '16px',
                }}
              >
                {f.feature}
              </TableCell>
              <TableCell>
                {f.tooltip ? (
                  <Tooltip title={f.tooltip}>
                    <Paper
                      sx={{
                        width: '32px',
                        height: '32px',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        color: '#7B75CB',
                      }}
                    >
                      <QuestionMarkIcon fontSize='small' />
                    </Paper>
                  </Tooltip>
                ) : (
                  ' '
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TarrifsNamesTable>
      <TarrifsDataTable>
        <TableHead>
          <TableRow>
            {data.headers.plans.map((plan) => (
              <TableCell
                align='center'
                sx={{
                  fontFamily: 'Montserrat, sans-serif',
                  fontSize: '14px',
                  fontWeight: 700,
                  color: '#7B75CB',
                }}
                key={plan}
              >
                {plan}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell
              align='center'
              sx={{
                padding: '16px',
              }}
            >
              <CustomChip
                sx={{
                  padding: '8px 10px',
                }}
              >
                <span className='normal-text'>
                  {data.costs.monthly.basic.currentPrice}
                </span>
              </CustomChip>
            </TableCell>
            <TableCell
              align='center'
              sx={{
                padding: '16px',
              }}
            >
              <CustomChip>
                <span className='strikethrough-text'>
                  {period === 'month'
                    ? data.costs.monthly.standard.oldPrice
                    : data.costs.yearly.standard.oldPrice}
                </span>
                <span className='green-text'>
                  {period === 'month'
                    ? data.costs.monthly.standard.currentPrice
                    : data.costs.yearly.standard.currentPrice}
                </span>
              </CustomChip>
            </TableCell>
            <TableCell
              align='center'
              sx={{
                padding: '16px',
              }}
            >
              <CustomChip>
                <span className='strikethrough-text'>
                  {period === 'month'
                    ? data.costs.monthly.business.oldPrice
                    : data.costs.yearly.business.oldPrice}
                </span>
                <span className='green-text'>
                  {data.costs.monthly.business.currentPrice}
                </span>
              </CustomChip>
            </TableCell>
            <TableCell
              align='center'
              sx={{
                fontFamily: 'Montserrat, sans-serif',
                fontSize: '14px',
                padding: '16px',
                fontWeight: 700,
                color: '#7B75CB',
              }}
            >
              {data.costs.monthly.custom.currentPrice}
            </TableCell>
          </TableRow>
        </TableHead>
        <>
          {data.features.map((item, index) => (
            <TableBody className='data-section'>
              <TableRow className='data-row' key={index}>
                <TableCell className='data-cell' align='center'>
                  {item.basic}
                </TableCell>
                <TableCell className='data-cell' align='center'>
                  {item.standard}
                </TableCell>
                <TableCell className='data-cell' align='center'>
                  {item.business}
                </TableCell>
                <TableCell className='data-cell' align='center'>
                  {item.custom}
                </TableCell>
              </TableRow>
            </TableBody>
          ))}
        </>
      </TarrifsDataTable>
    </TableWrapper>
  );
};
