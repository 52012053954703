import { FC, useContext, useEffect, useMemo, useState } from 'react';
import {
  Card,
  CardContent,
  Box,
  Grid,
  Typography,
  Alert,
  Link,
} from '@mui/material';
import FormField from '../../components/FormField/FormField';
import { User, UserApiClient } from '../../api/UserApiClient';
import { CommonContext } from '../../contexts/CommonContext';
import { Customer, CustomerApiClient } from '../../api/CustomerApiClient';
import { Promotion, PromotionApiClient } from '../../api/PromotionApiClient';
import { AnyObject } from '../../api/anyObjectTypes';
import Loading from '../../components/Loading/Loading';
import { styled } from '@mui/material/styles';

type ClientCustomerFieldsProps = {
  fixedClientDiscount: number;
  fixedCustomerDiscount: number;
  setFixedCustomerDiscount(num: number): void;
  setFixedClientDiscount(num: number): void;
  client?: User;
  setClient(user: User): void;
  customer?: Customer;
  setCustomer(customer: Customer | undefined): void;
  promotion?: Promotion;
  setPromotion(promotion: Promotion | undefined): void;
};

const DataFillContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  minWidth: '100%',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '&>*': {
      width: '100%',
      maxWidth: '100%',
    },
  },
}));

export const ClientCustomerFields: FC<ClientCustomerFieldsProps> = ({
  client,
  setClient,
  customer,
  setCustomer,
  promotion,
  setPromotion,
  fixedClientDiscount,
  setFixedClientDiscount,
  fixedCustomerDiscount,
  setFixedCustomerDiscount,
}) => {
  const { currentUser, currentProject } = useContext(CommonContext);
  const [loadingCustomers, setLoadingCustomers] = useState(true);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingPromotions, setLoadingPromotions] = useState(true);
  const [users, setUsers] = useState<User[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [promotions, setPromotions] = useState<Promotion[]>([]);

  const fetchUsers = async () => {
    setLoadingUsers(true);
    const res = await UserApiClient.getIndex(currentProject?.id);
    setUsers(res);
    setLoadingUsers(false);
  };

  const fetchCustomers = async (params?: AnyObject) => {
    setLoadingCustomers(true);
    const res = await CustomerApiClient.getIndex(currentProject?.id, params);
    setCustomers(res);
    setLoadingCustomers(false);
  };

  const fetchPromotions = async (params?: AnyObject) => {
    setLoadingPromotions(true);
    const res = await PromotionApiClient.getIndex(currentProject?.id, params);
    setPromotions(res);
    setLoadingPromotions(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    const params: AnyObject = {};
    !client?.manager_id
      ? (params.user_id = client?.id)
      : (params.visible_for_all = true);

    fetchCustomers(params);
    fetchPromotions(params);
  }, [client?.id]);

  useEffect(() => {
    if (loadingCustomers) return;

    if (customer?.id && !customers.map((c) => c.id).includes(customer?.id)) {
      setCustomer(undefined);
    }
  }, [loadingCustomers, customers]);

  useEffect(() => {
    if (loadingPromotions) return;

    if (!promotions.map((c) => c.id).includes(promotion?.id || '')) {
      setPromotion(undefined);
    }
  }, [loadingPromotions, promotions]);

  const clientIsCurrentUser = useMemo(
    () => !client?.auth0_id || client?.auth0_id === currentUser?.auth0_id,
    [client, currentUser]
  );
  const clientRoleIsNotClient = useMemo(
    () =>
      !client?.auth0_id
        ? currentUser?.role !== 'Client'
        : client?.role !== 'Client',
    [client, currentUser]
  );
  const discountApplied = useMemo(() => {
    const res = [];
    customer?.personal_discount && res.push(`"Особиста знижка"`);
    promotion && res.push(`промо-акція "${promotion.title}"`);
    return res;
  }, [customer?.personal_discount, promotion]);

  const autocompleteOptions = useMemo(() => {
    if (!users.length) return [];

    return users.map((user) => ({
      label: `${user.name}${currentUser?.auth0_id === user.auth0_id ? ' (Ви)' : ''}`,
      id: user.auth0_id,
      user,
    }));
  }, [users]);

  const customersAutocompleteOptions = useMemo(() => {
    if (!customers.length) return [];

    return customers.map((customer) => ({
      label: `${customer.name} (${customer.personal_discount}%) - ${customer.phone_number} - ${customer.email}`,
      id: customer.id,
      customer,
    }));
  }, [customers]);

  const [createCustomerMode, setCreateCustomerMode] = useState(
    !customersAutocompleteOptions.length && !customer?.id
  );

  const promotionsAutocompleteOptions = useMemo(() => {
    if (!promotions.length) return [];

    return promotions.map((promotion) => ({
      label: `${promotion.title}`,
      id: promotion.id,
      promotion,
    }));
  }, [promotions]);

  useEffect(() => {
    setFixedClientDiscount(client?.discount || 0);
  }, [client]);

  useEffect(() => {
    setFixedCustomerDiscount(customer?.personal_discount || 0);
  }, [customer]);

  if (loadingUsers) return <Loading />;

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                minWidth: '100%',
                flexDirection: 'column',
              }}
            >
              {currentUser?.role === 'Client' ? (
                <Box sx={{ display: 'flex', minWidth: '100%', p: 1 }}>
                  <Typography variant='body1'>
                    Ваша знижка (маржа): <b>{currentUser.discount}%</b>
                  </Typography>
                </Box>
              ) : (
                users.length > 1 && (
                  <DataFillContainer>
                    <FormField
                      sx={{ flex: '1 1 auto', maxWidth: '100%', width: '100%' }}
                      value={client?.auth0_id || ''}
                      label='Виберіть дропшипера (чи менеджера)'
                      name='client_id'
                      required={true}
                      onChange={(e) => {}}
                      loading={loadingUsers}
                      autocomplete={{
                        options: autocompleteOptions,
                        onChange: (_, newValue: any) => {
                          setClient(newValue?.user || undefined);
                        },
                      }}
                    />
                    <FormField
                      sx={{ maxWidth: '200px', width: '100%' }}
                      value={(fixedClientDiscount as unknown as string) || ''}
                      label='Знижка дропшипера (маржа)'
                      loading={loadingUsers}
                      fieldType='number'
                      name='fixed_client_discount'
                      onChange={(e) => setFixedClientDiscount(e.target.value)}
                    />
                  </DataFillContainer>
                )
              )}
            </Box>
            <br />

            <Box
              sx={{
                display: 'flex',
                minWidth: '100%',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  minWidth: '100%',
                  flexDirection: 'column',
                  p: 1,
                }}
              >
                <Typography variant='h4'>
                  {createCustomerMode ? 'Створіть' : 'Виберіть'} покупця
                  (необовʼязково)
                </Typography>
                <div>
                  {!!customersAutocompleteOptions?.length ? (
                    createCustomerMode ? (
                      <Link
                        onClick={(e) => {
                          e.preventDefault();
                          setCreateCustomerMode(false);
                        }}
                      >
                        або виберіть існуючого покупця
                      </Link>
                    ) : (
                      <Link
                        onClick={(e) => {
                          e.preventDefault();
                          setCreateCustomerMode(true);
                          setCustomer(undefined);
                        }}
                      >
                        або створіть нового покупця
                      </Link>
                    )
                  ) : null}
                </div>
              </Box>
              {!createCustomerMode ? (
                <DataFillContainer>
                  <FormField
                    sx={{ flex: '1 1 auto', maxWidth: '100%', width: '100%' }}
                    loading={loadingCustomers}
                    value={customer?.id || ''}
                    label='Виберіть або створіть замовника (покупця)'
                    name='customer_id'
                    required={true}
                    onChange={(e) => {}}
                    autocomplete={{
                      options: customersAutocompleteOptions,
                      onChange: (_, newValue: any) => {
                        setCustomer(newValue?.customer || undefined);
                      },
                    }}
                  />
                  <FormField
                    sx={{ maxWidth: '200px', width: '100%' }}
                    value={(fixedCustomerDiscount as unknown as string) || ''}
                    loading={loadingCustomers}
                    label='Особиста знижка'
                    fieldType='number'
                    name='personal_discount'
                    onChange={(e) => setFixedCustomerDiscount(e.target.value)}
                  />
                </DataFillContainer>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    minWidth: '100%',
                    flexDirection: 'column',
                  }}
                >
                  <DataFillContainer>
                    <FormField
                      sx={{ flex: '1 1 auto', maxWidth: '100%' }}
                      value={customer?.name || ''}
                      label='Імʼя замовника (покупця)'
                      name='name'
                      onChange={(e) =>
                        setCustomer({
                          ...(customer || {}),
                          name: e.target.value,
                        })
                      }
                    />
                    <FormField
                      sx={{ maxWidth: '200px', width: '100%' }}
                      value={
                        (customer?.personal_discount as unknown as string) || ''
                      }
                      label='Особиста знижка'
                      fieldType='number'
                      name='personal_discount'
                      onChange={(e) =>
                        setCustomer({
                          ...(customer || {}),
                          personal_discount: e.target.value,
                        })
                      }
                    />
                  </DataFillContainer>
                  <DataFillContainer>
                    <FormField
                      sx={{ flex: '1 1 auto', maxWidth: '100%' }}
                      value={customer?.email || ''}
                      label='Email'
                      name='email'
                      onChange={(e) =>
                        setCustomer({
                          ...(customer || {}),
                          email: e.target.value,
                        })
                      }
                    />
                    <FormField
                      sx={{ flex: '1 1 auto', maxWidth: '100%' }}
                      value={customer?.phone_number || ''}
                      label='Телефон'
                      name='phone_number'
                      onChange={(e) =>
                        setCustomer({
                          ...(customer || {}),
                          phone_number: e.target.value,
                        })
                      }
                    />
                  </DataFillContainer>
                </Box>
              )}
              {promotionsAutocompleteOptions.length ? (
                <Box
                  sx={{
                    display: 'flex',
                    minWidth: '100%',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', minWidth: '100%', p: 1 }}>
                    <Typography variant='h4'>
                      Додаткова знижка (необовʼязково)
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', minWidth: '100%' }}>
                    <FormField
                      sx={{ flex: '1 1 auto', maxWidth: '100%' }}
                      value={promotion?.id || ''}
                      loading={loadingPromotions}
                      label='Виберіть промо-акцію'
                      name='promotion_id'
                      onChange={(e) => {}}
                      autocomplete={{
                        options: promotionsAutocompleteOptions,
                        onChange: (_, newValue: any) => {
                          setPromotion(newValue?.promotion || undefined);
                        },
                      }}
                    />
                  </Box>
                </Box>
              ) : null}
              {discountApplied.length ? (
                <Alert severity='info'>
                  {clientRoleIsNotClient
                    ? clientIsCurrentUser
                      ? `Так як ви створюєте замовлення для себе, як представника менеджменту проєкту, то
                              ${discountApplied.join(' та ')} змінить рекомендовану ціну до сплати покупцем
                              `
                      : `Так як ви створюєте замовлення для іншого представника менеджменту проєкту, то
                                ${discountApplied.join(' та ')} змінить рекомендовану ціну до сплати покупцем
                              `
                    : clientIsCurrentUser
                      ? `Так як ви не можете впливати на ціну товару задану менеджментом проєкту, то
                                ${discountApplied.join(' та ')} не змінюватиме ціну продуктів, не впливатиме на розрахунок
                                суми яку ви повинні будете сплатити і застосовується лише для обрахунків рекомендованої суми до сплати покупцем
                              `
                      : `Так як ви створюєте замовлення для користувача який не може впливати на рекомендовану вартість товару, то
                                ${discountApplied.join(' та ')} не змінюватиме ціну продуктів, не впливатиме на розрахунок
                                суми до сплати клієнтом і застосовується лише для обрахунків рекомендованої суми до сплати покупцем
                              `}
                </Alert>
              ) : null}
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ClientCustomerFields;
