import { Button, styled, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import localize from '../../../../localize';
import { ColorSection, ContainerInner, MainTitle, MainText } from '../../Price';
import { Badge } from '../Badge';
import { TarrifsTable } from './TarrifsTable';

const TarrifsBetaTextContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '16px',
  alignItems: 'center',
  marginBottom: '14px',
}));

const TarrifsBetaText = styled(Typography)(() => ({
  color: '#D4FFE0',
  fontSize: '16px',
  display: 'block',
  fontWeight: 700,
  fontFamily: '"Montserrat", sans-serif',
  marginBottom: '0',
}));

const PeriodButtonsContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '12px',
  padding: '10px',
  backgroundColor: '#9E97EB',
  borderRadius: '100px',
  opacity: 0.5,
}));

const PeriodButton = styled(Button)(() => ({
  color: '#0c0c0c',
  textTransform: 'capitalize',
  pointerEvents: 'none',
}));

const TarrifsTableMainContainer = styled('section')(() => ({
  padding: '70px 0 95px 0',
  position: 'relative',
  color: '#0c0c0c',
}));

export type Period = 'month' | 'year';

export const Tarrifs = () => {
  const [period, setPeriod] = useState<Period>('month');

  return (
    <>
      <ColorSection>
        <ContainerInner sx={{ justifyContent: 'center' }}>
          <MainTitle variant='h2'>
            {localize.landing.pricingPage.tarrifsSectionTitle}
          </MainTitle>
          <MainText variant='body1'>
            {localize.landing.pricingPage.tarrifsSectionDescription}
          </MainText>
          <TarrifsBetaTextContainer>
            <Badge>Beta</Badge>
            <TarrifsBetaText variant='body1'>
              {localize.landing.pricingPage.tarrifsSectionSubtext}
            </TarrifsBetaText>
          </TarrifsBetaTextContainer>
          <Tooltip
            title={
              'Поки ми в beta-версії, ми не пропонуємо знижок при оплаті за рік, але вони будуть :)'
            }
          >
            <PeriodButtonsContainer>
              <PeriodButton
                sx={{
                  backgroundColor: period === 'month' ? '#fff' : '#7B75CB',
                  '&:hover': {
                    backgroundColor: period === 'month' ? '#fff' : '#7B75CB',
                    color: '#0c0c0c',
                  },
                }}
                variant='rounded'
                onClick={() => setPeriod('month')}
              >
                {localize.landing.pricingPage.period.month}
              </PeriodButton>
              <PeriodButton
                sx={{
                  backgroundColor: period === 'year' ? '#fff' : '#7B75CB',
                  '&:hover': {
                    backgroundColor: period === 'year' ? '#fff' : '#7B75CB',
                    color: '#0c0c0c',
                  },
                }}
                variant='rounded'
                onClick={() => setPeriod('year')}
              >
                {localize.landing.pricingPage.period.year}
              </PeriodButton>
            </PeriodButtonsContainer>
          </Tooltip>
        </ContainerInner>
      </ColorSection>
      <TarrifsTableMainContainer>
        <ContainerInner>
          <TarrifsTable
            data={localize.landing.pricingPage.pricingPlansTable}
            period={period}
          />
        </ContainerInner>
      </TarrifsTableMainContainer>
    </>
  );
};
