import xml2js from 'xml2js';

type UploadToAwsParams = {
  projectId: string;
  mainItemId?: string;
  additionalParams?: string;
  ApiClient: any;
  files: File[];
  contentType?: string;
};

function readFile(file: File) {
  return new Promise((resolve, reject) => {
    // Create file reader
    let reader = new FileReader();

    // Register event listeners
    reader.addEventListener('loadend', (e) => resolve(e.target?.result));
    reader.addEventListener('error', reject);

    // Read file
    reader.readAsArrayBuffer(file);
  });
}

async function getAsByteArray(file: File) {
  return new Uint8Array((await readFile(file)) as ArrayBufferLike);
}

export const uploadBlobToAws = async ({
  projectId,
  additionalParams,
  ApiClient,
  files,
}: UploadToAwsParams) => {
  const buildPromise = async (file: File) => {
    try {
      const presignedUrlResponse = await ApiClient.create(
        projectId,
        additionalParams,
        { file_name: file.name }
      );
      const byteFile = await getAsByteArray(file);
      let blobData = new Blob([byteFile], { type: file.type });
      const awsResponse = await fetch(presignedUrlResponse.url, {
        method: 'PUT',
        body: blobData,
      });

      const parsedResponse = await awsResponse.text();
      const result = { error: true, filename: presignedUrlResponse.file_name };
      await xml2js.parseString(parsedResponse, (err, _) => {
        result.error = !!err;
      });

      return result;
    } catch (err) {
      return { error: true, filename: '' };
    }
  };

  return await Promise.all(files.map((file: File) => buildPromise(file))).then(
    (response) => {
      const resultObj = { errors: [] as string[], success: [] as string[] };
      response.map((res) =>
        resultObj[res.error ? 'errors' : 'success'].push(res.filename)
      );
      return {
        errors: resultObj.errors.filter((el) => !!el),
        success: resultObj.success,
      };
    }
  );
};

export const uploadToAws = async ({
  projectId,
  additionalParams,
  ApiClient,
  files,
  contentType = 'application/pdf',
}: UploadToAwsParams) => {
  const buildPromise = async (file: File) => {
    try {
      const presignedUrlResponse = await ApiClient.create(
        projectId,
        additionalParams,
        { file_name: file.name }
      );
      const formData = new FormData();
      formData.append('Content-Type', contentType);
      formData.append('file', file);
      const awsResponse = await fetch(presignedUrlResponse.url, {
        method: 'PUT',
        headers: { 'Content-Type': contentType },
        body: formData,
      });
      const parsedResponse = await awsResponse.text();
      const result = { error: true, filename: presignedUrlResponse.file_name };
      await xml2js.parseString(parsedResponse, (err, _) => {
        result.error = !!err;
      });

      return result;
    } catch (err) {
      return { error: true, filename: '' };
    }
  };

  return await Promise.all(files.map((file: File) => buildPromise(file))).then(
    (response) => {
      const resultObj = { errors: [] as string[], success: [] as string[] };
      response.map((res) =>
        resultObj[res.error ? 'errors' : 'success'].push(res.filename)
      );
      return {
        errors: resultObj.errors.filter((el) => !!el),
        success: resultObj.success,
      };
    }
  );
};
