import { styled } from '@mui/material';
import { FC } from 'react';
import { AboutUsSection } from './components/AboutUsSection';
import { AdvantagesSection } from './components/AdvantagesSection';
import { FeaturesSection } from './components/FeaturesSection';
import { Footer } from './components/Footer';
import { Hero } from './components/Hero';
import { OffersSection } from './components/OffersSection';
import { PriceSection } from './components/PriceSection';
import { KnowMoreSection } from './components/KnowMoreSection';
import { NotificationProvider } from '../../contexts/NotificationContext';

export const Landing: FC = () => {
  return (
    <RootLandingWrapper>
      <NotificationProvider>
        <Hero />
        <AdvantagesSection />
        <AboutUsSection />
        <OffersSection />
        <FeaturesSection />
        <PriceSection />
        <KnowMoreSection />
        <Footer />
      </NotificationProvider>
    </RootLandingWrapper>
  );
};

export const RootLandingWrapper = styled('div')(() => ({
  fontFamily: 'Montserrat, sans-serif',
}));

export default Landing;
