import { FC, useContext, useEffect, useMemo, useState } from 'react';
import Page from '../../components/Page/Page';
import { Grid, Link, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { BasicTable } from '../../components/BaseTable/BaseTable';
import { CommonContext } from '../../contexts/CommonContext';
import { PromotionApiClient } from '../../api/PromotionApiClient';
import Loading from '../../components/Loading/Loading';
import { AnyObject } from '../../api/anyObjectTypes';
import localize from '../../localize';
import { NotificationContext } from '../../contexts/NotificationContext';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';

export const baseMainFields = [
  {
    field: 'title',
    headerName: localize.promotions.columns.title,
    type: 'string',
  },
  {
    field: 'promo',
    headerName: localize.promotions.columns.promo,
    type: 'string',
  },
  {
    field: 'is_one_time_use_only',
    headerName: localize.promotions.columns.is_one_time_use_only,
    type: 'boolean',
  },
  {
    field: 'discount',
    headerName: localize.promotions.columns.discount,
    type: 'number',
  },
  {
    field: 'discount_type',
    headerName: localize.promotions.columns.discount_type,
    type: 'string',
  },
  {
    field: 'affects_all',
    headerName: localize.promotions.columns.affects_all,
    type: 'boolean',
  },
  {
    field: 'starts_at',
    headerName: localize.promotions.columns.starts_at,
    width: 150,
    type: 'datetime',
  },
  {
    field: 'expires_at',
    headerName: localize.promotions.columns.expires_at,
    width: 150,
    type: 'datetime',
  },
];

const productTitleColumnWithLink = {
  field: 'product_title',
  align: 'left',
  headerName: 'Продукт',
  renderCell: (row: any) => (
    <Link href={`/cp/products/${row.product_id}`}>
      {[
        row.product?.additional_fields?.secondaryid,
        row.product?.additional_fields?.n_collection,
        row.product?.title,
      ]
        .filter((f) => !!f)
        .join(' - ')}
    </Link>
  ),
};

const productModificationColumn = {
  field: 'product_modification_title',
  align: 'left',
  headerName: 'Модифікація',
};
const productModificationPriceWithoutDiscountColumn = {
  field: 'price_without_discount',
  align: 'right',
  headerName: 'Ціна без знижки',
};
const productModificationPriceWithDiscountColumn = {
  field: 'price_with_discount',
  align: 'right',
  headerName: 'Ціна зі знижкою',
};

export const PromotionShowPage: FC = () => {
  const { id } = useParams();
  const { currentProject, currentUser } = useContext(CommonContext);
  const [item, setItem] = useState<AnyObject>({});
  const [promotionItemsColumns, setPromotionItemsColumns] = useState<
    AnyObject[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [itemIdToDelete, setItemIdToDelete] = useState<string | undefined>();
  const { setNotification } = useContext(NotificationContext);
  const navigate = useNavigate();

  const pageActions = useMemo(() => {
    const editAction = {
      title: localize.products.show.editButton,
      link: `/cp/promotions/edit/${id}`,
    };
    const deleteAction = {
      title: localize.products.show.deleteButton,
      onClick: () => setItemIdToDelete(id),
    };

    if (item.visible_for_all || item.user_id == currentUser.id)
      return [editAction, deleteAction];

    return [];
  }, [currentUser, item, id, setItemIdToDelete, itemIdToDelete]);

  const preparePageData = async (id: string): Promise<void> => {
    const res = await PromotionApiClient.getShow(currentProject.id, id);
    setItem(res);

    setPromotionItemsColumns([
      productTitleColumnWithLink,
      productModificationColumn,
      productModificationPriceWithoutDiscountColumn,
      productModificationPriceWithDiscountColumn,
    ]);
  };

  const deleteItem = () => {
    if (itemIdToDelete)
      PromotionApiClient.remove(currentProject.id, itemIdToDelete).then(() => {
        setItemIdToDelete(undefined);
        setNotification({
          severity: 'success',
          message: localize.general.successDeleteMessage,
        });
        navigate(`/cp/products`);
      });
  };

  useEffect(() => {
    if (!id) return;

    setLoading(true);
    preparePageData(id).finally(() => setLoading(false));
  }, [id, currentProject]);

  if (loading)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page title={localize.promotions.show.title} actions={pageActions}>
      <Grid
        container
        spacing={10}
        alignItems='flex-start'
        alignSelf='flex-start'
      >
        <Grid item xs={12}>
          <BasicTable rows={[{ ...item }]} columns={baseMainFields} colorRow />
        </Grid>
        {item.promotion_items.length ? (
          <Grid item xs={12}>
            <BasicTable
              rows={item.promotion_items}
              columns={promotionItemsColumns}
              colorRow
            />
          </Grid>
        ) : null}
      </Grid>
      <ConfirmationDialog
        header={localize.promotions.show.deleteConfirmationHeading}
        open={!!itemIdToDelete}
        description={localize.promotions.show.deleteConfirmationText}
        action={() => deleteItem()}
        discard={() => setItemIdToDelete(undefined)}
        mainActionButtonText={
          localize.promotions.show.deleteConfirmationButtonText
        }
      />
    </Page>
  );
};

export default PromotionShowPage;
