import { get, destroy, post, put } from './request';
import { AnyObject } from './anyObjectTypes';

export type Booking = {
  id: string;
  count: number;
  status: string;
  comment: string;
  product_title: string;
  product_modification_title: string;
};

export class BookingApiClient {
  static getIndex(projectId: string): Promise<Booking[]> {
    return get(`projects/${projectId}/bookings`);
  }

  static getShow(projectId: string, itemId: string): Promise<Booking> {
    return get(`projects/${projectId}/bookings/${itemId}`);
  }

  static remove(projectId: string, itemId: string): Promise<string> {
    return destroy(`projects/${projectId}/bookings/${itemId}`);
  }

  static create(projectId: string, requestBody: AnyObject): Promise<Booking> {
    return post(`projects/${projectId}/bookings`, requestBody);
  }

  static update(
    projectId: string,
    requestBody: AnyObject,
    itemId: string
  ): Promise<Booking> {
    return put(`projects/${projectId}/bookings/${itemId}`, requestBody);
  }
}
