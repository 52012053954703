import { FC, useContext, useEffect, useState } from 'react';
import Page from '../../components/Page/Page';
import { templatesForTypes } from '../../utils/template';
import { useNavigate } from 'react-router-dom';
import { NotificationContext } from '../../contexts/NotificationContext';
import { CommonContext } from '../../contexts/CommonContext';
import { Delivery, OrderApiClient, OrderItem } from '../../api/OrderApiClient';
import localize from '../../localize';
import { OrderUploadApiClient } from '../../api/OrderUploadApiClient';
import { uploadToAws } from '../../utils/aws';
import OrderFormStepper from '../../containers/order/OrderFormStepper';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import OrderItemSelect from '../../containers/order/OrderItemSelect';
import { User } from '../../api/UserApiClient';
import ClientCustomerFields from '../../containers/order/ClientCustomerFields';
import { Customer } from '../../api/CustomerApiClient';
import { Promotion } from '../../api/PromotionApiClient';
import UploadFilesFields from '../../containers/order/UploadFilesFields';
import DeliveryFields from '../../containers/order/DeliveryFields';
import Loading from '../../components/Loading/Loading';

const steps = [
  'Продукти та специфікації *',
  'Покупець, доставка та знижки',
  'Завантаження файлів',
];

export const OrderCreatePage: FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const { setNotification } = useContext(NotificationContext);
  const { currentProject, templates } = useContext(CommonContext);
  const navigate = useNavigate();
  const [fixedClientDiscount, setFixedClientDiscount] = useState(0);
  const [fixedCustomerDiscount, setFixedCustomerDiscount] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [client, setClient] = useState<User>();
  const [customer, setCustomer] = useState<Customer>();
  const [promotion, setPromotion] = useState<Promotion>();
  const [delivery, setDelivery] = useState<Delivery>();
  const [files, setFiles] = useState<File[]>([]);
  const [orderItems, setOrderItems] = useState<(OrderItem | any)[]>([{}]);
  const [hasErrors, setHasErrors] = useState(false);

  useEffect(() => {
    if (currentStep === steps.length) {
      submitOrder();
    } else if (currentStep < 0) {
      navigate('/cp/orders');
    }
  }, [currentStep]);

  const submitOrder = () => {
    setSubmitting(true);
    const orderParams = {
      order_items_attributes: orderItems.filter(
        (oi) => oi.product_modification_id && oi.count
      ),
      user_id: client?.id,
      fixed_client_discount: fixedClientDiscount,
      fixed_customer_discount: fixedCustomerDiscount,
      promotion_id: promotion?.id,
      customer_attributes: customer,
      ...(delivery || {}),
    };

    OrderApiClient.create(currentProject.id, {
      order: orderParams,
    }).then((createdOrder) => {
      uploadToAws({
        projectId: currentProject.id,
        additionalParams: createdOrder.id,
        ApiClient: OrderUploadApiClient,
        files,
      }).then((response) => {
        OrderApiClient.update(
          currentProject.id,
          {
            order: {
              files: [
                ...response.errors.filter((el) => !!el),
                ...response.success,
              ],
            },
            template_id: templatesForTypes(templates, ['Order'])[0]?.id,
          },
          createdOrder.id
        ).then(() => {
          if (response.errors.length) {
            setNotification({
              severity: 'warning',
              message: localize.general.warningCreateMessage,
            });
          } else {
            setNotification({
              severity: 'success',
              message: localize.general.successCreateMessage,
            });
          }
          setSubmitting(false);
          navigate(`/cp/orders/${createdOrder.id}`);
        });
      });
    });
  };

  return (
    <Page title={localize.orders.create.title} actions={[]}>
      <Grid
        container
        spacing={5}
        alignItems='flex-start'
        alignSelf='flex-start'
      >
        <Grid item xs={12}>
          <OrderFormStepper steps={steps} currentStep={currentStep} />
        </Grid>
        <Grid item xs={12} display='flex' justifyContent='space-around'>
          <Box
            sx={{
              maxWidth: '1000px',
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ flex: '1 1 auto' }}>
              {currentStep == 0 && (
                <OrderItemSelect
                  orderItems={orderItems}
                  setOrderItems={setOrderItems}
                  setHasErrors={setHasErrors}
                />
              )}
              {currentStep == 1 && (
                <ClientCustomerFields
                  fixedClientDiscount={fixedClientDiscount}
                  fixedCustomerDiscount={fixedCustomerDiscount}
                  setFixedClientDiscount={setFixedClientDiscount}
                  setFixedCustomerDiscount={setFixedCustomerDiscount}
                  setClient={setClient}
                  client={client}
                  customer={customer}
                  setCustomer={setCustomer}
                  promotion={promotion}
                  setPromotion={setPromotion}
                />
              )}
              <br />
              {currentStep == 1 && (
                <DeliveryFields
                  delivery={delivery}
                  setDelivery={setDelivery}
                  customer={customer}
                />
              )}
              {currentStep == 2 && (
                <UploadFilesFields files={files} setFiles={setFiles} />
              )}
              {submitting && (
                <Loading
                  text={
                    <>
                      <Typography variant='body1'>
                        Відправляємо дані...
                      </Typography>
                      <Typography variant='caption'>
                        Не повинно зайняти багато часу
                      </Typography>
                    </>
                  }
                />
              )}
            </Box>
            <br />
            <br />
            <Paper
              sx={{
                zIndex: 2,
                position: 'sticky',
                top: 'auto',
                bottom: '10px',
                maxWidth: '1000px',
                p: 2,
                display: 'flex',
                justifyContent: 'space-between',
                boxShadow:
                  'rgb(234, 236, 240) 0px 0px 15px, rgba(29, 41, 57, 0.08) 0px 1px 2px',
              }}
            >
              <Button
                variant='text'
                onClick={() => setCurrentStep(currentStep - 1)}
                disabled={submitting}
              >
                Назад
              </Button>
              <Button
                variant='contained'
                onClick={() => setCurrentStep(currentStep + 1)}
                disabled={submitting || hasErrors}
              >
                {currentStep == 2 ? 'Завершити' : 'Далі'}
              </Button>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
};

export default OrderCreatePage;
