import { FC, useContext, useEffect, useState } from 'react';
import Page from '../../components/Page/Page';
import Form from '../../components/Form/Form';
import { useNavigate, useParams } from 'react-router-dom';
import { NotificationContext } from '../../contexts/NotificationContext';
import { preparedRequestBody } from '../../utils/form';
import { CommonContext } from '../../contexts/CommonContext';
import { BookingApiClient } from '../../api/BookingApiClient';
import Loading from '../../components/Loading/Loading';
import { AnyObject } from '../../api/anyObjectTypes';
import localize from '../../localize';

export const BookingEditPage: FC = () => {
  const basicMainLabels: AnyObject = {
    product_modification_id: localize.bookings.columns.product_modification_id,
    count: localize.bookings.columns.count,
    comment: localize.bookings.columns.comment,
    user_id: localize.bookings.columns.select_user,
    booked_for_hours: localize.bookings.columns.booked_for_hours,
  };
  const { id } = useParams();
  const [values, setValues] = useState<AnyObject>({});
  const [mainFields, setMainFields] = useState<AnyObject[]>([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const { setNotification } = useContext(NotificationContext);
  const { currentProject } = useContext(CommonContext);
  const navigate = useNavigate();

  const prepareData = async (): Promise<void> => {
    if (id) {
      const res = await BookingApiClient.getShow(currentProject.id, id);

      setValues(res);
    }
  };

  const onSubmit = (mainItem: AnyObject) => {
    if (!id) return;
    setSubmitting(true);

    BookingApiClient.update(
      currentProject.id,
      {
        booking: preparedRequestBody({
          mainItem,
          mainFields,
          basicMainLabels,
        }),
      },
      id
    ).then(() => {
      setNotification({
        severity: 'success',
        message: localize.general.successUpdateMessage,
      });
      setSubmitting(false);
      navigate(`/cp/bookings/${id}`);
    });
  };

  useEffect(() => {
    setLoading(true);
    prepareData().finally(() => setLoading(false));
  }, [currentProject]);

  useEffect(() => {
    setMainFields([
      ...Object.keys(basicMainLabels || {}).map((el) => ({
        editable: true,
        hidden: true,
        internal_name: el,
        label: basicMainLabels[el],
        parsable: false,
        required: el !== 'comment' && el !== 'user_id',
      })),
    ]);
  }, [currentProject]);

  if (loading || !Object.keys(values).length)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page title={localize.bookings.edit.title} actions={[]}>
      <Form
        mainFields={mainFields}
        defaultMainValues={values}
        onSubmit={onSubmit}
        submitting={submitting}
      />
    </Page>
  );
};

export default BookingEditPage;
