import { FC, useContext, useEffect, useState } from 'react';
import Page from '../components/Page/Page';
import Form from '../components/Form/Form';
import { CommonContext } from '../contexts/CommonContext';
import { Grid } from '@mui/material';
import { AnyObject } from '../api/anyObjectTypes';
import localize from '../localize';
import { styled } from '@mui/material/styles';
import { ProjectApiClient } from '../api/ProjectApiClient';
import { uploadBlobToAws } from '../utils/aws';
import { ProjectUploadApiClient } from '../api/ProjectUploadApiClient';

const Container = styled('div')(({ theme }) => ({
  'form>div>div': {
    width: '100%',
  },
}));

type EditProjectPageProps = {};

export const EditProjectPage: FC<EditProjectPageProps> = () => {
  const basicMainLabels = {
    title: localize.projects.columns.title,
    logo: localize.projects.columns.logo,
  };
  const [values, setValues] = useState<AnyObject>({});
  const [mainFields, setMainFields] = useState<AnyObject[]>([]);
  const [loading, setLoading] = useState(true);
  const { currentProject } = useContext(CommonContext);
  const [submitting, setSubmitting] = useState(false);

  const prepareData = async (): Promise<void> => {
    return ProjectApiClient.getShow(currentProject.id).then((res) => {
      setValues({
        title: res.title,
        logo: res.logo
          ? [
              {
                name: res.logo.split('/')[res.logo.split('/').length - 1],
                url: res.logo_url,
              },
            ]
          : [],
      });
    });
  };

  const onSubmit = (mainItem: AnyObject) => {
    setSubmitting(true);

    ProjectApiClient.update(currentProject.id, {
      project: {
        title: mainItem.title,
      },
    }).then((res) => {
      uploadBlobToAws({
        projectId: currentProject.id,
        ApiClient: ProjectUploadApiClient,
        contentType: mainItem.logo[0].type,
        files: mainItem.logo,
      }).then((res) => {
        ProjectApiClient.update(currentProject.id, {
          project: {
            logo: res.success[0],
          },
        }).then(() => {
          window.location.reload();
        });
      });
    });
  };

  useEffect(() => {
    setLoading(true);
    prepareData().finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setMainFields([
      {
        editable: true,
        hidden: true,
        internal_name: 'title',
        label: basicMainLabels.title,
        parsable: false,
        required: false,
      },
      {
        editable: true,
        hidden: true,
        internal_name: 'logo',
        label: basicMainLabels.logo,
        field_type: 'image',
        parsable: false,
        required: false,
      },
    ]);
  }, []);

  if (loading || !Object.keys(values).length) return null;

  return (
    <Page title={localize.projects.edit.title} actions={[]}>
      <Grid
        container
        spacing={2}
        alignItems='flex-start'
        alignSelf='flex-start'
      >
        <Grid item xs={12}>
          <br />
          <br />
          <Container>
            <Form
              mainFields={mainFields}
              submitting={submitting}
              defaultMainValues={values}
              onSubmit={onSubmit}
            />
          </Container>
        </Grid>
      </Grid>
    </Page>
  );
};

export default EditProjectPage;
