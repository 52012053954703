import {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, Chip, CircularProgress, Typography } from '@mui/material';
import { Audit, AuditsApiClient } from '../api/AuditsApiClient';
import { CommonContext } from '../contexts/CommonContext';
import { formatDateTime } from '../utils/time';
import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
  timelineOppositeContentClasses,
  timelineItemClasses,
} from '@mui/lab';
import {
  auditedActionLabels,
  auditedTypeLabels,
  groupByRequestUUID,
  parsedAuditedChanges,
} from '../utils/audit';

type AuditsContentProps = {
  auditedType: 'Order';
  auditedId: string;
};

export const AuditsContent: FC<PropsWithChildren & AuditsContentProps> = ({
  auditedType,
  auditedId,
}) => {
  const { currentProject } = useContext(CommonContext);
  const [audits, setAudits] = useState<Audit[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchingAudit, setFetchingAudit] = useState<string>();
  const groupedAudits = useMemo(() => groupByRequestUUID(audits), [audits]);
  const TimelineComponent = Timeline as any;

  const fetchAudit = (auditId: string) => {
    setFetchingAudit(auditId);
    AuditsApiClient.getShow(currentProject?.id, auditId)
      .then((audit) => {
        const clonnedAudits = structuredClone(audits);
        const index = audits.findIndex((a) => a.id === audit.id);
        if (index < 0) return;

        clonnedAudits[index] = audit;
        setAudits(clonnedAudits);
      })
      .finally(() => setFetchingAudit(undefined));
  };

  useEffect(() => {
    if (auditedType && auditedId) {
      setLoading(true);
      AuditsApiClient.getIndex(currentProject?.id, auditedType, auditedId)
        .then(setAudits)
        .finally(() => setLoading(false));
    }
  }, [auditedType, auditedId]);

  return (
    <>
      <Typography
        variant='h4'
        sx={{
          textTransform: 'uppercase',
          opacity: '.6',
          marginTop: '50px',
          fontWeight: '400',
        }}
      >
        Активність
      </Typography>
      <TimelineComponent
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {audits?.length && !loading ? (
          Object.keys(groupedAudits).map((requestId, i) => (
            <TimelineItem key={`${requestId}-${i}-${Math.random()}`}>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant='overline'>
                  {groupedAudits[requestId][0].user?.name} -{' '}
                  {formatDateTime(groupedAudits[requestId][0].created_at)}
                </Typography>
                {groupedAudits[requestId].map((audit) => (
                  <Box sx={{ pb: 1 }}>
                    <Typography variant='caption'>
                      {auditedTypeLabels[audit.auditable_type]} -{' '}
                      {auditedActionLabels[audit.action]}
                    </Typography>
                    {parsedAuditedChanges(audit).map((change) => (
                      <Typography key={change.label}>
                        <b>{change.label}:</b>{' '}
                        {change.content?.length > 1 ? (
                          change.content.map((ch, i) => (
                            <span
                              style={
                                i === 0
                                  ? {
                                      textDecoration: 'line-through',
                                      opacity: '.5',
                                    }
                                  : {}
                              }
                            >
                              {i === 1 ? ' ' : ''}
                              {ch}
                            </span>
                          ))
                        ) : change.content[0] === '..._id' ? (
                          fetchingAudit === audit.id ? (
                            <CircularProgress size={15} />
                          ) : (
                            <Chip
                              label={'• • •'}
                              sx={{
                                height: 'auto',
                                bgcolor: '#434242',
                                color: 'white',
                                cursor: 'pointer',
                                span: { padding: '0 10px' },
                              }}
                              disabled={!!fetchingAudit}
                              onClick={() => fetchAudit(audit.id)}
                            />
                          )
                        ) : (
                          change.content[0]
                        )}
                      </Typography>
                    ))}
                  </Box>
                ))}
              </TimelineContent>
            </TimelineItem>
          ))
        ) : (
          <TimelineItem>
            <TimelineSeparator sx={{ posting: 'relative' }}>
              {loading ? (
                <CircularProgress
                  size={20}
                  sx={{ position: 'absolute', top: '8px' }}
                />
              ) : null}
              <TimelineDot sx={loading ? { opacity: '.1' } : {}} />
              <TimelineConnector sx={{ mt: 2 }} />
            </TimelineSeparator>
            <TimelineContent>
              <Typography
                variant='body1'
                sx={{
                  color: '#8B8B8B',
                  fontStyle: 'italic',
                }}
              >
                {loading ? 'завантажуємо дані' : 'тут поки пусто'}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        )}
      </TimelineComponent>
    </>
  );
};

export default AuditsContent;
