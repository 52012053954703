import {
  ChangeEvent,
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowUpIcon from '@mui/icons-material/ArrowDropUp';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  IconButton,
  Collapse,
  Checkbox,
  Pagination,
} from '@mui/material';

import { AnyObject } from '../../api/anyObjectTypes';
import localize from '../../localize';
import { formatDateTime } from '../../utils/time';
import { CommonContext } from '../../contexts/CommonContext';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';

import { ReactComponent as EditItemIconSvg } from '../../assets/edit-item.svg';
import { ReactComponent as DeleteItemIconSvg } from '../../assets/delete-item.svg';
import { ReactComponent as DoneIconSvg } from '../../assets/done-check-mark.svg';

export type Column = {
  [key: string]: any;
};

type BasicTableProps = {
  rows: AnyObject[];
  columns: Column[];
  isCheckbox?: boolean;
  isWithPhoto?: boolean;
  onRowClick?: (row: AnyObject) => void;
  onEditClick?: (row: AnyObject) => void;
  onDeleteClick?: (row: AnyObject) => void;
  onDoneClick?: (row: AnyObject) => void;
  onColapsedRowClick?: (row: AnyObject, key?: string) => void;
  collapsedContentColumns?: AnyObject;
  isInner?: boolean;
  colorRow?: boolean;
  noPagination?: boolean;
  expandColumns?: boolean;
};

export const BasicTable: FC<BasicTableProps> = ({
  rows,
  columns,
  collapsedContentColumns,
  isInner,
  isCheckbox,
  isWithPhoto,
  colorRow,
  onRowClick,
  onEditClick,
  onDeleteClick,
  onDoneClick,
  onColapsedRowClick,
  noPagination = false,
  expandColumns = false,
}) => {
  const { currentUser } = useContext(CommonContext);
  const tableRef = useRef<HTMLTableElement>(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [open, setOpen] = useState<number | null>(null);
  const [width, setWidth] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState<AnyObject | null>(
    null
  );

  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());

  const expandable = useMemo(
    () => Object.keys(collapsedContentColumns || {}).length,
    [collapsedContentColumns]
  );

  const actionsWidth = useMemo(
    () =>
      [onDeleteClick, onEditClick, onDoneClick].filter((el) => !!el).length *
      40,
    [onDeleteClick, onEditClick, onDoneClick]
  );

  const tableMaxWidth = useMemo(
    () =>
      columns
        .map((c, j) => c.width || (c.align === 'left' || j === 0 ? 200 : 140))
        .reduce((partialSum, a) => partialSum + a, 0) +
      (expandable || isInner ? 32 : 0) +
      48,
    [columns]
  );

  const extraWidth = useMemo(
    () => (tableMaxWidth > width || isInner ? 0 : width - tableMaxWidth),
    [columns, width]
  );

  const plusWidthForLeftAligned = useMemo(
    () =>
      extraWidth /
      columns.filter((c, j) => c.align === 'left' || j === 0).length,
    [extraWidth]
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setOpen(null);
  };

  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    if (
      rows
        .slice((page - 1) * rowsPerPage, page * rowsPerPage)
        .some((item) => !selectedRows.has(item.id))
    ) {
      setSelectedRows((prevSelectedRows) => {
        const newSelectedRows = new Set(prevSelectedRows);
        rows
          .slice((page - 1) * rowsPerPage, page * rowsPerPage)
          .forEach((item) => newSelectedRows.add(item.id));
        return newSelectedRows;
      });
    } else {
      setSelectedRows((prevSelectedRows) => {
        const newSelectedRows = new Set(prevSelectedRows);
        rows
          .slice((page - 1) * rowsPerPage, page * rowsPerPage)
          .forEach((item) => newSelectedRows.delete(item.id));
        return newSelectedRows;
      });
    }
  };

  const handleCheckboxChange = (rowIndex: string) => {
    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = new Set(prevSelectedRows);
      if (newSelectedRows.has(rowIndex)) {
        newSelectedRows.delete(rowIndex);
      } else {
        newSelectedRows.add(rowIndex);
      }
      return newSelectedRows;
    });
  };

  const deleteItem = () => {
    if (openDeleteModal && onDeleteClick) {
      onDeleteClick(openDeleteModal);
      setOpenDeleteModal(null);
    }
  };

  useEffect(() => {
    setWidth(
      document.getElementsByClassName('basic-table')[0]?.clientWidth || 0
    );
  }, [width, columns]);

  return (
    <>
      <ConfirmationDialog
        header={`${localize.products.delete.title} ${openDeleteModal?.title || openDeleteModal?.name || 'це'}?`}
        image='/delete-image.svg'
        open={!!openDeleteModal}
        description={localize.products.delete.text}
        action={() => deleteItem()}
        discard={() => setOpenDeleteModal(null)}
        mainActionButtonText={localize.products.delete.deleteBtn}
      />
      {isCheckbox && (
        <Box display='flex' alignItems='center' marginBottom='10px' gap='10px'>
          <Typography
            variant='subtitle2'
            color='#5B4D8F'
            sx={{ textDecoration: 'underline' }}
          >{`Selected ${selectedRows.size} from ${rows.length}`}</Typography>
          <IconButton sx={{ width: 40, height: 40 }}>
            <DeleteItemIconSvg />
          </IconButton>
          {/* <IconButton sx={{ width: 40, height: 40 }}>
            <DeleteItemIconSvg />
          </IconButton> */}
        </Box>
      )}
      <Box
        sx={{
          background: isInner ? '#fff' : 'none',
          margin: isInner ? '10px 5px' : '0',
        }}
        width={isInner ? tableMaxWidth + 20 : '100%'}
        className={`${isInner && '-inner'}`}
      >
        <TableContainer className='basic-table'>
          <Table
            sx={{ minWidth: 320, tableLayout: 'fixed' }}
            size={isInner ? 'small' : 'medium'}
            ref={tableRef}
          >
            <TableHead>
              <TableRow className={isInner ? '-inner' : 'main'}>
                {!isInner && isCheckbox && (
                  <TableCell
                    key='checkbox-head'
                    className='column-sticky'
                    sx={{
                      minWidth: 60,
                      maxWidth: 60,
                      width: 60,
                      background: '#fff',
                      left: 0,
                    }}
                  >
                    <Checkbox
                      checked={rows.every((item) => selectedRows.has(item.id))}
                      indeterminate={rows
                        .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                        .every((item) => selectedRows.has(item.id))}
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                )}
                {!isInner && isWithPhoto && (
                  <TableCell
                    key='photo-head'
                    className='row-image'
                    sx={{
                      minWidth: 80,
                      maxWidth: 80,
                      width: 80,
                    }}
                  ></TableCell>
                )}
                {expandable || isInner ? (
                  <TableCell
                    key='expand-head'
                    className='column-sticky'
                    colSpan={0}
                    sx={{
                      minWidth: 45,
                      maxWidth: 45,
                      width: 45,
                      left: isCheckbox ? '55px' : '0',
                      background: '#fff',
                    }}
                  />
                ) : null}

                {columns.map((c, j) => {
                  const leftAligned = c.align === 'left' || j === 0;
                  return (
                    <TableCell
                      sx={{
                        left: isCheckbox ? '100px' : expandable ? '40px' : 0,
                        borderRightColor:
                          c.field === 'title' ? 'black' : 'transparent',
                        textAlign: 'left',
                        minWidth:
                          c.width ||
                          (leftAligned
                            ? 200 + (plusWidthForLeftAligned || 0)
                            : 140),
                        maxWidth:
                          c.width ||
                          (leftAligned
                            ? 200 + (plusWidthForLeftAligned || 0)
                            : 140),
                        width:
                          c.width ||
                          (leftAligned
                            ? 200 + (plusWidthForLeftAligned || 0)
                            : 140),
                      }}
                      key={c.headerName}
                      align={leftAligned ? 'left' : 'right'}
                      className={c.field === 'title' ? 'column-sticky' : ''}
                    >
                      {c.headerName}
                    </TableCell>
                  );
                })}
                {!isInner &&
                  (!!onDeleteClick || !!onEditClick || !!onDoneClick) && (
                    <TableCell
                      key='actions-head'
                      className='column-sticky'
                      sx={{
                        minWidth: actionsWidth,
                        maxWidth: actionsWidth,
                        width: actionsWidth,
                        background: '#fff',
                        right: 0,
                        textAlign: 'right',
                        borderLeftColor: 'black',
                      }}
                    >
                      {localize.products.columns.actions}
                    </TableCell>
                  )}
              </TableRow>
            </TableHead>
            <TableBody>
              {(noPagination
                ? rows
                : rows.slice(rowsPerPage * (page - 1), page * rowsPerPage)
              ).map((row, i) => [
                <TableRow
                  key={row.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    background: i % 2 === 0 && !isInner ? '#FAF9FF' : '#fff',
                  }}
                  className={`${isInner ? '-inner' : ''} ${
                    colorRow || selectedRows.has(row.id) ? '-color-row' : ''
                  } ${open === i ? '-opened' : ''}`}
                  hover={!!onRowClick}
                >
                  {!isInner && isCheckbox && (
                    <TableCell
                      key={`checkbox-row-${row.id}`}
                      className='column-sticky'
                      sx={{
                        minWidth: 60,
                        maxWidth: 60,
                        width: 60,
                        left: 0,
                      }}
                    >
                      <Checkbox
                        checked={selectedRows.has(row.id)}
                        onChange={() => handleCheckboxChange(row.id)}
                      />
                    </TableCell>
                  )}
                  {!isInner && isWithPhoto && (
                    <TableCell
                      key={`photo-row-${row.id}`}
                      className='row-image'
                      sx={{
                        minWidth: 80,
                        maxWidth: 80,
                        width: 80,
                      }}
                    >
                      <Box width={80} height={100}>
                        <img
                          src={
                            row.additional_fields.preview_images
                              ? row.additional_fields.preview_images[0].url
                              : '/logo.svg'
                          }
                          alt={row.title}
                          style={{
                            objectFit: 'contain',
                            width: '100%',
                            height: '100%',
                            display: 'block',
                          }}
                        />
                      </Box>
                    </TableCell>
                  )}

                  {expandable ? (
                    <TableCell
                      key={`expand-row-${i}`}
                      className='column-sticky'
                      sx={{
                        minWidth: 45,
                        maxWidth: 45,
                        width: 45,
                        padding: '0 0 0 5px',
                        left: isCheckbox ? '55px' : '0',
                      }}
                    >
                      {!row['hide_collapsed'] ? (
                        <IconButton
                          aria-label='expand row'
                          size='small'
                          onClick={(e): void => {
                            e.stopPropagation();
                            setOpen(open === i ? null : i);
                          }}
                        >
                          {open === i ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      ) : null}
                    </TableCell>
                  ) : isInner ? (
                    <TableCell
                      key={`expand-row-${i}`}
                      sx={{ minWidth: 32, maxWidth: 32, width: 32 }}
                    />
                  ) : null}
                  {columns.map((c, j) => {
                    const leftAligned = c.align === 'left' || j === 0;
                    return (
                      <TableCell
                        key={`${c.field}-${i}`}
                        onClick={() => {
                          onRowClick && onRowClick(row);
                        }}
                        align={
                          leftAligned || c.type === 'text' ? 'left' : 'right'
                        }
                        sx={{
                          left: isCheckbox ? '100px' : expandable ? '40px' : 0,
                          textAlign: 'left',
                        }}
                        className={`${c.field === 'title' ? 'column-sticky' : ''} ${expandColumns ? 'expand-column' : ''}`}
                      >
                        {!!c.renderCell ? (
                          c.renderCell(row)
                        ) : c.valueGetter ? (
                          <Typography
                            variant='body1'
                            sx={{ whiteSpace: 'pre-line' }}
                          >
                            {typeof c.valueGetter(row) === 'boolean'
                              ? c.valueGetter(row)
                                ? localize.general.true
                                : localize.general.false
                              : c.valueGetter(row)}
                          </Typography>
                        ) : c.type === 'boolean' ? (
                          <Typography variant='body1'>
                            {row[c.field]
                              ? localize.general.true
                              : localize.general.false}
                          </Typography>
                        ) : c.type === 'datetime' && row[c.field] ? (
                          <Typography variant='body1'>
                            {formatDateTime(row[c.field])}
                          </Typography>
                        ) : (
                          <Typography variant='body1'>
                            {row[c.field]}
                          </Typography>
                        )}
                      </TableCell>
                    );
                  })}
                  {!isInner &&
                    (!!onDeleteClick || !!onEditClick || !!onDoneClick) && (
                      <TableCell
                        key={`actions-head-${i}`}
                        className='column-sticky'
                        sx={{
                          minWidth: actionsWidth,
                          maxWidth: actionsWidth,
                          width: actionsWidth,
                          right: 0,
                        }}
                      >
                        <Box
                          display='flex'
                          gap='5px'
                          justifyContent='flex-end'
                          mr='-10px'
                        >
                          {onDoneClick && row.status === 'new' && (
                            <IconButton
                              sx={{
                                width: 40,
                                height: 40,
                                '&:hover': { background: '#53c17f6f' },
                              }}
                              onClick={() => {
                                onDoneClick && onDoneClick(row);
                              }}
                            >
                              <DoneIconSvg fill='#777777' />
                            </IconButton>
                          )}
                          {onEditClick && (
                            <IconButton
                              sx={{ width: 40, height: 40 }}
                              onClick={() => {
                                onEditClick && onEditClick(row);
                              }}
                            >
                              <EditItemIconSvg fill='#777777' />
                            </IconButton>
                          )}
                          {onDeleteClick && (
                            <IconButton
                              sx={{
                                width: 40,
                                height: 40,
                                '&:hover': { background: '#fa3b3b73' },
                              }}
                              onClick={() => {
                                setOpenDeleteModal(row);
                              }}
                            >
                              <DeleteItemIconSvg fill='#777777' />
                            </IconButton>
                          )}
                        </Box>
                      </TableCell>
                    )}
                </TableRow>,
                collapsedContentColumns && !row['hide_collapsed'] ? (
                  <TableRow
                    key={`${row.id}-colapsed`}
                    className={`${row.id}-colapsed`}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      background: '#F0F0F0',
                    }}
                  >
                    <TableCell
                      sx={{
                        padding: 0,
                        paddingTop: '0',
                        border: 0,
                        borderRadius: '8px',
                      }}
                      colSpan={columns.length + 1}
                    >
                      <Collapse in={i === open} timeout='auto' unmountOnExit>
                        <br />
                        {Object.keys(collapsedContentColumns).map((key) => (
                          <BasicTable
                            columns={collapsedContentColumns[key]}
                            key={`${row.id}-bt-${key}-collaosable`}
                            rows={
                              Array.isArray(row[key]) ? row[key] : [row[key]]
                            }
                            onRowClick={(r): void => {
                              onColapsedRowClick && onColapsedRowClick(r, key);
                            }}
                            noPagination
                            isInner
                          />
                        ))}
                        <br />
                      </Collapse>
                    </TableCell>
                  </TableRow>
                ) : null,
              ])}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
          mt='10px'
        >
          {rows.length > rowsPerPage && !noPagination ? (
            <Pagination
              count={Math.ceil(rows.length / rowsPerPage)}
              page={page}
              onChange={handleChangePage}
              color='primary'
            />
          ) : null}
        </Box>
      </Box>
    </>
  );
};
