import { AnyObject } from './anyObjectTypes';

const apiDomain =
  process.env.NODE_ENV === 'production'
    ? '/api'
    : 'https://factorywise-dev-r7nxc.ondigitalocean.app/api';

export const get = async (path: string) => {
  return await fetch(`${apiDomain}/${path}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem('accessToken')}`,
    },
  }).then((res) => {
    if (res.status > 400) throw Error(`${res.status}`);

    return res.json();
  });
};

export const post = async (path: string, requestBody: AnyObject) => {
  return await fetch(`${apiDomain}/${path}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer: ${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify(requestBody),
  }).then((res) => {
    if (res.status > 400) throw Error(`${res.status}`);

    return res.json();
  });
};

export const put = async (path: string, requestBody: AnyObject) => {
  return await fetch(`${apiDomain}/${path}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer: ${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify(requestBody),
  }).then((res) => {
    if (res.status > 400) throw Error(`${res.status}`);

    return res.json();
  });
};

export const destroy = async (path: string) => {
  return await fetch(`${apiDomain}/${path}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer: ${localStorage.getItem('accessToken')}`,
    },
  }).then((res) => {
    if (res.status > 400) throw Error(`${res.status}`);

    return 'Deleted!';
  });
};
