import { Link } from '@mui/material';
import { AnyObject } from '../api/anyObjectTypes';
import { CustomField } from '../api/TemplateApi';
import { FileContainer } from '../components/FileContainer';

export const preparedColumns = (cols: CustomField[]) =>
  cols.map((col: CustomField) => ({
    field: col?.internal_name,
    headerName:
      col?.localized_field && col?.localized_field['ua']?.label
        ? col?.localized_field['ua']?.label
        : col?.label,
    type: col?.field_type,
    renderCell:
      col?.field_type === 'files'
        ? (row: any) =>
            (
              (row && row[col?.internal_name]) ||
              (row.additional_fields &&
                row.additional_fields[col?.internal_name])
            )?.map((el: any) => (
              <FileContainer key={el.url}>
                <Link href={el.url} target='_blank'>
                  {el.name}
                </Link>
              </FileContainer>
            ))
        : undefined,
    valueGetter: (row: AnyObject) => {
      const currentUserLocale = 'ua';
      const value = Object.keys(row).includes(col?.internal_name)
        ? col?.parsable
          ? row[`parsed_${col?.internal_name}`]
          : row[col?.internal_name]
        : row.additional_fields && row.additional_fields[col?.internal_name];

      if (
        col?.localized_field &&
        col?.localized_field[currentUserLocale] &&
        col?.localized_field[currentUserLocale]?.options &&
        value
      )
        return col?.localized_field[currentUserLocale]?.options[value] || value;

      return value;
    },
    width: col?.field_type === 'text' ? '200px' : undefined,
  }));
