import { FC } from 'react';
import { Order } from '../../api/OrderApiClient';
import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Avatar,
  ListItemAvatar,
} from '@mui/material';
import localize from '../../localize';
import { formatDateTime } from '../../utils/time';
import { statusColors, statusIcons } from '../StatusesTimeline';
import {
  HistoryToggleOff as HistoryToggleOffIcon,
  PointOfSale as PointOfSaleIcon,
  Update as UpdateIcon,
} from '@mui/icons-material';

type GeneralCardProps = {
  order: Order;
};

export const GeneralCard: FC<GeneralCardProps> = ({ order }) => {
  return (
    <Card sx={{ border: '1px solid #DFDDF9', borderRadius: '8px' }}>
      <CardContent>
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: statusColors[order.last_status] }}>
                {statusIcons[order.last_status]}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={localize.order_statuses.values[order.last_status]}
              secondary='Статус'
            />
          </ListItem>
          {order.sent_to_manager_at && (
            <ListItem disablePadding>
              <ListItemAvatar>
                <Avatar>
                  <HistoryToggleOffIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={formatDateTime(order.sent_to_manager_at)}
                secondary='Вперше відправлено на обробку'
              />
            </ListItem>
          )}
          <ListItem disablePadding>
            <ListItemAvatar>
              <Avatar>
                <UpdateIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={formatDateTime(order.updated_at)}
              secondary='Востаннє оновлено'
            />
          </ListItem>
          {order.fixed_recommended_sum_with_discount != order.sum ? (
            <ListItem disablePadding>
              <ListItemAvatar>
                <Avatar>
                  <PointOfSaleIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={order.fixed_recommended_sum_with_discount}
                secondary='Сума до сплати покупцем'
              />
            </ListItem>
          ) : null}
          <ListItem disablePadding>
            <ListItemAvatar>
              <Avatar>
                <PointOfSaleIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={order.sum} secondary='Сума замовлення' />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};

export default GeneralCard;
