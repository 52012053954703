import { FC } from 'react';
import { Stepper, Box, Step, StepLabel } from '@mui/material';

type OrderFormStepperProps = {
  steps: string[];
  currentStep: number;
};

export const OrderFormStepper: FC<OrderFormStepperProps> = ({
  steps,
  currentStep,
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={currentStep} alternativeLabel>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

export default OrderFormStepper;
