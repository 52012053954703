import { FC, useContext, useEffect, useState } from 'react';
import Page from '../../components/Page/Page';
import Form from '../../components/Form/Form';
import { useNavigate, useParams } from 'react-router-dom';
import { NotificationContext } from '../../contexts/NotificationContext';
import { preparedRequestBody } from '../../utils/form';
import { CommonContext } from '../../contexts/CommonContext';
import { PromotionApiClient } from '../../api/PromotionApiClient';
import { AnyObject } from '../../api/anyObjectTypes';
import localize from '../../localize';
import Loading from '../../components/Loading/Loading';
import dayjs from 'dayjs';

export const PromotionEditPage: FC = () => {
  const basicMainFields: AnyObject = [
    {
      internal_name: 'title',
      field_type: 'string',
      required: true,
      label: 'Назва',
    },
    {
      internal_name: 'promo',
      field_type: 'string',
      required: false,
      label: 'Промокод',
    },
    {
      internal_name: 'is_one_time_use_only',
      field_type: 'boolean',
      required: false,
      label: 'Одноразово',
    },
    {
      internal_name: 'discount',
      field_type: 'number',
      required: true,
      label: 'Розмір знижки',
    },
    {
      internal_name: 'discount_type',
      field_type: 'string',
      required: true,
      label: 'Тип знижки',
      options: ['Відсоткова', 'Фіксована'],
    },
    {
      internal_name: 'affects_all',
      field_type: 'boolean',
      required: true,
      label: 'Застосовується до всіх продуктів?',
    },
    {
      internal_name: 'starts_at',
      field_type: 'datetime',
      required: false,
      label: 'Діє від',
    },
    {
      internal_name: 'expires_at',
      field_type: 'datetime',
      required: false,
      label: 'Діє до',
    },
  ];
  const [mainFields, setMainFields] = useState<AnyObject[]>([]);
  const [subItemFields, setSubItemFields] = useState<AnyObject[]>([]);
  const { setNotification } = useContext(NotificationContext);
  const [submitting, setSubmitting] = useState(false);
  const [subItemFieldsVisible, setSubItemFieldsVisible] = useState(false);
  const { currentProject } = useContext(CommonContext);
  const navigate = useNavigate();
  const [values, setValues] = useState<AnyObject>({});
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const prepareData = async (): Promise<void> => {
    if (id) {
      const res = {
        ...(await PromotionApiClient.getShow(currentProject.id, id)),
      } as AnyObject;
      if (res.starts_at) res.starts_at = dayjs(res.starts_at);

      if (res.expires_at) res.expires_at = dayjs(res.expires_at);

      setValues(res);
    }
  };

  const onChangeCallback = (mainItem: any, subItems: any[]) => {
    if (!!mainItem.affects_all) {
      setSubItemFieldsVisible(false);
    } else {
      setSubItemFieldsVisible(true);
    }
  };

  const onSubmit = (mainItem: AnyObject, subItems: AnyObject[]) => {
    if (!id) return;

    setSubmitting(true);
    PromotionApiClient.update(
      currentProject.id,
      {
        promotion: preparedRequestBody({
          mainItem,
          mainFields,
          basicMainLabels: basicMainFields.reduce((acc: any, field: any) => {
            let { label, internal_name } = field;
            return { ...acc, [internal_name]: label };
          }, {}),
          ...(subItemFieldsVisible
            ? {
                basicSubItemLabels: {
                  product_modification_id: 'product_modification_id',
                  product_id: 'product_id',
                },
                subItems,
                subItemFields,
                subItemsKey: 'promotion_items_attributes',
              }
            : {}),
        }),
      },
      id
    )
      .then((res) => {
        setNotification({
          severity: 'success',
          message: localize.general.successCreateMessage,
        });
        setSubmitting(false);
        navigate(`/cp/promotions/${res.id}`);
      })
      .catch((err) => {
        setNotification({
          severity: 'error',
          message: localize.general.unexpectedError,
        });
        setSubmitting(false);
        navigate(`/cp/promotions`);
      });
  };

  useEffect(() => {
    setMainFields([
      ...basicMainFields.map((el: any) => ({
        ...el,
        editable: true,
        hidden: true,
        parsable: false,
      })),
    ]);

    setLoading(true);
    prepareData().finally(() => setLoading(false));
  }, [currentProject]);

  useEffect(() => {
    if (subItemFieldsVisible) {
      setSubItemFields(
        [
          {
            internal_name: 'product_modification_id',
            field_type: 'string',
            required: true,
            label: 'Модифікація продукту',
          },
          // { internal_name: "product_id", field_type: "string", required: true, label: "Продукт (зі всіма модифікаціями)" },
          // { internal_name: "discount", field_type: "number", required: false, label: "Розмір знижки" },
          // { internal_name: "discount_type", field_type: "string", required: false, label: "Тип знижки", options: ["Відсоткова", "Фіксована"] },
        ].map((el) => ({
          ...el,
          editable: true,
          hidden: false,
          parsable: false,
        }))
      );
    } else {
      setSubItemFields([]);
    }
  }, [subItemFieldsVisible]);

  if (loading || !Object.keys(values).length)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page title={localize.promotions.create.title} actions={[]}>
      <Form
        mainFields={mainFields}
        defaultMainValues={values}
        defaultSubItemValues={values['promotion_items']}
        subItemFields={subItemFields}
        addModificationText={'Додати продукти (модифікації) на які буде знижка'}
        submitting={submitting}
        onChangeCallback={onChangeCallback}
        onSubmit={onSubmit}
      />
    </Page>
  );
};

export default PromotionEditPage;
