import React from 'react';
import { styled } from '@mui/material';

const ProgressBarContainer = styled('div')(() => ({
  height: '50px',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: '100%',
}));

const ProgressBarOuterLine = styled('div')(() => ({
  width: '100%',
  borderRadius: '20px',
  height: '13px',
  backgroundColor: '#EDEFF3',
  overflow: 'hidden',
}));

const ProgressBarInnerLine = styled('div')(() => ({
  borderRadius: 'inherit',
  height: '100%',
  backgroundColor: 'red',
}));

const ProgressBarCircle = styled('div')(() => ({
  position: 'absolute',
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  backgroundColor: '#fff',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  img: {
    width: '29px',
    height: '29px',
    display: 'block',
  },
}));

export const StaticProgressBar = ({
  progress,
  color,
  iconPath,
}: {
  progress: number;
  color: string;
  iconPath: string;
}) => {
  return (
    <ProgressBarContainer>
      <ProgressBarCircle
        sx={{ top: '-18.5px', left: `calc(${progress}% - 50px)` }}
      >
        <img src={iconPath} alt='icon' />
      </ProgressBarCircle>
      <ProgressBarOuterLine>
        <ProgressBarInnerLine
          sx={{ width: `${progress}%`, backgroundColor: color }}
        />
      </ProgressBarOuterLine>
    </ProgressBarContainer>
  );
};
