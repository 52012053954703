const strDateToMS = (date?: string) => (date ? new Date(date).getTime() : 0);

export const checkNumberValue = (
  row: any,
  name: string,
  value?: [any, any]
) => {
  const rowValue = Object.keys(row).includes(name)
    ? row[name]
    : (row.additional_fields || {})[name];

  if (
    value === undefined ||
    ([null, undefined, ''].includes(value[0]) &&
      [null, undefined, ''].includes(value[1]))
  )
    return true;
  if (rowValue === undefined) return false;

  if (
    ![null, undefined, ''].includes(value[0]) &&
    ![null, undefined, ''].includes(value[1])
  )
    return (
      parseInt(rowValue) >= parseInt(value[0]) &&
      parseInt(rowValue) <= parseInt(value[1])
    );

  if (![null, undefined, ''].includes(value[0]))
    return parseInt(rowValue) >= parseInt(value[0]);

  if (![null, undefined, ''].includes(value[1]))
    return parseInt(rowValue) <= parseInt(value[1]);

  return true;
};

export const checkStringValue = (row: any, name: string, value?: string) => {
  const rowValue = Object.keys(row).includes(name)
    ? row[name]
    : (row.additional_fields || {})[name];

  if (!value) return true;
  if (!rowValue) return false;

  return rowValue?.toLowerCase()?.includes(value?.toLowerCase());
};

export const checkOptionValue = (row: any, name: string, value?: string[]) => {
  const rowValue = Object.keys(row).includes(name)
    ? row[name]
    : (row.additional_fields || {})[name];

  if (value === undefined) return true;
  if (rowValue === undefined) return false;

  return value?.includes(rowValue);
};

export const checkDateValue = (row: any, name: string, value?: [any, any]) => {
  const rowValue = Object.keys(row).includes(name)
    ? row[name]
    : (row.additional_fields || {})[name];

  if (
    value === undefined ||
    ([null, undefined, ''].includes(value[0]) &&
      [null, undefined, ''].includes(value[1]))
  )
    return true;
  if (rowValue === undefined) return false;

  if (
    ![null, undefined, ''].includes(value[0]) &&
    ![null, undefined, ''].includes(value[1])
  )
    return (
      strDateToMS(rowValue) >= strDateToMS(value[0]) &&
      strDateToMS(rowValue) <= strDateToMS(value[1])
    );

  if (![null, undefined, ''].includes(value[0]))
    return strDateToMS(rowValue) >= strDateToMS(value[0]);

  if (![null, undefined, ''].includes(value[1]))
    return strDateToMS(rowValue) <= strDateToMS(value[1]);

  return true;
};
