import { FC, useContext, useEffect, useMemo, useState } from 'react';
import {
  Card,
  CardContent,
  Button,
  Box,
  Grid,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Product, ProductApiClient } from '../../api/ProductApiClient';
import { AnyObject } from '../../api/anyObjectTypes';
import FormField from '../../components/FormField/FormField';
import { styled } from '@mui/material/styles';
import { OrderItem } from '../../api/OrderApiClient';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { CommonContext } from '../../contexts/CommonContext';
import Loading from '../../components/Loading/Loading';

type OrderItemSelectProps = {
  orderItems: (OrderItem | any)[];
  setOrderItems(orderItems: (OrderItem | any)[]): void;
  setHasErrors(hasErrors: boolean): void;
};

const SingleItemContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  minWidth: '100%',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const OrderItemSelect: FC<OrderItemSelectProps> = ({
  orderItems,
  setOrderItems,
  setHasErrors,
}) => {
  const { currentProject } = useContext(CommonContext);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [products, setProducts] = useState<Product[]>([]);
  const [duplications, setDuplications] = useState<OrderItem[] | any[]>([]);

  const fetchProducts = async () => {
    setLoadingProducts(true);
    const res = await ProductApiClient.getIndex(currentProject?.id);
    setLoadingProducts(false);
    setProducts(res);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    const dups = orderItems.filter(
      (orderItem) =>
        orderItems.filter(
          (el) =>
            el.product_modification_id == orderItem.product_modification_id &&
            !el['_destroy']
        )?.length > 1
    );
    const noCount = orderItems.some(
      (orderItem) => orderItem.count <= 0 && !orderItem['_destroy']
    );

    setDuplications(dups);
    setHasErrors(!!dups.length || noCount);
  }, [orderItems]);

  const autocompleteOptions = useMemo(() => {
    if (!products.length) return [];

    return products
      .map((el) =>
        el.product_modifications.map((pm: AnyObject) => ({
          groupLabel: [
            el.additional_fields?.secondaryid,
            el.additional_fields?.n_collection,
            el.title,
          ]
            .filter((f) => !!f)
            .join(' - '),
          label: `${pm.parsed_title} || ціна: ${pm.price}; доступно (од): ${pm.available_count};`,
          price: pm.price,
          pm: pm,
          product: el,
          id: pm.id,
        }))
      )
      .flat();
  }, [products]);

  const handleInputChange = (
    e: { target: { name: any; value: any } },
    itemIndex: number
  ) => {
    const { name, value } = e.target;
    const newOrderItems = orderItems.slice();
    newOrderItems[itemIndex] = { ...newOrderItems[itemIndex], [name]: value };
    setOrderItems(newOrderItems);
  };

  const deleteSubItem = (itemIndex: number) => {
    let newOrderItems = orderItems.slice();
    if (newOrderItems[itemIndex]['id']) {
      newOrderItems[itemIndex]['_destroy'] = true;
    } else {
      newOrderItems.splice(itemIndex, 1);
    }
    setOrderItems(newOrderItems);
  };

  const addMoreClick = () => {
    setOrderItems([...orderItems.slice(), {}]);
  };

  if (loadingProducts) return <Loading />;

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            {orderItems.map((orderItem, i) => {
              if (orderItem['_destroy']) return null;

              return (
                <SingleItemContainer
                  key={`${orderItem.product_modification_id}-${i}`}
                >
                  <FormField
                    sx={{ flex: '1 1 auto', width: '100%', maxWidth: '100%' }}
                    value={orderItem.product_modification_id || ''}
                    label='Виберіть продукт (специфікацію)'
                    name='product_modification_id'
                    required={true}
                    error={
                      duplications.includes(orderItem)
                        ? 'Така модифікація вже обрана, видаліть повторення.'
                        : !orderItem.product_modification_id
                          ? 'Виберіть щось або видаліть стрічку'
                          : ''
                    }
                    onChange={(e) => handleInputChange(e, i)}
                    autocomplete={{
                      options: autocompleteOptions,
                      renderOption: (props: any, option: any) => (
                        <li {...props} key={option.id}>
                          Назва:&nbsp;<b>{option.pm.parsed_title}</b>;&nbsp;
                          Ціна:&nbsp;<b>{option.pm.price}</b>;&nbsp; Доступно
                          (од.):&nbsp;<b>{option.pm.available_count}</b>;
                        </li>
                      ),
                      onChange: (_, newValue: any) => {
                        const newOrderItems = orderItems.slice();
                        const newFields = newValue?.price
                          ? {
                              product_modification_id: newValue.id,
                              fixed_price: +newValue.price,
                            }
                          : {
                              product_modification_id: newValue,
                            };
                        newOrderItems[i] = {
                          ...newOrderItems[i],
                          ...newFields,
                        };

                        setOrderItems(newOrderItems);
                      },
                    }}
                  />
                  <Box sx={{ display: 'flex', width: '100%', flex: 0 }}>
                    <FormField
                      sx={{ minWidth: '120px', width: '100%', flex: 1 }}
                      value={orderItem.count || ''}
                      label='Кількість'
                      fieldType='number'
                      name='count'
                      error={!orderItem.count ? 'Тут пусто' : ''}
                      required={true}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                      }}
                    >
                      {orderItems.filter((item) => !item['_destroy']).length <=
                      1 ? (
                        <Tooltip
                          title='Повинен бути як мінімум один елемент замовлення щоб ви могли щось видалити'
                          placement='top'
                        >
                          <Box>
                            <IconButton size='small' disabled={true}>
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </Tooltip>
                      ) : (
                        <IconButton
                          size='small'
                          onClick={(): void => {
                            deleteSubItem(i);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                </SingleItemContainer>
              );
            })}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '10px 10px 0',
              }}
            >
              <Button variant='outlined' onClick={addMoreClick}>
                додати ще
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default OrderItemSelect;
