import { Button, styled } from '@mui/material';
import React from 'react';
import { Link } from '@mui/material';
import localize from '../../../localize';
import { ReactComponent as LogoTextSvg } from '../../../assets/logoText.svg';
import { ReactComponent as LogoIconSvg } from '../../../assets/logoIcon.svg';
import { Navigation } from './Navigation';
import { Badge } from './Badge';
import { useLocation, useNavigate } from 'react-router-dom';
import seaBlob from '../../../assets/landing/seaBlob.svg';

export const ContainerInner = styled('div')(() => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 20px',
  width: '1280px',
  maxWidth: '100%',
  minWidth: '700px',
}));

export const HeaderWrapper = styled('header')(() => ({
  display: 'flex',
  position: 'relative',
  overflow: 'hidden',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'column',
  paddingTop: '25px',
  paddingBottom: '45px',
}));

export const Logo = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  paddingRight: '20px',
  cursor: 'pointer',

  '&>svg+svg': {
    marginLeft: '15px',
    width: '72px',
    height: 'auto',
  },
}));

const LogoMainWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

export const Menu = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',

  'a + a': {
    marginLeft: '20px',
  },
}));

export const SeaBlob = styled('img')(() => ({
  width: '413px',
  height: '385px',
  position: 'absolute',
  right: 0,
  top: 0,
  transform: 'translate(15%, -40%)',
}));

export const navLinks = [
  {
    name: localize.landing.navLinks.about,
    path: '/#about',
  },
  {
    name: localize.landing.navLinks.advantages,
    path: '/#features',
  },
  {
    name: localize.landing.navLinks.price,
    path: '/price',
  },
];
export const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <HeaderWrapper>
      <ContainerInner>
        {location.pathname !== '/' && <SeaBlob src={seaBlob} alt='' />}
        <LogoMainWrapper>
          <Logo onClick={() => navigate('/')}>
            <LogoIconSvg />
            <LogoTextSvg className='logo-text' />
          </Logo>
          <Badge>Beta</Badge>
        </LogoMainWrapper>
        <Navigation links={navLinks} />
        <Menu>
          <Button component={Link} href='/cp' variant='roundedSecondary'>
            {localize.landing.loginButton}
          </Button>
          <Button
            component={Link}
            href='/cp'
            variant='rounded'
            sx={{
              color: '#0c0c0c',
              backgroundColor: '#fff',
              boxShadow: 'none',
            }}
          >
            {localize.landing.signUpButton}
          </Button>
        </Menu>
      </ContainerInner>
    </HeaderWrapper>
  );
};
