import { AnyObject } from './anyObjectTypes';
import { get, put } from './request';

export type Template = {
  [key: string]: any;
};

export type CustomField = {
  [key: string]: any;
};

export type TemplateType =
  | 'Order'
  | 'Product'
  | 'Stock'
  | 'Manufacture Request'
  | 'Booking'
  | 'Order item'
  | 'Product modification';

export class TemplateApi {
  static getIndex(projectId: string): Promise<Template[]> {
    return get(`projects/${projectId}/templates`);
  }

  static update(
    projectId: string,
    requestBody: AnyObject,
    itemId: string
  ): Promise<Template> {
    return put(`projects/${projectId}/templates/${itemId}`, requestBody);
  }

  static getTemplateFor(
    type: TemplateType,
    projectId: string
  ): Promise<Template> {
    return this.getIndex(projectId).then((res: Template[]) => {
      return res.filter((template: any) => template?.template_type === type)[0];
    });
  }
}
