import { get, destroy, post, put } from './request';
import { AnyObject } from './anyObjectTypes';

export type Promotion = {
  id: string;
  title: string;
};

export class PromotionApiClient {
  static getIndex(
    projectId: string,
    params?: { [key: string]: string }
  ): Promise<Promotion[]> {
    return get(
      `projects/${projectId}/promotions?${
        params
          ? Object.keys(params)
              .map((k) => `${k}=${params[k]}`)
              .join('&')
          : ''
      }`
    );
  }

  static getShow(projectId: string, itemId: string): Promise<Promotion> {
    return get(`projects/${projectId}/promotions/${itemId}`);
  }

  static remove(projectId: string, itemId: string): Promise<string> {
    return destroy(`projects/${projectId}/promotions/${itemId}`);
  }

  static create(projectId: string, requestBody: AnyObject): Promise<Promotion> {
    return post(`projects/${projectId}/promotions`, requestBody);
  }

  static update(
    projectId: string,
    requestBody: AnyObject,
    itemId: string
  ): Promise<Promotion> {
    return put(`projects/${projectId}/promotions/${itemId}`, requestBody);
  }
}
