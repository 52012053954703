import { FC, useContext, useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import { Typography, IconButton } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

import { AnyObject } from '../../api/anyObjectTypes';
import { ApiKeysApiClient } from '../../api/ApiKeysApiClient';
import { useAsyncError } from '../../hooks/useAsyncError';
import { formatDateTime } from '../../utils/time';
import { CommonContext } from '../../contexts/CommonContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import { BasicTable } from '../../components/BaseTable/BaseTable';
import Loading from '../../components/Loading/Loading';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import TextWithCopyButton from '../../components/TextWithCopyButton/TextWithCopyButton';
import localize from '../../localize';

const InnerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '30px',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
  width: '100%',
}));

const columns = [
  {
    field: 'id',
    align: 'left',
    headerName: `${localize.api_integration.api_keys.table_fields.id}`,
    width: '130px',
  },
  {
    field: 'access_key',
    align: 'left',
    headerName: `${localize.api_integration.api_keys.table_fields.access_key}`,
    valueGetter: (row: any) => <TextWithCopyButton text={row.access_key} />,
  },
  {
    field: 'created_at',
    align: 'right',
    headerName: `${localize.api_integration.api_keys.table_fields.created_at}`,
    valueGetter: (row: any) => row.created_at && formatDateTime(row.created_at),
  },
  {
    field: 'expires_at',
    align: 'right',
    headerName: `${localize.api_integration.api_keys.table_fields.expires_at}`,
    valueGetter: (row: any) => row.expires_at && formatDateTime(row.created_at),
  },
];

export const ApiKeysManagePage: FC = () => {
  const { currentProject, currentUser } = useContext(CommonContext);
  const throwError = useAsyncError();
  const [rows, setRows] = useState<AnyObject[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { setNotification } = useContext(NotificationContext);

  const create = async (): Promise<void> => {
    setLoading(true);
    await ApiKeysApiClient.create(currentProject.id)
      .catch((e) => {
        throwError(e);
      })
      .finally(() => {
        setNotification({
          severity: 'success',
          message: localize.general.successCreateMessage,
        });
        preparePageData().finally(() => setLoading(false));
      });
  };

  const deleteItem = (item: AnyObject) => {
    ApiKeysApiClient.remove(currentProject.id, item.id)
      .then(() => {
        setRows(rows.filter((row) => row.id !== item.id));
        setNotification({
          severity: 'success',
          message: localize.general.successDeleteMessage,
        });
      })
      .catch((e) => {
        setNotification({
          severity: 'warning',
          message: localize.general.unexpectedError,
        });
      });
  };

  const preparePageData = async (): Promise<void> => {
    const apiRows = await ApiKeysApiClient.getIndex(currentProject.id).catch(
      (e) => {
        throwError(e);
      }
    );

    setRows(apiRows || []);
  };

  useEffect(() => {
    setLoading(true);
    preparePageData().finally(() => setLoading(false));
  }, [currentProject]);

  if (loading) return <Loading />;

  return (
    <InnerContainer>
      <Typography variant='h6' color='#464646'>
        {localize.api_integration.api_keys.title}
      </Typography>
      {currentUser.user_permissions.api_key?.create?.length && (
        <IconButton
          onClick={() => {
            create();
          }}
          sx={{
            padding: '12px 24px',
            background: '#7B75CB',
            borderRadius: '100px',
            '&:hover': { background: '#7B75CB' },
          }}
        >
          <AddIcon sx={{ fill: '#fff' }} />
        </IconButton>
      )}

      {!!rows.length ? (
        <BasicTable rows={rows} onDeleteClick={deleteItem} columns={columns} />
      ) : (
        <EmptyContent />
      )}
    </InnerContainer>
  );
};

export default ApiKeysManagePage;
