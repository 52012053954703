import { FC } from 'react';
import { Order } from '../../api/OrderApiClient';
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  Link,
  Divider,
  ListItemText,
} from '@mui/material';
import IconWithInfo from '../../components/IconWithInfo';

type TotalSumCardProps = {
  order: Order;
};

export const TotalSumCard: FC<TotalSumCardProps> = ({ order }) => {
  return (
    <Card sx={{ border: '1px solid #DFDDF9', borderRadius: '8px' }}>
      <CardHeader
        title='Загалом'
        titleTypographyProps={{ variant: 'h4' }}
        subheader='Всі розрахунки'
        subheaderTypographyProps={{ variant: 'body2' }}
      />
      <CardContent>
        <List>
          <ListItem disablePadding>
            <ListItemText
              primary={
                <>
                  Базова вартість{' '}
                  <IconWithInfo text='Сума по всіх елементах замовлення.' />
                </>
              }
            />
            {order.fixed_recommended_sum}
          </ListItem>
          {order.promotion_id ? (
            <ListItem disablePadding>
              <ListItemText primary='Знижка по промо-акції' />
              {order.promotion_discount_value}
            </ListItem>
          ) : null}
          {order.fixed_customer_discount ? (
            <ListItem disablePadding>
              <ListItemText primary='Знижка покупця' />
              {order.customer_discount_value}
            </ListItem>
          ) : null}
          <Divider />
          {order.fixed_client_discount ? (
            <>
              <ListItem disablePadding>
                <ListItemText
                  primary={
                    <>
                      До сплати покупцем{' '}
                      <IconWithInfo text='Базова вартість мінус найбільша знижка (знижки не накладаються).' />
                    </>
                  }
                />
                {order.fixed_recommended_sum_with_discount}
              </ListItem>
              <br />
              <Divider />
              <ListItem disablePadding>
                <ListItemText
                  primary={`Знижка дропшипера (маржа) ${order.fixed_client_discount}%`}
                />
              </ListItem>
            </>
          ) : null}
          <ListItem disablePadding>
            <ListItemText
              primary={
                <>
                  Сума замовлення{' '}
                  <IconWithInfo
                    text={
                      order.user_is_client
                        ? 'Скільки дропшипер повинен віддати з замовлення. Базова вартість мінус знижка дропшипера.'
                        : order.promotion_id && order.fixed_customer_discount
                          ? 'Базова вартість мінус найбільша знижка (знижки не накладаються).'
                          : 'Скільки замовник повинен сплатити.'
                    }
                  />
                </>
              }
            />
            {order.sum}
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};

export default TotalSumCard;
