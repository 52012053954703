import { get, destroy, post, put } from './request';
import { AnyObject } from './anyObjectTypes';

export type Order = {
  editable_by_current_user?: boolean;
  files: any;
  delivery_operator: string;
  delivery_recipient_name: string;
  delivery_recipient_phone: string;
  delivery_address: string;
  delivery_ttn: string;
  sent_to_manager_at: string;
  updated_at: string;
  fixed_recommended_sum: number;
  customer_id?: string;
  user_role?: string;
  user_id?: string;
  user_auth0_id?: string;
  fixed_recommended_sum_with_discount: number;
  promotion_discount_value: number;
  customer_discount_value: number;
  promotion_id: any;
  promotion_title: string;
  sum: any;
  user_is_client: any;
  user_name: string;
  user_discount: number;
  user_maneger_name: string;
  customer_name: string;
  customer_personal_discount: number;
  customer_email: string;
  customer_phone_number: string;
  id: string;
  count: number;
  status: string;
  comment: string;
  order_items: any[];
  product_title: string;
  product_modification_title: string;
  fixed_client_discount: number;
  fixed_customer_discount: number;
  last_status: string;
  source?: string;
  additional_fields?: object;
};

export type OrderItem = {
  product_modification_id: string;
  count: string;
};

export type DeliveryKeys =
  | 'delivery_operator'
  | 'delivery_recipient_name'
  | 'delivery_recipient_phone'
  | 'delivery_address'
  | 'delivery_ttn';

export type CustomerKeys =
  | 'customer_name'
  | 'customer_email'
  | 'fixed_customer_discount'
  | 'customer_phone_number';

export type Delivery = {
  delivery_operator?: string;
  delivery_recipient_name?: string;
  delivery_recipient_phone?: string;
  delivery_address?: string;
  delivery_ttn?: string;
};

export class OrderApiClient {
  static getIndex(projectId: string): Promise<Order[]> {
    return get(`projects/${projectId}/orders`);
  }

  static getShow(projectId: string, itemId: string): Promise<Order> {
    return get(`projects/${projectId}/orders/${itemId}`);
  }

  static remove(projectId: string, itemId: string): Promise<string> {
    return destroy(`projects/${projectId}/orders/${itemId}`);
  }

  static create(projectId: string, requestBody: AnyObject): Promise<Order> {
    return post(`projects/${projectId}/orders`, requestBody);
  }

  static update(
    projectId: string,
    requestBody: AnyObject,
    itemId: string
  ): Promise<Order> {
    return put(`projects/${projectId}/orders/${itemId}`, requestBody);
  }
}
