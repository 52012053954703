import { FC, useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { BaseApiClient } from '../../api/BaseApiClient';
import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import FormField from '../FormField/FormField';
import DeleteIcon from '@mui/icons-material/Delete';
import Loading from '../Loading/Loading';
import { AnyObject } from '../../api/anyObjectTypes';
import AddIcon from '@mui/icons-material/Add';
import localize from '../../localize';
import { CommonContext } from '../../contexts/CommonContext';

const Container = styled('form')(({ theme }) => ({
  width: '100%',
  maxWidth: '1024px',
}));

const MainItemContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: 'calc(100% - 50px)',
  marginBottom: '20px',
  background: '#FFFFFF',
  borderRadius: '10px',
  padding: '30px',

  '&>div': {
    width: '33%',
    minWidth: '200px',
  },
}));

const SubItemContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',

  '& + &': {
    paddingTop: '20px',
  },
}));

const SubItemTreeIndicator = styled('div')(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.primary.main}`,
  width: '20px',
  maxWidth: '20px',
  minWidth: '20px',
  marginRight: '20px',
  marginTop: '-20px',
  position: 'relative',

  '&:before': {
    content: "''",
    display: 'block',
    width: '7px',
    position: 'absolute',
    right: '-4px',
    top: 'calc(50% + 8px)',
    height: '7px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },

  '&:after': {
    content: "''",
    display: 'block',
    width: '20px',
    maxWidth: '20px',
    minWidth: '20px',
    position: 'absolute',
    right: '-22px',
    top: 'calc(50% + 11px)',
    height: '1px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const SubItemInnerContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  flex: '1 1 auto',
  background: '#fff',
  borderRadius: '10px',
  padding: '30px',

  '& + &': {
    marginTop: '20px',
  },

  '&>div': {
    width: '33%',
    minWidth: '200px',
  },
}));

const SubItemRemove = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '10px',
  width: '50px',
  minWidth: '50px',
  maxWidth: '50px',
}));

const AddSubItemContainer = styled('div')(({ theme }) => ({
  paddingTop: '50px',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',

  '&:before': {
    content: "''",
    borderRight: `1px solid ${theme.palette.primary.main}`,
    display: 'block',
    position: 'absolute',
    width: '20px',
    top: '0',
    height: '52px',
  },
}));

const AddSubItemNote = styled('div')(({ theme }) => ({
  marginLeft: '10px',
  color: '#8B8B8B',
  fontStyle: 'italic',
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: 'white',
  marginLeft: '2px',

  '&:hover': {
    background: theme.palette.primary.dark,
  },
}));

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: '20px',
  padding: '10px 50px 10px 10px',
  justifyContent: 'flex-end',
}));

type FormProps = {
  mainFields: AnyObject[];
  defaultMainValues?: AnyObject;
  defaultSubItemValues?: AnyObject[];
  subItemFields?: AnyObject[];
  submitting?: boolean;
  addModificationText?: string;
  onSubmit(mainItem: AnyObject, subItems: AnyObject[]): void;
  onChangeCallback?(mainItem: AnyObject, subItems: AnyObject[]): void;
};

export const Form: FC<FormProps> = ({
  submitting,
  mainFields,
  subItemFields = [],
  defaultMainValues = {},
  defaultSubItemValues = [{}],
  addModificationText,
  onChangeCallback,
  onSubmit,
}) => {
  const [mainItem, setMainItem] = useState(defaultMainValues);
  const [subItems, setSubItems] = useState(defaultSubItemValues);
  const [mainRelations, setMainRelations] = useState<AnyObject>({});
  const [subRelations, setSubRelations] = useState<AnyObject>({});
  const [loadingMain, setLoadingMain] = useState(false);
  const [loadingSub, setLoadingSub] = useState(false);
  const { currentProject } = useContext(CommonContext);

  useEffect(() => {
    const staticMainFields = mainFields.filter(
      (field) => field.field_type === 'static'
    );
    const staticSubItemFields = subItemFields.filter(
      (field) => field.field_type === 'static'
    );

    if (staticMainFields?.length) {
      const resObj1: any = {};
      staticMainFields.forEach(
        (field) => (resObj1[field.internal_name] = field.default_value)
      );
      setMainItem({
        ...mainItem,
        ...resObj1,
      });
    }

    if (staticSubItemFields?.length) {
      const resObj2: any = {};
      staticSubItemFields.forEach(
        (field) => (resObj2[field.internal_name] = field.default_value)
      );
      setSubItems(
        JSON.parse(JSON.stringify(subItems)).map((item: any) => ({
          ...item,
          ...resObj2,
        }))
      );
    }
  }, [mainFields, subItems?.length, subItemFields]);

  const handleMainInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setMainItem({
      ...mainItem,
      [name]: value,
    });
  };

  const handleSubItemInputChange = (
    e: { target: { name: any; value: any } },
    itemIndex: number
  ) => {
    const { name, value } = e.target;
    const newSubItems = subItems.slice();
    newSubItems[itemIndex] = { ...newSubItems[itemIndex], [name]: value };
    setSubItems(newSubItems);
  };

  const deleteSubItem = (itemIndex: number) => {
    const newSubItems = subItems.slice();
    newSubItems[itemIndex]['_destroy'] = true;
    setSubItems(newSubItems);
  };

  const fetchRelations = async (
    relationsToFetch: string[],
    type: 'sub' | 'main'
  ) => {
    await Promise.all(
      relationsToFetch.map(async (relationToFetch) => {
        const entityToFetch =
          (relationToFetch === 'product_modification_id'
            ? 'product'
            : relationToFetch === 'manager_id'
              ? 'user'
              : relationToFetch.slice(0, -3)) + 's';
        const relationItems = await BaseApiClient.getIndex(
          currentProject?.id,
          entityToFetch
        );
        return {
          [relationToFetch]: relationItems
            .map((el) =>
              relationToFetch === 'product_modification_id'
                ? el.product_modifications.map((pm: AnyObject) => ({
                    groupLabel: [
                      el.additional_fields?.secondaryid,
                      el.additional_fields?.n_collection,
                      el.title,
                    ]
                      .filter((f) => !!f)
                      .join(' - '),
                    label: `${pm.count} || ${pm.parsed_title}`,
                    price: pm.price,
                    id: pm.id,
                  }))
                : relationToFetch === 'user_role_id'
                  ? {
                      label: localize.users.roles[el.title],
                      id: el.id,
                    }
                  : {
                      label: el.name || el.title,
                      id: el.id,
                    }
            )
            .flat(),
        };
      })
    ).then((res) => {
      const currRelations: AnyObject = {};
      res.map((r) => (currRelations[Object.keys(r)[0]] = Object.values(r)[0]));
      type === 'sub'
        ? setSubRelations(currRelations)
        : setMainRelations(currRelations);
    });
  };

  useEffect(() => {
    const relationsToFetch = [
      ...mainFields
        .filter((mf) => mf.internal_name.endsWith('_id'))
        .map((mf) => mf.internal_name),
    ];
    if (relationsToFetch.length) {
      setLoadingMain(true);
      fetchRelations(relationsToFetch, 'main').finally(() =>
        setLoadingMain(false)
      );
    }
  }, [mainFields]);

  useEffect(() => {
    const relationsToFetch = [
      ...subItemFields
        .filter((mf) => mf.internal_name.endsWith('_id'))
        .map((mf) => mf.internal_name),
    ];
    if (relationsToFetch.length) {
      setLoadingSub(true);
      fetchRelations(relationsToFetch, 'sub').finally(() =>
        setLoadingSub(false)
      );
    }
  }, [subItemFields]);

  useEffect(() => {
    onChangeCallback && onChangeCallback(mainItem, subItems);
  }, [mainItem, subItems]);

  if (loadingMain) return <Loading />;

  return (
    <Container
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(mainItem, subItems);
      }}
    >
      <MainItemContainer elevation={2}>
        {mainFields.map((mf) => (
          <FormField
            key={mf.internal_name}
            value={
              mainItem[mf.internal_name] === undefined
                ? ''
                : mainItem[mf.internal_name]
            }
            label={mf.label}
            name={mf.internal_name}
            onChange={handleMainInputChange}
            fieldType={mf.field_type}
            localizedField={mf.localized_field}
            options={mf.options}
            required={mf.required}
            disabled={!mf.editable}
            autocomplete={
              mf.internal_name.endsWith('_id')
                ? {
                    options: mainRelations[mf.internal_name] || [],
                    onChange: (_, newValue: any) => {
                      setMainItem({
                        ...mainItem,
                        [mf.internal_name]: newValue,
                      });
                    },
                  }
                : undefined
            }
          />
        ))}
      </MainItemContainer>
      {subItemFields.length ? (
        <>
          {!loadingSub &&
            subItems.map((item, i) => {
              if (item['_destroy']) return null;

              return (
                <SubItemContainer key={`${item.id}-${i}`}>
                  <SubItemTreeIndicator />
                  <SubItemInnerContainer elevation={2}>
                    {subItemFields.map((mf) => (
                      <FormField
                        key={`${item.id}-${i}-${mf.internal_name}`}
                        value={item[mf.internal_name] || ''}
                        label={mf.label}
                        name={mf.internal_name}
                        required={mf.required}
                        fieldType={mf.field_type}
                        options={mf.options}
                        localizedField={mf.localized_field}
                        disabled={!mf.editable}
                        onChange={(e) => handleSubItemInputChange(e, i)}
                        autocomplete={
                          mf.internal_name.endsWith('_id')
                            ? {
                                options: subRelations[mf.internal_name] || [],
                                onChange: (_, newValue: any) => {
                                  const newSubItems = subItems.slice();
                                  const newFields = newValue?.price
                                    ? {
                                        [mf.internal_name]: newValue,
                                        fixed_price: +newValue.price,
                                      }
                                    : {
                                        [mf.internal_name]: newValue,
                                      };
                                  newSubItems[i] = {
                                    ...newSubItems[i],
                                    ...newFields,
                                  };

                                  setSubItems(newSubItems);
                                },
                              }
                            : undefined
                        }
                      />
                    ))}
                  </SubItemInnerContainer>
                  <SubItemRemove>
                    {subItems.filter((item) => !item['_destroy']).length > 1 ? (
                      <IconButton
                        size='small'
                        onClick={(): void => {
                          deleteSubItem(i);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    ) : null}
                  </SubItemRemove>
                </SubItemContainer>
              );
            })}
          <AddSubItemContainer>
            <StyledIconButton
              aria-label='expand row'
              size='small'
              onClick={() =>
                !loadingSub ? setSubItems([...subItems, {}]) : undefined
              }
            >
              {loadingSub ? (
                <CircularProgress color='inherit' size={20} />
              ) : (
                <AddIcon />
              )}
            </StyledIconButton>
            <AddSubItemNote>
              <Typography variant='body1'>
                {loadingSub
                  ? 'завантажуємо дані'
                  : addModificationText || 'додати'}
              </Typography>
            </AddSubItemNote>
          </AddSubItemContainer>
        </>
      ) : null}
      <Actions>
        <Button
          size='large'
          type='submit'
          variant='contained'
          disabled={submitting}
        >
          {submitting ? (
            <CircularProgress size={26} thickness={6} color='primary' />
          ) : (
            localize.general.submit
          )}
        </Button>
      </Actions>
    </Container>
  );
};

export default Form;
