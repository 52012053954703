import { FC, useContext, useEffect, useMemo, useState } from 'react';
import Page from '../../components/Page/Page';
import Form from '../../components/Form/Form';
import { useNavigate, useParams } from 'react-router-dom';
import { NotificationContext } from '../../contexts/NotificationContext';
import { preparedRequestBody } from '../../utils/form';
import { CommonContext } from '../../contexts/CommonContext';
import { UserApiClient } from '../../api/UserApiClient';
import Loading from '../../components/Loading/Loading';
import { AnyObject } from '../../api/anyObjectTypes';
import localize from '../../localize';

export const UserEditPage: FC = () => {
  const { id } = useParams();
  const [values, setValues] = useState<AnyObject>({});
  const [mainFields, setMainFields] = useState<AnyObject[]>([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const { setNotification } = useContext(NotificationContext);
  const { currentProject, currentUser } = useContext(CommonContext);
  const navigate = useNavigate();
  const { userType } = useParams();
  const basicMainLabels: AnyObject = useMemo(
    () =>
      currentUser?.user_permissions.user?.update?.includes('all')
        ? {
            user_role_id: localize.users.columns.role,
            manager_id: localize.users.columns.manager,
            discount: localize.users.columns.discount,
          }
        : {
            user_role_id: localize.users.columns.role,
          },
    [currentUser]
  );

  const prepareData = async (): Promise<void> => {
    if (id) {
      const res = await UserApiClient.getShow(currentProject.id, id);

      setValues(res);
    }
  };

  const onSubmit = (mainItem: AnyObject) => {
    if (!id) return;
    setSubmitting(true);

    UserApiClient.update(
      currentProject.id,
      {
        user: preparedRequestBody({
          mainItem,
          mainFields,
          basicMainLabels,
        }),
      },
      id
    ).then(() => {
      setNotification({
        severity: 'success',
        message: localize.general.successUpdateMessage,
      });
      setSubmitting(false);
      navigate(`/cp/users/${userType}/${id}`);
    });
  };

  useEffect(() => {
    setLoading(true);
    prepareData().finally(() => setLoading(false));
  }, [currentProject]);

  useEffect(() => {
    setMainFields([
      ...Object.keys(basicMainLabels || {}).map((el) => ({
        editable: el !== 'name',
        hidden: true,
        internal_name: el,
        label: basicMainLabels[el],
        field_type: el === 'discount' ? 'number' : undefined,
        parsable: false,
        required: el !== 'manager_id',
      })),
    ]);
  }, [currentProject]);

  if (loading || !Object.keys(values).length)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page title={localize.users.edit.title} actions={[]}>
      <Form
        mainFields={mainFields}
        defaultMainValues={values}
        onSubmit={onSubmit}
        submitting={submitting}
      />
    </Page>
  );
};

export default UserEditPage;
