import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Order } from '../../api/OrderApiClient';
import { Card, CardHeader, Button, CardContent, Link } from '@mui/material';
import { BasicTable } from '../../components/BaseTable/BaseTable';
import localize from '../../localize';
import { CommonContext } from '../../contexts/CommonContext';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import { useNavigate } from 'react-router-dom';

type OrderItemsCardProps = {
  order: Order;
};

const productTitleColumnWithLink = {
  field: 'product_title',
  align: 'left',
  headerName: 'Продукт',
  renderCell: (row: any) => (
    <Link href={`/cp/products/${row.product_id}`}>
      {[
        row.product?.additional_fields?.secondaryid,
        row.product?.additional_fields?.n_collection,
        row.product?.title,
      ]
        .filter((f) => !!f)
        .join(' - ')}
    </Link>
  ),
};

const productModificationColumn = {
  field: 'product_modification_title',
  align: 'left',
  headerName: 'Модифікація',
};
const countColumn = { field: 'count', align: 'right', headerName: 'Кількість' };
const priceColumn = {
  field: 'fixed_price',
  align: 'right',
  headerName: 'Ціна (одного)',
};

const queueColumn = {
  field: 'queue',
  align: 'left',
  width: '200px',
  headerName: '',
  renderCell: (row: any) =>
    !row['hide_collapsed'] && (
      <i style={{ color: '#8B8B8B' }}>{localize.orders.show.queue}</i>
    ),
};

export const OrderItemsCard: FC<OrderItemsCardProps> = ({ order }) => {
  const { currentUser } = useContext(CommonContext);
  const navigate = useNavigate();

  const queueColumns = [
    {
      field: 'index',
      align: 'left',
      headerName: localize.orders.columns.index,
    },
    {
      field: 'user_name',
      align: 'left',
      headerName: localize.orders.columns.user_name,
    },
    {
      field: 'manager_name',
      align: 'left',
      headerName: localize.orders.columns.manager_name,
    },
  ];

  const inQueue = useMemo(
    () =>
      order.order_items.length &&
      order.order_items[0].product_modification_id &&
      !!order.order_items.filter((oi: any) => !oi?.hide_collapsed).length &&
      currentUser.user_permissions.role_name !== 'client',
    [order.order_items, currentUser]
  );

  const baseSubFields = useMemo(
    () =>
      inQueue
        ? [
            queueColumn,
            productTitleColumnWithLink,
            productModificationColumn,
            countColumn,
            priceColumn,
          ]
        : [
            productTitleColumnWithLink,
            productModificationColumn,
            countColumn,
            priceColumn,
          ],
    []
  );
  const [columnsForSubItems, setColumnsForSubItems] = useState<any[]>(
    baseSubFields || []
  );

  useEffect(() => {
    if (inQueue && columnsForSubItems[0].field !== 'queue')
      setColumnsForSubItems([queueColumn, ...columnsForSubItems]);
  }, [inQueue, columnsForSubItems]);

  return (
    <Card sx={{ border: '1px solid #DFDDF9', borderRadius: '8px' }}>
      <CardHeader
        title='Елементи замовлення'
        titleTypographyProps={{ variant: 'h4' }}
        subheader={
          inQueue
            ? localize.orders.show.queueAttention
            : 'Дані про елементи замовлення'
        }
        subheaderTypographyProps={{ variant: 'body2' }}
        action={
          order.editable_by_current_user && (
            <Button
              variant='outlined'
              onClick={() =>
                navigate(`/cp/orders/${order.id}/edit/order-items`)
              }
            >
              Редагувати
            </Button>
          )
        }
      />
      <CardContent>
        {order.order_items.length &&
        order.order_items[0].product_modification_id ? (
          <BasicTable
            key='table-product-modifications'
            rows={order.order_items}
            columns={columnsForSubItems}
            {...(currentUser.user_permissions.role_name !== 'client'
              ? { collapsedContentColumns: { queue: queueColumns } }
              : {})}
          />
        ) : (
          <EmptyContent title='' subtitle='Нічого не вибрано' />
        )}
      </CardContent>
    </Card>
  );
};

export default OrderItemsCard;
