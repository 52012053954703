import { FC, useContext, useEffect, useMemo, useState } from 'react';
import Page from '../../components/Page/Page';
import Form from '../../components/Form/Form';
import { useNavigate, useParams } from 'react-router-dom';
import { NotificationContext } from '../../contexts/NotificationContext';
import { preparedRequestBody } from '../../utils/form';
import { CommonContext } from '../../contexts/CommonContext';
import { UserApiClient } from '../../api/UserApiClient';
import { AnyObject } from '../../api/anyObjectTypes';
import localize from '../../localize';

export const UserCreatePage: FC = () => {
  const [mainFields, setMainFields] = useState<AnyObject[]>([]);
  const { setNotification } = useContext(NotificationContext);
  const [submitting, setSubmitting] = useState(false);
  const { currentProject, currentUser } = useContext(CommonContext);
  const navigate = useNavigate();
  const { userType } = useParams();
  const basicMainLabels: AnyObject = useMemo(
    () =>
      currentUser?.user_permissions.user?.update?.includes('all')
        ? {
            email: localize.users.columns.inviteeEmail,
            user_role_id: localize.users.columns.role,
            manager_id: localize.users.columns.manager,
            discount: localize.users.columns.discount,
          }
        : {
            email: localize.users.columns.inviteeEmail,
            user_role_id: localize.users.columns.role,
          },
    [currentUser]
  );

  const onSubmit = (mainItem: AnyObject) => {
    setSubmitting(true);
    UserApiClient.create(currentProject.id, {
      user: preparedRequestBody({
        mainItem,
        mainFields,
        basicMainLabels,
      }),
    })
      .then((res) => {
        setNotification({
          severity: 'success',
          message: localize.users.messages.invitationSent,
        });
        setSubmitting(false);
        navigate(`/cp/users/${userType}/${res.auth0_id}`);
      })
      .catch((err) => {
        if (err.message === '409') {
          setNotification({
            severity: 'error',
            message: localize.users.messages.alreadyExists,
          });
        } else {
          setNotification({
            severity: 'error',
            message: localize.general.unexpectedError,
          });
        }
        setSubmitting(false);
        navigate(`/cp/users/${userType}`);
      });
  };

  useEffect(() => {
    setMainFields([
      ...Object.keys(basicMainLabels || {}).map((el) => ({
        editable: true,
        hidden: true,
        internal_name: el,
        label: basicMainLabels[el],
        parsable: false,
        required: !['manager_id', 'discount'].includes(el),
      })),
    ]);
  }, [currentProject, userType]);

  return (
    <Page title={localize.users.create.title} actions={[]}>
      <Form
        mainFields={mainFields}
        submitting={submitting}
        onSubmit={onSubmit}
      />
    </Page>
  );
};

export default UserCreatePage;
