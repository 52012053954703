import React, { useContext } from 'react';
import { useOnboardingMultiStep } from '../../contexts/OnboardingContext';
import { DropshipperMainInfo } from './dropshipper/DropshipperMainInfo';
import { DropshipperPlacementInfo } from './dropshipper/DropshipperPlacementInfo';
import { DropshipperAdvInfo } from './dropshipper/DropshipperAdvInfo';
import { FinalScreenManufacturer } from './manufacturer/FinalScreenManufacturer';
import { FinalScreenDropshipper } from './dropshipper/FinalScreenDropshipper';
import { ManufacturerWelcomeInfo } from './manufacturer/ManufacturerWelcomeInfo';
import { ManufacturerAdvInfo } from './manufacturer/ManufacturerAdvInfo';
import { ManufacturerProjectInfo } from './manufacturer/ManufacturerProjectInfo';
import { ManufacturerLegalInfo } from './manufacturer/ManufacturerLegalInfo';
import { OnboardingSelectPath } from './OnboardingSelectPath';

export const StepManager = () => {
  const { path, step } = useOnboardingMultiStep();

  const renderStep = () => {
    if (step === 0) {
      return <OnboardingSelectPath />;
    }

    if (path === 'manufacturer') {
      switch (step) {
        case 1:
          return <ManufacturerWelcomeInfo />;
        case 2:
          return <ManufacturerProjectInfo />;
        case 3:
          return <ManufacturerLegalInfo />;
        case 4:
          return <ManufacturerAdvInfo />;
        default:
          return <FinalScreenManufacturer />;
      }
    } else if (path === 'dropshipper') {
      switch (step) {
        case 1:
          return <DropshipperMainInfo />;
        case 2:
          return <DropshipperPlacementInfo />;
        case 3:
          return <DropshipperAdvInfo />;
        default:
          return <FinalScreenDropshipper />;
      }
    }
  };

  return <>{renderStep()}</>;
};
