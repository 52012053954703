import { Button, styled, Typography } from '@mui/material';
import React from 'react';
import localize from '../../../localize';
import sally3 from '../../../assets/landing/salyThird.png';
import doodle7 from '../../../assets/landing/doodle7.png';
import doodle5 from '../../../assets/landing/doodle5.png';

import { useState } from 'react';
import { ContactModal } from './ContactModal';
import ContactModalImg from '../../../assets/landing/contact-matte.svg';

const KnowMoreSectionContainer = styled('section')(() => ({
  backgroundColor: '#AFABEF',
  position: 'relative',
  borderTopRightRadius: '100px',
  borderTopLeftRadius: '100px',
  padding: '3% 0',
  color: '#fff',
}));

const KnowMoreSectionInner = styled('div')(() => ({
  display: 'flex',
  padding: '0 20px',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  minHeight: '407px',
  width: '1280px',
  maxWidth: '100%',
  minWidth: '700px',
  margin: '0 auto',

  '& h2': {
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '24px',
  },

  '& p': {
    fontSize: '18px',
    lineHeight: '24px',
    marginBottom: '14px',
    fontFamily: '"Montserrat", sans-serif',
  },
}));

const KnowMoreSectionTextWithCTA = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '600px',
  gap: '40px',
}));

const KnowMoreSectionImg = styled('img')(() => ({
  display: 'block',
  width: '465px',
  maxWidth: '100%',
}));

const KnowMoreSectionDoodle7 = styled('img')(() => ({
  position: 'absolute',
  top: '10%',
  right: '15%',
}));

const KnowMoreSectionDoodle5 = styled('img')(() => ({
  position: 'absolute',
  bottom: '-4%',
  right: '40%',
}));

export const KnowMoreSection = () => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <KnowMoreSectionContainer>
      <KnowMoreSectionDoodle7 src={doodle7} />
      <KnowMoreSectionDoodle5 src={doodle5} />
      <KnowMoreSectionInner>
        <KnowMoreSectionTextWithCTA>
          <Typography variant='h2' component='h2'>
            {localize.landing.knowMoreSection.title}
          </Typography>
          <Typography variant='body1' component='p'>
            {localize.landing.knowMoreSection.description}
          </Typography>
          <Button
            variant='rounded'
            sx={{
              color: '#0c0c0c',
              backgroundColor: '#fff',
              boxShadow: 'none',
              alignSelf: 'flex-start',
            }}
            onClick={() => setOpenModal(true)}
          >
            {localize.landing.knowMoreSection.CTAButton}
          </Button>
          <ContactModal
            open={openModal}
            handleClose={() => setOpenModal(false)}
            handleSubmit={() => setOpenModal(false)}
            title={localize.landing.modal.titleContactUs}
            imgPath={ContactModalImg}
          />
        </KnowMoreSectionTextWithCTA>

        <KnowMoreSectionImg src={sally3} />
      </KnowMoreSectionInner>
    </KnowMoreSectionContainer>
  );
};
