import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material';

type ShowStepNumbersProps = {
  currentStep: number;
  totalSteps: number;
};
const StepsWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '54px',
}));

const StepCircle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isCurrent' && prop !== 'isPast',
})<{ isCurrent: boolean; isPast: boolean }>(({ isCurrent, isPast }) => ({
  width: '47px',
  height: '47px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '18px',
  fontWeight: 'bold',
  color: isCurrent || isPast ? '#FFFFFF' : '#AFABEF',
  backgroundColor: isCurrent ? '#AFABEF' : isPast ? '#7B75CB' : '#FFFFFF',
  border: isCurrent || isPast ? '1px solid transparent' : '1px solid #AFABEF',
}));

const StepLine = styled(Box)({
  width: '154px',
  height: '1px',
  backgroundColor: '#E3E1FA',
});

const ShowStepNumbers: React.FC<ShowStepNumbersProps> = ({
  currentStep,
  totalSteps,
}) => {
  const steps = Array.from({ length: totalSteps }, (_, i) => i + 1);

  return (
    <StepsWrapper>
      {steps.map((step, index) => (
        <React.Fragment key={step}>
          <StepCircle
            isCurrent={step === currentStep}
            isPast={step < currentStep}
          >
            <Typography variant='body1'>{step}</Typography>
          </StepCircle>
          {index < totalSteps - 1 && <StepLine />}
        </React.Fragment>
      ))}
    </StepsWrapper>
  );
};

export default ShowStepNumbers;
