import {
  FC,
  createContext,
  useMemo,
  useState,
  ReactNode,
  PropsWithChildren,
} from 'react';

const emptyFun = (): void => {
  return;
};

type RightSideBarProps = {
  setOpen: (open: boolean) => void;
  setContent: (content: ReactNode) => void;
  content: ReactNode;
  open: boolean;
};

export const RightSideBarContext = createContext<RightSideBarProps>({
  setOpen: emptyFun,
  setContent: emptyFun,
  content: '',
  open: false,
});

export const RightSideBarProvider: FC<PropsWithChildren> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [content, setContent] = useState<ReactNode>('');

  const value = useMemo(
    () => ({
      setContent,
      setOpen,
      content,
      open,
    }),
    [open, setOpen, setContent, content]
  );

  return (
    <RightSideBarContext.Provider value={value}>
      {props.children}
    </RightSideBarContext.Provider>
  );
};

RightSideBarProvider.displayName = 'RightSideBarProvider';
