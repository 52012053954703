import { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CommonContext } from '../contexts/CommonContext';
import { get } from '../api/request';
import {
  TimelineDot,
  TimelineContent,
  TimelineConnector,
  TimelineSeparator,
  TimelineItem,
  Timeline,
  timelineItemClasses,
} from '@mui/lab';
import { formatDateTime } from '../utils/time';
import { AnyObject } from '../api/anyObjectTypes';
import localize from '../localize';
import Loading from '../components/Loading/Loading';
import { Typography } from '@mui/material';
import {
  ScheduleOutlined, // Pending
  PlaylistAddCheckOutlined, // Ready
  DoneAllOutlined, // Complete
  CancelOutlined, // Canceled
  BlockOutlined, // Blocked
  EditOutlined, // Draft
} from '@mui/icons-material';

export const availableStatuses = [
  'Pending',
  'Ready',
  'Complete',
  'Canceled',
  'Blocked',
];

export const statusIcons: { [key: string]: ReactNode } = {
  Draft: <EditOutlined style={{ color: '#404040' }} />,
  Pending: <ScheduleOutlined style={{ color: '#404040' }} />,
  Ready: <PlaylistAddCheckOutlined style={{ color: '#404040' }} />,
  Complete: <DoneAllOutlined style={{ color: '#404040' }} />,
  Canceled: <CancelOutlined style={{ color: '#fff' }} />,
  Blocked: <BlockOutlined style={{ color: '#fff' }} />,
};

export const statusColors: { [key: string]: string } = {
  Draft: '#87CEEB', // Sky Blue
  Pending: '#FFD966', // Soft Gold
  Ready: '#FFFF99', // Soft Yellow
  Complete: '#7FFF7F', // Soft Green
  Canceled: '#FF6347', // Tomato
  Blocked: '#404040', // Dark Gray
};

type StatusesTimelineProps = {};

export const StatusesTimeline: FC<StatusesTimelineProps> = () => {
  const { currentProject } = useContext(CommonContext);
  const { id } = useParams();
  const [statuses, setStatuses] = useState<AnyObject[]>([]);
  const [loading, setLoading] = useState(false);
  const TimelineComponent = Timeline as any;

  const preparePageData = async (id: string): Promise<void> => {
    const res = await get(
      `projects/${currentProject.id}/orders/${id}/order_statuses`
    );
    setStatuses(res);
  };

  useEffect(() => {
    if (!id) return;

    setLoading(true);
    preparePageData(id).finally(() => setLoading(false));
  }, [id]);

  if (loading) return <Loading />;

  return (
    <TimelineComponent
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
        margin: 0,
        padding: 0,
      }}
    >
      {statuses.map((status, i) => (
        <TimelineItem key={status.id}>
          <TimelineSeparator>
            <TimelineDot sx={{ bgcolor: statusColors[status.title] }}>
              {statusIcons[status.title]}
            </TimelineDot>
            {i !== statuses.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant='h4'>
              {localize.order_statuses.values[status.title]}
            </Typography>
            <Typography variant='body1' color='#8B8B8B'>
              {formatDateTime(status.created_at)}
            </Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </TimelineComponent>
  );
};

export default StatusesTimeline;
