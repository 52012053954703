import { FC, useEffect, useMemo } from 'react';
import Main from '../pages/Main';
import { Auth0Provider } from '@auth0/auth0-react';
import { CommonProvider } from '../contexts/CommonContext';
import ErrorBoundary from '../ErrorBoundary';
import { RightSideBarProvider } from '../contexts/RightSideBarContext';
import { FilterContextProvider } from '../contexts/FilterContext';

export const RootCP: FC = () => {
  const providerProps = useMemo(
    () => ({
      domain: !process.env.REACT_APP_POINT_TO_STAGING
        ? 'factorywise.eu.auth0.com'
        : 'dev-9frarrow.us.auth0.com',
      clientId: !process.env.REACT_APP_POINT_TO_STAGING
        ? 'xWQTVpTrHQrJjz8jkQaqSx2mEndtyPq0'
        : 'ZS7t7RaQdir3v86j6q3OQZGMheFkXQmd',
      redirectUri: window.location.origin,
      audience: 'https://factorywise.com.ua',
    }),
    []
  );

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if ((document.activeElement as HTMLInputElement)?.type === 'number') {
        (document.activeElement as HTMLInputElement).blur();
      }
    };

    document.addEventListener('wheel', handleWheel);

    return () => {
      document.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <ErrorBoundary>
      <Auth0Provider {...providerProps}>
        <CommonProvider>
          <RightSideBarProvider>
            <FilterContextProvider>
              <Main />
            </FilterContextProvider>
          </RightSideBarProvider>
        </CommonProvider>
      </Auth0Provider>
    </ErrorBoundary>
  );
};

export default RootCP;
