import { FC, useContext, useEffect, useMemo, useState } from 'react';
import Page, { PageAction } from '../../components/Page/Page';
import { Grid, Link } from '@mui/material';
import { fieldsForTypes, toTemplateType } from '../../utils/template';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import { preparedColumns } from '../../utils/table';
import { BasicTable } from '../../components/BaseTable/BaseTable';
import { CommonContext } from '../../contexts/CommonContext';
import { ProductApiClient } from '../../api/ProductApiClient';
import Loading from '../../components/Loading/Loading';
import { NotificationContext } from '../../contexts/NotificationContext';
import { AnyObject } from '../../api/anyObjectTypes';
import localize from '../../localize';

export const ProductShowPage: FC = () => {
  const navigate = useNavigate();
  const baseSubFields = {
    product_modifications: [
      {
        field: 'available_count',
        align: 'left',
        headerName: localize.products.columns.availableCount,
        width: '80px',
      },
      {
        field: 'goToStock',
        headerName: localize.products.columns.actualCount,
        renderCell: (row: any) => {
          if (currentUser.user_permissions.product?.update?.length)
            return (
              <>
                {row?.actual_count}{' '}
                <Link
                  href={`/stocks/${row?.stock_id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/cp/stocks/edit/${row?.stock_id}`);
                  }}
                >
                  {localize.products.show.editStock}
                </Link>
              </>
            );

          return row?.count;
        },
      },
    ],
  };
  const { id } = useParams();
  const { setNotification } = useContext(NotificationContext);
  const { currentProject, currentUser, templates } = useContext(CommonContext);
  const [item, setItem] = useState<AnyObject>({});
  const [columns, setColumns] = useState<AnyObject[]>([]);
  const [columnsForSubItems, setColumnsForSubItems] = useState<AnyObject>(
    baseSubFields || {}
  );
  const [itemIdToDelete, setItemIdToDelete] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);

  const pageActions = useMemo(() => {
    const editAction = {
      title: localize.products.show.editButton,
      link: `/cp/products/edit/${id}`,
    };
    const deleteAction = {
      title: localize.products.show.deleteButton,
      onClick: () => setItemIdToDelete(id),
    };
    const res: PageAction[] = [];

    if (currentUser.user_permissions.product?.update?.length)
      res.push(editAction);
    if (currentUser.user_permissions.product?.update?.length)
      res.push(deleteAction);

    return res;
  }, [item, id, setItemIdToDelete, itemIdToDelete]);

  const preparePageData = async (id: string): Promise<void> => {
    const res = await ProductApiClient.getShow(currentProject.id, id);
    setItem({ ...res });

    setColumns([
      ...columns,
      ...preparedColumns(fieldsForTypes(templates, ['Product']) || []),
    ]);

    const subItemColumns = { ...columnsForSubItems };
    subItemColumns['product_modifications'] = [
      ...(subItemColumns['product_modifications'] || []),
      ...preparedColumns(
        fieldsForTypes(templates, [toTemplateType('product_modifications')]) ||
          []
      ),
    ];

    setColumnsForSubItems(subItemColumns);
  };

  const deleteItem = () => {
    if (itemIdToDelete)
      ProductApiClient.remove(currentProject.id, itemIdToDelete).then(() => {
        setItemIdToDelete(undefined);
        setNotification({
          severity: 'success',
          message: localize.general.successDeleteMessage,
        });
        navigate(`/cp/products`);
      });
  };

  useEffect(() => {
    if (!id || !templates.length) return;

    setLoading(true);
    preparePageData(id).finally(() => setLoading(false));
  }, [id, templates, currentProject]);

  if (loading)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page title={localize.products.show.title} actions={pageActions}>
      <Grid
        container
        spacing={10}
        alignItems='flex-start'
        alignSelf='flex-start'
      >
        <Grid item xs={12}>
          <BasicTable rows={[item]} columns={columns} colorRow expandColumns />
        </Grid>
        <Grid item xs={12} key='product_modifications'>
          <BasicTable
            key='table-product-modifications'
            rows={item['product_modifications']}
            expandColumns
            columns={columnsForSubItems['product_modifications']}
          />
        </Grid>
      </Grid>
      <ConfirmationDialog
        header={localize.products.show.deleteConfirmationHeading}
        open={!!itemIdToDelete}
        description={localize.products.show.deleteConfirmationText}
        action={() => deleteItem()}
        discard={() => setItemIdToDelete(undefined)}
        mainActionButtonText={
          localize.products.show.deleteConfirmationButtonText
        }
      />
    </Page>
  );
};

export default ProductShowPage;
