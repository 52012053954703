import { post } from './request';
import { AnyObject } from './anyObjectTypes';

export class OrderUploadApiClient {
  static create(
    projectId: string,
    orderId: string,
    requestBody: AnyObject
  ): Promise<any> {
    return post(
      `projects/${projectId}/orders/${orderId}/order_uploads`,
      requestBody
    );
  }
}
