import { FC, useContext, useEffect, useState } from 'react';
import Page from '../../components/Page/Page';
import Form from '../../components/Form/Form';
import { useNavigate } from 'react-router-dom';
import { NotificationContext } from '../../contexts/NotificationContext';
import { preparedRequestBody } from '../../utils/form';
import { CommonContext } from '../../contexts/CommonContext';
import { ManufactureRequestApiClient } from '../../api/ManufactureRequestApiClient';
import { AnyObject } from '../../api/anyObjectTypes';
import localize from '../../localize';

export const ManufactureRequestCreatePage: FC = () => {
  const basicMainLabels: AnyObject = {
    product_modification_id:
      localize.manufacture_requests.columns.product_modification_id,
    count: localize.manufacture_requests.columns.count,
  };
  const [mainFields, setMainFields] = useState<AnyObject[]>([]);
  const { setNotification } = useContext(NotificationContext);
  const [submitting, setSubmitting] = useState(false);
  const { currentProject } = useContext(CommonContext);
  const navigate = useNavigate();

  const onSubmit = (mainItem: AnyObject) => {
    setSubmitting(true);
    ManufactureRequestApiClient.create(currentProject.id, {
      manufacture_request: preparedRequestBody({
        mainItem,
        mainFields,
        basicMainLabels,
      }),
    })
      .then((res) => {
        setNotification({
          severity: 'success',
          message: localize.general.successCreateMessage,
        });
        setSubmitting(false);
        navigate(`/cp/manufacture_requests/${res.id}`);
      })
      .catch((err) => {
        setNotification({
          severity: 'error',
          message: localize.general.unexpectedError,
        });
        setSubmitting(false);
        navigate(`/cp/manufacture_requests`);
      });
  };

  useEffect(() => {
    setMainFields([
      ...Object.keys(basicMainLabels || {}).map((el) => ({
        editable: true,
        hidden: true,
        internal_name: el,
        label: basicMainLabels[el],
        parsable: false,
        required: true,
      })),
    ]);
  }, [currentProject]);

  return (
    <Page title={localize.manufacture_requests.create.title} actions={[]}>
      <Form
        mainFields={mainFields}
        submitting={submitting}
        onSubmit={onSubmit}
      />
    </Page>
  );
};

export default ManufactureRequestCreatePage;
