import { get, destroy, post, put } from './request';
import { AnyObject } from './anyObjectTypes';

export type ProductModification = {
  id: string;
  price: string;
  title: string;
  additional_fields: AnyObject;
};

export type Product = {
  additional_fields: AnyObject;
  product_modifications: any;
  id: string;
  title: string;
};

export class ProductApiClient {
  static getIndex(projectId: string): Promise<Product[]> {
    return get(`projects/${projectId}/products`);
  }

  static getShow(projectId: string, itemId: string): Promise<Product> {
    return get(`projects/${projectId}/products/${itemId}`);
  }

  static remove(projectId: string, itemId: string): Promise<string> {
    return destroy(`projects/${projectId}/products/${itemId}`);
  }

  static create(projectId: string, requestBody: AnyObject): Promise<Product> {
    return post(`projects/${projectId}/products`, requestBody);
  }

  static update(
    projectId: string,
    requestBody: AnyObject,
    itemId: string
  ): Promise<Product> {
    return put(`projects/${projectId}/products/${itemId}`, requestBody);
  }
}
