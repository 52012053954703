import { get, destroy, post, put } from './request';
import { AnyObject } from './anyObjectTypes';

export type Customer = {
  id?: string;
  name?: string;
  phone_number?: string;
  email?: string;
  personal_discount?: number;
};

export class CustomerApiClient {
  static getIndex(
    projectId: string,
    params?: { [key: string]: string }
  ): Promise<Customer[]> {
    return get(
      `projects/${projectId}/customers?${
        params
          ? Object.keys(params)
              .map((k) => `${k}=${params[k]}`)
              .join('&')
          : ''
      }`
    );
  }

  static getShow(projectId: string, itemId: string): Promise<Customer> {
    return get(`projects/${projectId}/customers/${itemId}`);
  }

  static remove(projectId: string, itemId: string): Promise<string> {
    return destroy(`projects/${projectId}/customers/${itemId}`);
  }

  static create(projectId: string, requestBody: AnyObject): Promise<Customer> {
    return post(`projects/${projectId}/customers`, requestBody);
  }

  static update(
    projectId: string,
    requestBody: AnyObject,
    itemId: string
  ): Promise<Customer> {
    return put(`projects/${projectId}/customers/${itemId}`, requestBody);
  }
}
