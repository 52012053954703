import { FC } from 'react';
import { Card, CardContent, Box, Grid, Typography } from '@mui/material';
import FormField from '../../components/FormField/FormField';
import { Customer } from '../../api/CustomerApiClient';
import { Delivery } from '../../api/OrderApiClient';

type UploadFilesFieldsProps = {
  files?: File[];
  setFiles(files: File[]): void;
};

export const UploadFilesFields: FC<UploadFilesFieldsProps> = ({
  files,
  setFiles,
}) => {
  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                minWidth: '100%',
                flexDirection: 'column',
              }}
            >
              <Box sx={{ display: 'flex', minWidth: '100%', p: 1 }}>
                <Typography variant='h4'>Файли (необовʼязково)</Typography>
              </Box>
              <Box sx={{ display: 'flex', minWidth: '100%' }}>
                <FormField
                  value={files || ''}
                  label='файли'
                  fieldType='file'
                  name='files'
                  onChange={(e) => setFiles(e.target.value)}
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default UploadFilesFields;
