/* eslint-disable @typescript-eslint/no-explicit-any */
import localize from '../localize';

export const timeSince = (date: Date): string => {
  const seconds = (Date.now() - date.getTime()) / 1000;
  let interval = seconds / 31536000;
  let suffix = 'second';

  if (seconds > 31536000) {
    interval = seconds / 31536000;
    suffix = 'year';
  } else if (seconds > 2592000) {
    interval = seconds / 2592000;
    suffix = 'month';
  } else if (seconds > 86400) {
    interval = seconds / 86400;
    suffix = 'day';
  } else if (seconds > 3600) {
    interval = seconds / 3600;
    suffix = 'hour';
  } else if (seconds > 60) {
    interval = seconds / 60;
    suffix = 'minute';
  } else {
    interval = seconds;
    suffix = 'second';
  }
  const res = Math.floor(interval);

  return (
    [
      res,
      res === 1
        ? localize.general.time.long[suffix + (res === 1 ? '' : 's')]
        : localize.general.time.long[
            suffix + (`${res}`.endsWith('1') ? '' : 's')
          ] || localize.general.time.long[suffix + 's'],
    ].join(' ') +
    ' ' +
    localize.general.time.ago
  );
};

export const timeLeft = (date: Date): string => {
  const seconds = (date.getTime() - Date.now()) / 1000;

  if (seconds < 0) return '0';

  let interval = seconds / 31536000;
  let suffix = 'second';

  if (seconds > 31536000) {
    interval = seconds / 31536000;
    suffix = 'year';
  } else if (seconds > 2592000) {
    interval = seconds / 2592000;
    suffix = 'month';
  } else if (seconds > 86400) {
    interval = seconds / 86400;
    suffix = 'day';
  } else if (seconds > 3600) {
    interval = seconds / 3600;
    suffix = 'hour';
  } else if (seconds > 60) {
    interval = seconds / 60;
    suffix = 'minute';
  } else {
    interval = seconds;
    suffix = 'second';
  }
  const res = Math.floor(interval);

  return [res, localize.general.time.short[suffix]].join(' ');
};

export const formatDateTime = (dateString: string): string => {
  const date: Date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  const formattedDate: string = date.toLocaleDateString('uk-UA', options);
  return formattedDate;
};

export const formatDate = (dateString: string): string => {
  const date: Date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };
  const formattedDate: string = date.toLocaleDateString('uk-UA', options);
  return formattedDate;
};
