import { FC, useContext, useEffect, useState } from 'react';
import Page from '../components/Page/Page';
import Form from '../components/Form/Form';
import { preparedRequestBody } from '../utils/form';
import { CommonContext } from '../contexts/CommonContext';
import { Grid, Typography } from '@mui/material';
import { UserApiClient } from '../api/UserApiClient';
import { AnyObject } from '../api/anyObjectTypes';
import localize from '../localize';

type EditProfilePageProps = {};

export const EditProfilePage: FC<EditProfilePageProps> = () => {
  const basicMainLabels = {
    name: localize.profile.edit.fields.name,
    email: localize.profile.edit.fields.email,
  };
  const [values, setValues] = useState<AnyObject>({});
  const [mainFields, setMainFields] = useState<AnyObject[]>([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useContext(CommonContext);
  const [submitting, setSubmitting] = useState(false);

  const prepareData = async (): Promise<void> => {
    setValues({
      name: currentUser.name,
      email: currentUser.email,
      locale: currentUser.locale,
    });
  };

  const onSubmit = (mainItem: AnyObject) => {
    setSubmitting(true);
    UserApiClient.updateSelf(
      {
        user: preparedRequestBody({
          mainItem,
          mainFields,
          basicMainLabels,
        }),
      },
      currentUser.auth0_id
    ).then((res) => {
      localStorage.setItem('locale', res.locale || 'ua');
      window.location.href = '/cp/profile';
    });
  };

  useEffect(() => {
    setLoading(true);
    prepareData().finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setMainFields([
      {
        editable: true,
        hidden: true,
        internal_name: 'name',
        label: basicMainLabels.name,
        parsable: false,
        required: false,
      },
      {
        editable: true,
        hidden: true,
        internal_name: 'email',
        label: basicMainLabels.email,
        parsable: false,
        required: false,
      },
    ]);
  }, []);

  if (loading || !Object.keys(values).length) return null;

  return (
    <Page title={localize.profile.edit.title} actions={[]}>
      <Grid
        container
        spacing={2}
        alignItems='flex-start'
        alignSelf='flex-start'
      >
        <Grid item xs={12}>
          <Typography variant='body1'>
            {localize.profile.edit.infoLine1}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1'>
            {localize.profile.edit.infoLine2}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <br />
          <br />
          <Form
            mainFields={mainFields}
            submitting={submitting}
            defaultMainValues={values}
            onSubmit={onSubmit}
          />
        </Grid>
      </Grid>
    </Page>
  );
};

export default EditProfilePage;
