import { FC, useContext, useEffect, useState } from 'react';
import Page from '../../components/Page/Page';
import { useNavigate, useParams } from 'react-router-dom';
import { NotificationContext } from '../../contexts/NotificationContext';
import { CommonContext } from '../../contexts/CommonContext';
import {
  Delivery,
  Order,
  OrderApiClient,
  OrderItem,
} from '../../api/OrderApiClient';
import Loading from '../../components/Loading/Loading';
import localize from '../../localize';
import { OrderUploadApiClient } from '../../api/OrderUploadApiClient';
import { uploadToAws } from '../../utils/aws';
import OrderItemSelect from '../../containers/order/OrderItemSelect';
import { Customer } from '../../api/CustomerApiClient';
import { Promotion } from '../../api/PromotionApiClient';
import { User } from '../../api/UserApiClient';
import { Grid, Box, Button, Paper, Typography } from '@mui/material';
import ClientCustomerFields from '../../containers/order/ClientCustomerFields';
import DeliveryFields from '../../containers/order/DeliveryFields';
import UploadFilesFields from '../../containers/order/UploadFilesFields';

export const OrderEditPage: FC = () => {
  const { id, type } = useParams();
  const [order, setOrder] = useState<Order>();
  const [orderItems, setOrderItems] = useState<(OrderItem | any)[]>([{}]);
  const [fixedClientDiscount, setFixedClientDiscount] = useState(0);
  const [fixedCustomerDiscount, setFixedCustomerDiscount] = useState(0);
  const [client, setClient] = useState<User>();
  const [customer, setCustomer] = useState<Customer>();
  const [promotion, setPromotion] = useState<Promotion>();
  const [delivery, setDelivery] = useState<Delivery>();
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const { setNotification } = useContext(NotificationContext);
  const { currentProject } = useContext(CommonContext);
  const [files, setFiles] = useState<File[]>([]);
  const [hasErrors, setHasErrors] = useState(false);
  const navigate = useNavigate();

  const prepareData = async (): Promise<void> => {
    if (id) {
      const res = await OrderApiClient.getShow(currentProject.id, id);
      if (res.customer_id)
        setCustomer({
          id: res.customer_id,
          personal_discount: res.fixed_customer_discount,
          name: res.customer_name,
          phone_number: res.customer_phone_number,
        });
      if (res.promotion_id)
        setPromotion({
          id: res.promotion_id,
          title: res.promotion_title,
        } as Promotion);
      setFixedClientDiscount(res.fixed_client_discount);
      setFixedCustomerDiscount(res.fixed_customer_discount);
      setDelivery({
        delivery_address: res.delivery_address,
        delivery_operator: res.delivery_operator,
        delivery_recipient_name: res.delivery_recipient_name,
        delivery_recipient_phone: res.delivery_recipient_phone,
        delivery_ttn: res.delivery_ttn,
      });
      setFiles(res.files);
      setClient({
        id: res.user_id,
        auth0_id: res.user_auth0_id,
        discount: res.fixed_client_discount,
        role: res.user_role,
      } as User);
      setOrderItems(res.order_items);
      setOrder(res);
    }
  };

  const onSubmit = () => {
    if (!id || !type || !order) return;

    let orderParams: any = {};
    if (type == 'order-items') {
      orderParams.order_items_attributes = orderItems.filter(
        (oi) => (oi.product_modification_id && oi.count) || oi.id
      );
    } else if (type == 'client') {
      orderParams = {
        user_id: client?.id,
        fixed_client_discount: fixedClientDiscount,
        fixed_customer_discount: fixedCustomerDiscount,
        promotion_id: promotion?.id,
        customer_attributes: customer,
        ...(delivery || {}),
      };
    }

    setSubmitting(true);
    if (type !== 'files') {
      OrderApiClient.update(
        currentProject.id,
        {
          order: orderParams,
          update_type: type,
        },
        id
      ).then(() => {
        setNotification({
          severity: 'success',
          message: localize.general.successUpdateMessage,
        });
        setSubmitting(false);
        navigate(`/cp/orders/${order?.id}`);
      });
    } else {
      uploadToAws({
        projectId: currentProject.id,
        additionalParams: order.id,
        ApiClient: OrderUploadApiClient,
        files: files.filter((el: any) => !el.url),
      }).then((response) => {
        OrderApiClient.update(
          currentProject.id,
          {
            order: {
              files: [
                ...response.errors.filter((el) => !!el),
                ...response.success,
                ...files.filter((el: any) => el.url).map((el: any) => el.path),
              ],
            },
          },
          order.id
        ).then(() => {
          if (response.errors.length) {
            setNotification({
              severity: 'warning',
              message: localize.general.warningCreateMessage,
            });
          } else {
            setNotification({
              severity: 'success',
              message: localize.general.successUpdateMessage,
            });
          }
          setSubmitting(false);
          navigate(`/cp/orders/${order.id}`);
        });
      });
    }
  };

  useEffect(() => {
    if (!id) return;

    setLoading(true);
    prepareData().finally(() => setLoading(false));
  }, [id, currentProject]);

  return (
    <Page title={localize.orders.edit.title} actions={[]}>
      <Grid
        container
        spacing={5}
        alignItems='flex-start'
        alignSelf='flex-start'
      >
        <Grid item xs={12} display='flex' justifyContent='space-around'>
          <Box
            sx={{
              maxWidth: '1000px',
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ flex: '1 1 auto' }}>
              {loading ? (
                <Loading />
              ) : (
                !submitting && (
                  <>
                    {type == 'order-items' && (
                      <OrderItemSelect
                        orderItems={orderItems}
                        setOrderItems={setOrderItems}
                        setHasErrors={setHasErrors}
                      />
                    )}
                    {type == 'client' && (
                      <>
                        <ClientCustomerFields
                          fixedClientDiscount={fixedClientDiscount}
                          fixedCustomerDiscount={fixedCustomerDiscount}
                          setFixedClientDiscount={setFixedClientDiscount}
                          setFixedCustomerDiscount={setFixedCustomerDiscount}
                          setClient={setClient}
                          client={client}
                          customer={customer}
                          setCustomer={setCustomer}
                          promotion={promotion}
                          setPromotion={setPromotion}
                        />
                        <br />
                        <DeliveryFields
                          delivery={delivery}
                          setDelivery={setDelivery}
                          customer={customer}
                        />
                      </>
                    )}
                    {type == 'files' && (
                      <UploadFilesFields files={files} setFiles={setFiles} />
                    )}
                    {submitting && (
                      <Loading
                        text={
                          <>
                            <Typography variant='body1'>
                              Відправляємо дані...
                            </Typography>
                            <Typography variant='caption'>
                              Не повинно зайняти багато часу
                            </Typography>
                          </>
                        }
                      />
                    )}
                  </>
                )
              )}
            </Box>
            <br />
            <br />
            <Paper
              sx={{
                zIndex: 2,
                position: 'sticky',
                top: 'auto',
                bottom: '10px',
                maxWidth: '1000px',
                p: 2,
                display: 'flex',
                justifyContent: 'space-between',
                boxShadow:
                  'rgb(234, 236, 240) 0px 0px 15px, rgba(29, 41, 57, 0.08) 0px 1px 2px',
              }}
            >
              <Button
                variant='text'
                onClick={() => navigate(`/cp/orders/${order?.id}`)}
                disabled={submitting}
              >
                Назад
              </Button>
              <Button
                variant='contained'
                onClick={() => onSubmit()}
                disabled={hasErrors || submitting || loading}
              >
                Зберегти
              </Button>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
};

export default OrderEditPage;
