import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
}));

type CircularDeterminateProps = {
  value: number;
  color?: 'warning' | 'success' | 'secondary';
};

export const CircularDeterminate: FC<CircularDeterminateProps> = ({
  value,
  color,
}) => {
  return (
    <Container>
      <CircularProgress
        variant='determinate'
        value={value}
        color={color}
        sx={{ zIndex: 2, position: 'relative' }}
      />
      <CircularProgress
        variant='determinate'
        value={100}
        color={'secondary'}
        sx={{ position: 'absolute', left: 0, opacity: 0.2 }}
      />
    </Container>
  );
};

export default CircularDeterminate;
