import { FC, useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { CommonContext } from '../contexts/CommonContext';
import Loading from '../components/Loading/Loading';
import { useAsyncError } from '../hooks/useAsyncError';
import { NotificationContext } from '../contexts/NotificationContext';
import localize from '../localize';
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { UserApiClient } from '../api/UserApiClient';
import { Check, Close } from '@mui/icons-material';

const InnerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '30%',
  minWidth: '600px',
}));

const CardContentInner = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

const IntegrationStatus = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const TelegramAddonCard: FC = () => {
  const { currentProject, currentUser, projects } = useContext(CommonContext);
  const throwError = useAsyncError();
  const [telegramUsername, setTelegramUsername] = useState<string>();
  const [chatId, setChatId] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { setNotification } = useContext(NotificationContext);

  const triggerUpdate = async (): Promise<void> => {
    setSubmitting(true);
    await UserApiClient.updateSelf(
      {
        user: { telegram_username: telegramUsername },
      },
      currentUser.auth0_id
    )
      .then((res) => {
        setNotification({
          severity: 'success',
          message: localize.general.successCreateMessage,
        });
        setTelegramUsername(res.telegram_username);
        setChatId(res.chat_id);
      })
      .catch((e) => {
        throwError(e);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const preparePageData = async (): Promise<void> => {
    const res = await UserApiClient.getShowSelf(currentUser.auth0_id).catch(
      (e) => {
        throwError(e);
      }
    );
    setTelegramUsername(res?.telegram_username);
    setChatId(res?.chat_id);
  };

  useEffect(() => {
    setLoading(true);
    preparePageData().finally(() => setLoading(false));
  }, [currentProject]);

  return (
    <InnerContainer>
      <Card>
        <CardHeader
          title='Telegram інтеграція'
          titleTypographyProps={{ variant: 'h4' }}
          subheader='Дозволяє отримувати сповіщення про замовлення створені через ключ доступу'
          subheaderTypographyProps={{ variant: 'body2' }}
        />
        <CardContent>
          {projects.length > 1 ? (
            <Alert severity='info'>
              Увага - зміна застосовується для вашого аккаунту користувача
              загалом (а не тільки для проекту на якому ви зараз є)
            </Alert>
          ) : null}
          {loading ? (
            <Loading />
          ) : (
            <CardContentInner>
              <IntegrationStatus>
                <span>Статус інтеграції</span>
                {chatId ? (
                  <Chip
                    icon={<Check />}
                    label='Активна'
                    variant='outlined'
                    color='success'
                  />
                ) : (
                  <Chip
                    icon={<Close />}
                    label='Неактивна'
                    variant='outlined'
                    color='error'
                  />
                )}
              </IntegrationStatus>
              <Typography variant='body1' color='#8B8B8B'>
                Щоб налаштувати інтеграцію
              </Typography>
              <Typography variant='body1' color='#8B8B8B'>
                1) напишіть повідомлення нашому боту (будь-яке){' '}
                <Link
                  href='//t.me/fw_api_orders_bot?start=start'
                  target='_blank'
                >
                  FactoryWise API Orders
                </Link>
              </Typography>
              <Typography variant='body1' color='#8B8B8B'>
                2) введіть ваш Telegram username (те що після '@' коли вас
                згадують)
              </Typography>
              <Typography variant='body1' color='#8B8B8B'>
                та натисніть на кнопку зберегти.
              </Typography>
              <Typography variant='body1' color='#8B8B8B'>
                Якщо протягом декількох хвилин інтеграція не стала активною то
                спробуйте ще раз.
              </Typography>
              <br />

              <TextField
                id='telegram_username'
                name='telegram_username'
                label='Telegram username'
                type='text'
                value={telegramUsername || ''}
                onChange={(e) => setTelegramUsername(e.target.value)}
                margin='none'
                fullWidth
              />
              <br />
              <Button
                sx={{ alignSelf: 'flex-end' }}
                variant='contained'
                onClick={triggerUpdate}
                disabled={submitting}
              >
                {submitting ? (
                  <CircularProgress size={26} thickness={6} color='primary' />
                ) : (
                  localize.general.submit
                )}
              </Button>
            </CardContentInner>
          )}
          <br />
        </CardContent>
      </Card>
    </InnerContainer>
  );
};

export default TelegramAddonCard;
