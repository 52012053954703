import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import localize from '../localize';
import { fieldsForTypes } from '../utils/template';
import { CommonContext } from './CommonContext';
import { preparedColumns } from '../utils/table';

export type FilterArea = 'products' | 'orders';
export type FilterDataItem = {
  mainItems: any[];
  subItems: any[];
};
export type FilterData = {
  [key in FilterArea]: FilterDataItem;
};

type FilterProps = {
  filterData: FilterData;
  setItems(area: FilterArea, newMainItems: any[], newSubItems: any[]): void;
  setMainItems(area: FilterArea, newItems: any[]): void;
  setSubItems(area: FilterArea, newItems: any[]): void;
};

export const FilterContext = createContext<FilterProps>({
  filterData: {
    products: { mainItems: [], subItems: [] },
    orders: { mainItems: [], subItems: [] },
  },
  setItems: () => {},
  setMainItems: (area: FilterArea, newItems: any[]) => {},
  setSubItems: (area: FilterArea, newItems: any[]) => {},
});

export const FilterContextProvider: FC<PropsWithChildren> = (props) => {
  const { templates } = useContext(CommonContext);
  const [filterData, setFilterData] = useState<any>({
    products: { mainItems: [], subItems: [] },
    orders: { mainItems: [], subItems: [] },
  });

  const setMainItems = (area: FilterArea, newItems: any[]) => {
    const newFilterData = structuredClone(filterData);
    newFilterData[area] = {
      ...newFilterData[area],
      mainItems: newItems,
    };
    setFilterData(newFilterData);
  };

  const setSubItems = (area: FilterArea, newItems: any[]) => {
    const newFilterData = structuredClone(filterData);
    newFilterData[area] = {
      ...newFilterData[area],
      subItems: newItems,
    };
    setFilterData(newFilterData);
  };

  const setItems = (
    area: FilterArea,
    newMainItems: any[],
    newSubItems: any[]
  ) => {
    const newFilterData = structuredClone(filterData);
    newFilterData[area] = {
      ...newFilterData[area],
      mainItems: newMainItems,
      subItems: newSubItems,
    };
    setFilterData(newFilterData);
  };

  useEffect(() => {
    if (
      !!filterData.orders.mainItems.length ||
      !!filterData.products.mainItems.length ||
      !templates?.length
    )
      return;
    setFilterData({
      products: {
        mainItems: fieldsForTypes(templates, ['Product']),
        subItems: [
          {
            field_type: 'number',
            id: 'available_count-322',
            internal_name: 'available_count',
            label: localize.products.columns.availableCount,
            localized_field: {
              en: { label: localize.products.columns.availableCount },
            },
            options: [],
          },
          {
            field_type: 'number',
            id: 'actual_count-322',
            internal_name: 'actual_count',
            label: localize.products.columns.actualCount,
            localized_field: {
              en: { label: localize.products.columns.actualCount },
            },
            options: [],
          },
          ...fieldsForTypes(templates, ['Product modification']).filter(
            (field: any) => !field.parsable
          ),
        ],
      },
      orders: {
        mainItems: [
          {
            field_type: 'string',
            id: 'user_name-322',
            internal_name: 'user_name',
            label: localize.orders.columns.user_name,
            localized_field: {
              en: { label: localize.orders.columns.user_name },
            },
            options: [],
          },
          {
            field_type: 'number',
            id: 'sum-322',
            internal_name: 'sum',
            label: localize.orders.columns.sum,
            localized_field: { en: { label: localize.orders.columns.sum } },
            options: [],
          },
          {
            field_type: 'date',
            id: 'sent_to_manager_at',
            internal_name: 'sent_to_manager_at',
            label: 'Дата першої відправки менеджеру',
            localized_field: {
              en: { label: 'Дата першої відправки менеджеру' },
            },
            options: [],
          },
          {
            field_type: 'date',
            id: 'updated_at',
            internal_name: 'updated_at',
            label: 'Дата останнього оновлення',
            localized_field: { en: { label: 'Дата останнього оновлення' } },
            options: [],
          },
          ...preparedColumns(fieldsForTypes(templates, ['Order'])).map(
            (field) => ({
              field_type: field.type,
              id: field.field,
              internal_name: field.field,
              label: field.headerName,
              localized_field: { en: { label: field.headerName } },
              options: [],
            })
          ),
        ],
        subItems: [
          {
            field_type: 'string',
            id: 'product_title-322',
            internal_name: 'product_title',
            label: localize.orders.columns.product_title,
            localized_field: {
              en: { label: localize.orders.columns.product_title },
            },
            options: [],
          },
        ],
      },
    });
  }, [filterData, templates]);

  const value = useMemo(
    () => ({
      filterData,
      setItems,
      setMainItems,
      setSubItems,
    }),
    [filterData, setMainItems, setSubItems]
  );

  return (
    <FilterContext.Provider value={value}>
      {props.children}
    </FilterContext.Provider>
  );
};

FilterContextProvider.displayName = 'FilterContextProvider';
