import { FC, useContext, useEffect, useMemo, useState } from 'react';
import Page, { PageAction } from '../../components/Page/Page';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import { BasicTable } from '../../components/BaseTable/BaseTable';
import { CommonContext } from '../../contexts/CommonContext';
import { CustomerApiClient } from '../../api/CustomerApiClient';
import Loading from '../../components/Loading/Loading';
import { NotificationContext } from '../../contexts/NotificationContext';
import { AnyObject } from '../../api/anyObjectTypes';
import localize from '../../localize';

export const baseMainFields = [
  {
    field: 'name',
    headerName: 'Імʼя',
    align: 'left',
    type: 'string',
  },
  {
    field: 'email',
    headerName: 'Емейл',
    align: 'left',
    type: 'string',
  },
  {
    field: 'phone_number',
    headerName: 'Номер телефону',
    type: 'string',
  },
  {
    field: 'personal_discount',
    headerName: 'Знижка (%)',
    align: 'right',
    type: 'string',
  },
];

export const ClientShowPage: FC = () => {
  const { id } = useParams();
  const { currentProject, currentUser } = useContext(CommonContext);
  const [item, setItem] = useState<AnyObject>({});
  const [itemIdToDelete, setItemIdToDelete] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);
  const { setNotification } = useContext(NotificationContext);
  const navigate = useNavigate();

  const pageActions = useMemo(() => {
    const editAction = { title: 'Редагувати', link: `/cp/clients/edit/${id}` };
    const deleteAction = {
      title: 'Видалити',
      onClick: () => setItemIdToDelete(id),
    };
    const res: PageAction[] = [];

    if (currentUser.user_permissions.customer?.update?.includes('all'))
      res.push(editAction);
    if (currentUser.user_permissions.customer?.destroy?.length)
      res.push(deleteAction);

    return res;
  }, [item, id, setItemIdToDelete, itemIdToDelete]);

  const preparePageData = async (id: string): Promise<void> => {
    const res = await CustomerApiClient.getShow(currentProject.id, id);
    setItem(res);
  };

  const deleteItem = () => {
    if (itemIdToDelete)
      CustomerApiClient.remove(currentProject.id, itemIdToDelete).then(() => {
        setItemIdToDelete(undefined);
        setNotification({
          severity: 'success',
          message: localize.general.successDeleteMessage,
        });
        navigate(`/cp/customers`);
      });
  };

  useEffect(() => {
    if (!id) return;

    setLoading(true);
    preparePageData(id).finally(() => setLoading(false));
  }, [id, currentProject]);

  if (loading)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page title='Перегляд клієнта' actions={pageActions}>
      <Grid
        container
        spacing={10}
        alignItems='flex-start'
        alignSelf='flex-start'
      >
        <Grid item xs={12}>
          <BasicTable
            rows={[{ ...item, id: item.auth0_id }]}
            columns={baseMainFields}
            colorRow
          />
        </Grid>
      </Grid>
      <ConfirmationDialog
        header={'Ви впевнені?'}
        open={!!itemIdToDelete}
        description={
          'Ця дія безповоротна. Буде видалено не лише цього клієнта, а й звʼязані з ним замовлення'
        }
        action={() => deleteItem()}
        discard={() => setItemIdToDelete(undefined)}
        mainActionButtonText='Так, видалити'
      />
    </Page>
  );
};

export default ClientShowPage;
