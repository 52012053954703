import { Button, styled } from '@mui/material';
import React, { useState } from 'react';
import { MainText, MainTitle } from '../../Price';
import phoneImg from '../../../../assets/landing/comment-matte.svg';
import localize from '../../../../localize';
import { ContactModal } from '../ContactModal';
export const ConsultContainer = styled('section')(() => ({
  padding: '10px 0 90px 0',
  position: 'relative',
  overflowX: 'hidden',
}));

export const PhoneImg = styled('img')(() => ({
  maxWidth: '100%',
}));

const ConsultContainerInner = styled('div')(() => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'space-evenly',
  margin: '0 auto',
  alignItems: 'center',
  padding: '0 20px',
  width: '1280px',
  maxWidth: '100%',
  minWidth: '700px',
  marginRight: 'auto',
}));

const RoundedBg = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '325px',
  minWidth: '500px',

  color: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '&::after': {
    content: "' '",
    position: 'absolute',
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    height: '100%',
    width: '100vw',
    backgroundColor: '#AFABEF',
    borderTopLeftRadius: '24px',
    borderBottomLeftRadius: '24px',
    zIndex: '-1',
  },
}));

const TextContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  paddingLeft: '150px',
  maxWidth: '625px',
  gap: '24px',
}));
export const ConsultSection = () => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <ConsultContainer>
      <ConsultContainerInner>
        <PhoneImg src={phoneImg} />
        <RoundedBg>
          <TextContainer>
            <MainTitle variant='h2' sx={{ textAlign: 'left' }}>
              {localize.landing.pricingPage.consultSectionTitle}
            </MainTitle>
            <MainText>
              {localize.landing.pricingPage.consultSectionDescription}
            </MainText>
            <Button
              variant='rounded'
              sx={{
                color: '#0c0c0c',
                backgroundColor: '#fff',
                boxShadow: 'none',
                alignSelf: 'flex-start',
                marginLeft: '20px',
              }}
              onClick={() => setOpenModal(true)}
            >
              {localize.landing.pricingPage.consultSectionCTAButton}
            </Button>
            <ContactModal
              open={openModal}
              handleClose={() => setOpenModal(false)}
              handleSubmit={() => setOpenModal(false)}
              title={localize.landing.modal.titleContactUs}
              imgPath={phoneImg}
            />
          </TextContainer>
        </RoundedBg>
      </ConsultContainerInner>
    </ConsultContainer>
  );
};
