import { FC } from 'react';
import { CustomerKeys, Order } from '../../api/OrderApiClient';
import { Typography } from '@mui/material';
import IconWithInfo from '../../components/IconWithInfo';

type CustomerDetailsCardProps = {
  order: Order;
};

const customerFieldsAndLabels: { [key in CustomerKeys]: string } = {
  customer_name: 'Імʼя',
  customer_email: 'Емейл',
  fixed_customer_discount: 'Знижка',
  customer_phone_number: 'Телефон',
};

export const CustomerDetailsCard: FC<CustomerDetailsCardProps> = ({
  order,
}) => {
  return Object.keys(customerFieldsAndLabels).some(
    (el) => order[el as CustomerKeys]
  ) ? (
    <>
      <br />
      <Typography variant='h4' pb={1}>
        Дані замовника
      </Typography>
      {Object.keys(customerFieldsAndLabels).map((el) =>
        order[el as CustomerKeys] ? (
          <Typography variant='body1'>
            {el == 'fixed_customer_discount' ? (
              <>
                {customerFieldsAndLabels[el as CustomerKeys]}{' '}
                <IconWithInfo text='Фіксована знижка замовника саме на це замовлення. Ця знижка використовувалась для обрахунку суми до сплати покупцем. Актуальну знижку замовника на даний момент ви можете переглянути в профілі замовника.' />
                : <b>{order.fixed_customer_discount || 0}%</b>
              </>
            ) : (
              <>
                {customerFieldsAndLabels[el as CustomerKeys]}:{' '}
                <b>{order[el as CustomerKeys]}</b>
              </>
            )}
          </Typography>
        ) : null
      )}
    </>
  ) : null;
};

export default CustomerDetailsCard;
