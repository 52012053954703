import { styled } from '@mui/material/styles';

export const FileContainer = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  position: 'relative',

  '&::before': {
    content: "'...'",
    backgroundColor: '#E2E1F1',
    position: 'absolute',
    left: 0,
  },
}));
