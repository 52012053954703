const DO_STG_FUNCTION_URL =
  'https://faas-fra1-afec6ce7.doserverless.co/api/v1/web/fn-ad616afe-2de5-4cd7-b1d4-c30656cb2274/default/fw-landing';
const DO_FUNCTION_URL =
  'https://faas-fra1-afec6ce7.doserverless.co/api/v1/web/fn-574dc93d-3a65-4b75-9f5e-4b41272d1b04/default/landing-fw';

export const apiCallWrapper = (path: string, data: any) =>
  fetch(
    `${!!process.env.REACT_APP_POINT_TO_STAGING ? DO_STG_FUNCTION_URL : DO_FUNCTION_URL}${path}`,
    data
  )
    .then((res) => res.json())
    .then((res) => {
      if (res.error) throw Error(res.error);
      return res;
    });
