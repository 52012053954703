import { FC, useContext, useEffect, useMemo, useState } from 'react';
import Page from '../../components/Page/Page';
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import { CommonContext } from '../../contexts/CommonContext';
import { Order, OrderApiClient } from '../../api/OrderApiClient';
import Loading from '../../components/Loading/Loading';
import { NotificationContext } from '../../contexts/NotificationContext';
import localize from '../../localize';
import PageMenu from '../../components/PageMenu/PageMenu';
import { post } from '../../api/request';
import { BookingApiClient } from '../../api/BookingApiClient';
import StatusesTimeline from '../../containers/StatusesTimeline';
import ClientDetailsCard from '../../containers/order/ClientDetailsCard';
import OrderItemsCard from '../../containers/order/OrderItemsCard';
import FilesCard from '../../containers/order/FilesCard';
import CommentsCard from '../../containers/order/CommentsCard';
import TotalSumCard from '../../containers/order/TotalSumCard';
import GeneralCard from '../../containers/order/GeneralCard';
import AuditsContent from '../../containers/AuditsContent';

export const OrderShowPage: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setNotification } = useContext(NotificationContext);
  const { currentProject, currentUser } = useContext(CommonContext);
  const [item, setItem] = useState<Order>();
  const [itemIdToDelete, setItemIdToDelete] = useState<string | undefined>();
  const [statusToChangeTo, setStatusToChangeTo] = useState<
    string | undefined
  >();
  const [deleting, setDeleting] = useState<boolean>(false);
  const [booking, setBooking] = useState<boolean>(false);
  const [changingStatus, setChangingStatus] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [itemIdToBookTemporary, setItemIdToBookTemporary] = useState<
    string | undefined
  >();

  const inQueue = useMemo(
    () =>
      item?.order_items &&
      !!item?.order_items.filter((oi: any) => !oi?.hide_collapsed).length &&
      currentUser.user_permissions.role_name !== 'client',
    [item, currentUser]
  );

  const pageActions = useMemo(() => {
    if (!item) return [];

    const deleteAction = {
      title: localize.orders.show.deleteButton,
      onClick: () => setItemIdToDelete(id),
    };
    const res: any[] = [];

    if (item?.last_status !== 'Canceled' && item?.last_status !== 'Complete') {
      if (item?.editable_by_current_user) {
        let validStatuses = ['Pending'];
        if (
          ['staff', 'owner', 'manager', 'administrator'].includes(
            currentUser?.user_permissions?.role_name
          )
        )
          validStatuses = [
            'Pending',
            'Ready',
            'Complete',
            'Canceled',
            'Blocked',
          ];
        validStatuses
          .filter((st) => st !== item?.last_status)
          .forEach((status) =>
            res.push({
              title:
                status === 'Pending' &&
                currentUser?.user_permissions?.role_name === 'client'
                  ? localize.orders.show.clientChangeStatusToPending
                  : localize.orders.show[`changeStatusTo${status}`],
              onClick: () => setStatusToChangeTo(status),
            })
          );
        if (
          item?.last_status === 'Draft' &&
          !!item?.order_items?.filter((oi: any) => !oi.is_booked).length
        ) {
          res.push({
            title: localize.orders.show.bookTemporary,
            onClick: () => setItemIdToBookTemporary(id),
          });
        }
      }

      if (item?.editable_by_current_user) {
        res.push({ divider: true });
      }

      if (
        currentUser.user_permissions.order?.destroy?.length &&
        (currentUser.user_permissions.role_name !== 'client' ||
          item?.last_status === 'Draft')
      )
        res.push(deleteAction);
    }

    // return [{ Component: <DocumentsMenu order={item} /> }, { Component: <PageMenu menuItems={res} /> }];
    return [{ Component: <PageMenu menuItems={res} /> }];
  }, [item, id, setItemIdToDelete, itemIdToDelete]);

  const preparePageData = async (id: string): Promise<void> => {
    const res = await OrderApiClient.getShow(currentProject.id, id);
    setItem({ ...res });
  };

  const deleteItem = () => {
    if (itemIdToDelete) {
      setDeleting(true);
      OrderApiClient.remove(currentProject.id, itemIdToDelete).then(() => {
        setItemIdToDelete(undefined);
        setNotification({
          severity: 'success',
          message: localize.general.successDeleteMessage,
        });
        setDeleting(false);
        navigate(`/cp/orders`);
      });
    }
  };

  const bookTemporary = () => {
    if (item && itemIdToBookTemporary && item.order_items?.length > 0) {
      setBooking(true);
      Promise.all(
        item.order_items.map((orderItem: any) =>
          BookingApiClient.create(currentProject.id, {
            order_item_id: orderItem.id,
          })
        )
      ).then(() => {
        setItemIdToBookTemporary(undefined);
        setNotification({
          severity: 'success',
          message: localize.general.successMessage,
        });
        loadData(item.id);
        setBooking(false);
      });
    }
  };

  const changeStatus = () => {
    if (statusToChangeTo && id) {
      setChangingStatus(true);
      post(`projects/${currentProject.id}/orders/${id}/order_statuses`, {
        title: statusToChangeTo,
      }).then(() => {
        setStatusToChangeTo(undefined);
        setNotification({
          severity: 'success',
          message: localize.general.successMessage,
        });
        loadData(id);
        setChangingStatus(false);
      });
    }
  };

  const loadData = (id: string) => {
    setLoading(true);
    preparePageData(id).finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!id) return;

    loadData(id);
  }, [id, currentProject]);

  if (loading || !item || !id)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page
      title={`Замовлення ${item.source ? `(${item.source})` : ''} №${item.id}`}
      actions={pageActions}
      subtitle={inQueue ? localize.orders.show.queueAttention : undefined}
    >
      <Grid container>
        <Grid
          item
          container
          xs={12}
          spacing={5}
          alignItems='flex-start'
          alignSelf='flex-start'
        >
          {Object.keys(item.additional_fields || {}).length ? (
            <Grid item xs={12}>
              <Alert severity='info'>
                Це замовлення було створено використовуючи застарілий формат, ми
                не мігрували всі дані, але ви маєте змогу побачити ті що не було
                мігровано:
                <pre>
                  {JSON.stringify(item.additional_fields || {}, null, 2)}
                </pre>
              </Alert>
            </Grid>
          ) : null}
          <Grid item container spacing={5} md={8} xs={12}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item container md={6} xs={12}>
                  <Grid item xs={12} component={Paper}>
                    <GeneralCard order={item} />
                  </Grid>
                </Grid>
                <Grid item container md={6} xs={12}>
                  <Grid item xs={12} component={Paper}>
                    <TotalSumCard order={item} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <OrderItemsCard order={item} />
            </Grid>
            <Grid item xs={12}>
              <CommentsCard order={item} />
            </Grid>
          </Grid>
          <Grid item container spacing={5} md={4} xs={12}>
            <Grid item container xs={12}>
              <Grid item xs={12} component={Paper}>
                <ClientDetailsCard order={item} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FilesCard order={item} />
            </Grid>
            <Grid item xs={12}>
              <Card sx={{ border: '1px solid #DFDDF9', borderRadius: '8px' }}>
                <CardHeader
                  title='Статус'
                  titleTypographyProps={{ variant: 'h4' }}
                  subheader='Історія змін'
                  subheaderTypographyProps={{ variant: 'body2' }}
                />
                <CardContent>
                  <StatusesTimeline />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <AuditsContent auditedType='Order' auditedId={id} />
          </Grid>
        </Grid>
      </Grid>
      <ConfirmationDialog
        header={localize.orders.show.deleteConfirmationHeading}
        open={!!itemIdToDelete}
        submitting={deleting}
        description={localize.orders.show.deleteConfirmationText}
        action={() => deleteItem()}
        discard={() => setItemIdToDelete(undefined)}
        mainActionButtonText={localize.orders.show.deleteConfirmationButtonText}
      />
      <ConfirmationDialog
        header={localize.orders.show.bookTemporaryConfirmationHeading}
        open={!!itemIdToBookTemporary}
        submitting={booking}
        description={localize.orders.show.bookTemporaryConfirmationDescription}
        action={() => bookTemporary()}
        discard={() => setItemIdToBookTemporary(undefined)}
        mainActionButtonText={
          localize.orders.show.bookTemporaryConfirmationButtonText
        }
      />
      <ConfirmationDialog
        header={localize.orders.show.statusChangeConfirmationHeading}
        open={!!statusToChangeTo}
        submitting={changingStatus}
        description={
          <>
            {statusToChangeTo &&
              localize.order_statuses.values_info[
                statusToChangeTo === 'Pending' &&
                currentUser.user_permissions.role_name === 'client'
                  ? 'Pending_client'
                  : statusToChangeTo
              ]}
            {statusToChangeTo === 'Complete' && inQueue ? (
              <>
                <br />
                <br />
                {localize.orders.show.changeStatusToCompleteWithQueue}
              </>
            ) : (
              ''
            )}
          </>
        }
        action={() => changeStatus()}
        discard={() => setStatusToChangeTo(undefined)}
        mainActionButtonText={
          localize.orders.show.statusChangeConfirmationButtonText
        }
      />
    </Page>
  );
};

export default OrderShowPage;
