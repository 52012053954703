import { Button, Link, styled } from '@mui/material';
import React from 'react';
import localize from '../../localize';
import manufacturer from '../../assets/onboarding/tools-manufacturer.png';
import dropshipper from '../../assets/onboarding/box-dropshipper.png';
import { useOnboardingMultiStep } from '../../contexts/OnboardingContext';
import {
  OnboaringPageContainer,
  MainTitle,
  MainDescription,
} from './OnboardingPage';

const OnboardingInnerContainer = styled('div')(() => ({
  display: 'flex',
  maxWidth: '686px',
  width: '100%',
  margin: '0 auto',
  flexDirection: 'column',
  padding: '0 20px',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '24px',
}));

const RoleBox = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'normal',
  gap: '40px',
  marginBottom: '40px',
}));

const RoleButton = styled('button')(() => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#FAF9FF',
  textAlign: 'left',
  transition: 'all .3s ease',
  border: '2px solid transparent',
  borderRadius: '16px',
  padding: '8px 16px',
  height: '230px',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: '16px',
  fontWeight: 500,
  fontSize: '12px',
  fontFamily: '"Montserrat", sans-serif',
  cursor: 'pointer',
  '&.active': {
    backgroundColor: '#fff',
    BoxShadow: '4px 8px 20px 0px #3326AE14',
    border: '2px solid #AFABEF',
  },
  img: {
    width: '32px',
  },
  span: {
    fontWeight: 500,
    fontSize: '16px',
    textAlign: 'left',
    color: '#0C0C0C',
    fontFamily: '"Montserrat", sans-serif',
  },
  p: {
    fontWeight: 400,
    margin: 0,
    fontSize: '12px',
    color: '#464646',
    fontFamily: '"Montserrat", sans-serif',
  },
}));

export const ButtonsBox = styled('div')(() => ({
  display: 'flex',
  gap: '16px',
  justifyContent: 'center',
}));

export const OnboardingSelectPath = () => {
  const { path, handlePath, nextStep } = useOnboardingMultiStep();
  return (
    <OnboaringPageContainer>
      <OnboardingInnerContainer>
        <MainTitle>{localize.onboarding.indexPage.title}</MainTitle>
        <MainDescription>
          {localize.onboarding.indexPage.mainDescription}
        </MainDescription>
        <RoleBox>
          <RoleButton
            className={path === 'manufacturer' ? 'active' : ''}
            onClick={() => handlePath('manufacturer')}
          >
            <img src={manufacturer} alt='tools' />
            <span>{localize.onboarding.indexPage.manufacturer.title}</span>
            <p>{localize.onboarding.indexPage.manufacturer.description}</p>
          </RoleButton>
          <RoleButton
            className={path === 'dropshipper' ? 'active' : ''}
            onClick={() => handlePath('dropshipper')}
          >
            <img src={dropshipper} alt='box' />
            <span>{localize.onboarding.indexPage.dropshipper.title}</span>
            <p>{localize.onboarding.indexPage.dropshipper.description}</p>
          </RoleButton>
        </RoleBox>
        <ButtonsBox>
          <Button
            variant='rounded'
            onClick={nextStep}
            sx={{
              padding: '14px 44px',
            }}
          >
            {localize.onboarding.indexPage.nextButton}
          </Button>
          <Button variant='transparent' component={Link} href='/cp'>
            {localize.onboarding.indexPage.cancelButton}
          </Button>
        </ButtonsBox>
      </OnboardingInnerContainer>
    </OnboaringPageContainer>
  );
};
