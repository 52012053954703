import { FC, useContext, useEffect, useMemo, useState } from 'react';
import Page, { PageAction } from '../../components/Page/Page';
import { Grid } from '@mui/material';
import { fieldsForTypes } from '../../utils/template';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import { preparedColumns } from '../../utils/table';
import { BasicTable } from '../../components/BaseTable/BaseTable';
import { CommonContext } from '../../contexts/CommonContext';
import { BookingApiClient } from '../../api/BookingApiClient';
import Loading from '../../components/Loading/Loading';
import { NotificationContext } from '../../contexts/NotificationContext';
import { AnyObject } from '../../api/anyObjectTypes';
import localize from '../../localize';
import { timeLeft } from '../../utils/time';

export const baseMainFields = [
  {
    field: 'count',
    headerName: localize.bookings.columns.count,
    type: 'number',
  },
  {
    field: 'user_name',
    headerName: localize.bookings.columns.user_name,
    type: 'string',
  },
  {
    field: 'comment',
    headerName: localize.bookings.columns.comment,
    type: 'string',
  },
  {
    field: 'enddate',
    headerName: localize.bookings.columns.enddate,
    type: 'string',
    width: '200px',
    valueGetter: (row: any) =>
      row.status === 'Active' && (!row.order_id || row.order_status === 'Draft')
        ? timeLeft(new Date(row.enddate)) !== '0'
          ? timeLeft(new Date(row.enddate))
          : localize.bookings.status['Disabled']
        : localize.bookings.status[row.status],
  },
];

export const BookingShowPage: FC = () => {
  const { id } = useParams();
  const { currentProject, currentUser, templates } = useContext(CommonContext);
  const [item, setItem] = useState<AnyObject>({});
  const [productColumns, setProductColumns] = useState<AnyObject[]>([]);
  const [productModificationColumns, setProductModificationColumns] = useState<
    AnyObject[]
  >([]);
  const [itemIdToDelete, setItemIdToDelete] = useState<string | undefined>();
  const [bookingIdToUpdateStatus, setBookingIdToUpdateStatus] = useState<
    string | undefined
  >();
  const [deleting, setDeleting] = useState<boolean>(false);
  const [updatingStatus, setUpdatingStatus] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const { setNotification } = useContext(NotificationContext);
  const navigate = useNavigate();

  const pageActions = useMemo(() => {
    const updateStatusAction = {
      title: localize.bookings.show.updateStatusButton,
      onClick: () => setBookingIdToUpdateStatus(id),
    };
    const showOrderAction = {
      title: localize.bookings.show.showOrderButton,
      link: `/cp/orders/${item.order_id}`,
    };
    const editAction = {
      title: localize.bookings.show.editButton,
      link: `/cp/bookings/edit/${id}`,
    };
    const deleteAction = {
      title: localize.bookings.show.deleteButton,
      onClick: () => setItemIdToDelete(id),
    };
    const res: PageAction[] = [];

    if (item.status !== 'Active') return [];

    if (currentUser.user_permissions.booking?.update?.length) {
      if (item.order_id && item.order_status !== 'Draft') {
        res.push(showOrderAction);
      } else if (item.order_id && item.order_status === 'Draft') {
        if (currentUser.user_permissions.booking?.destroy?.length)
          res.push(deleteAction);
      } else {
        res.push(updateStatusAction);
        res.push(editAction);
        if (currentUser.user_permissions.booking?.destroy?.length)
          res.push(deleteAction);
      }
    }

    return res;
  }, [item, id, setItemIdToDelete, itemIdToDelete]);

  const preparePageData = async (id: string): Promise<void> => {
    const res = await BookingApiClient.getShow(currentProject.id, id);
    setItem(res);

    const customProductFields = fieldsForTypes(templates, ['Product']);

    setProductColumns([...preparedColumns(customProductFields || [])]);

    const customProductModificationFields = fieldsForTypes(templates, [
      'Product modification',
    ]);

    setProductModificationColumns([
      ...preparedColumns(customProductModificationFields || []),
    ]);
  };

  const deleteItem = () => {
    if (itemIdToDelete) {
      setDeleting(true);
      BookingApiClient.remove(currentProject.id, itemIdToDelete).then(() => {
        setItemIdToDelete(undefined);
        setNotification({
          severity: 'success',
          message: localize.general.successDeleteMessage,
        });
        setDeleting(false);
        navigate(`/cp/bookings`);
      });
    }
  };

  const updateStatus = () => {
    if (bookingIdToUpdateStatus) {
      setUpdatingStatus(true);
      BookingApiClient.update(
        currentProject.id,
        {
          booking: { status: 'Completed' },
        },
        bookingIdToUpdateStatus
      ).then(() => {
        setBookingIdToUpdateStatus(undefined);
        setNotification({
          severity: 'success',
          message: localize.general.successUpdateMessage,
        });
        setUpdatingStatus(false);
        setItem({ ...item, status: 'Stock updated' });
      });
    }
  };

  useEffect(() => {
    if (!id) return;

    setLoading(true);
    preparePageData(id).finally(() => setLoading(false));
  }, [id, currentProject]);

  if (loading)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page
      title={localize.bookings.show.title}
      actions={pageActions}
      rightContent={
        item.status !== 'Active'
          ? `${localize.bookings.show.nonActive}. ${item.status === 'Stock updated' ? localize.bookings.show.stockUpdated : localize.bookings.show.disabled}.`
          : undefined
      }
    >
      <Grid
        container
        spacing={10}
        alignItems='flex-start'
        alignSelf='flex-start'
      >
        <Grid item xs={12}>
          <BasicTable
            rows={[{ ...item }]}
            columns={baseMainFields}
            colorRow
            expandColumns
          />
        </Grid>
        <Grid item xs={12}>
          <BasicTable
            rows={[{ ...item.product }]}
            columns={productColumns}
            colorRow
          />
        </Grid>
        <Grid item xs={12}>
          <BasicTable
            rows={[{ ...item.product_modification }]}
            columns={productModificationColumns}
            colorRow
          />
        </Grid>
      </Grid>
      <ConfirmationDialog
        header={localize.bookings.show.deleteConfirmationHeading}
        open={!!itemIdToDelete}
        description={localize.bookings.show.deleteConfirmationText}
        action={() => deleteItem()}
        submitting={deleting}
        discard={() => setItemIdToDelete(undefined)}
        mainActionButtonText={
          localize.bookings.show.deleteConfirmationButtonText
        }
      />
      <ConfirmationDialog
        header={localize.bookings.show.updateConfirmationHeading}
        open={!!bookingIdToUpdateStatus}
        description={localize.bookings.show.updateConfirmationText}
        action={() => updateStatus()}
        submitting={updatingStatus}
        discard={() => setBookingIdToUpdateStatus(undefined)}
        mainActionButtonText={
          localize.bookings.show.updateConfirmationButtonText
        }
      />
    </Page>
  );
};

export default BookingShowPage;
