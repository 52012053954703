import { styled } from '@mui/material';
import TextField from '@mui/material/TextField';
import { MuiTelInput } from 'mui-tel-input';
import { OnboardingProvider } from '../../contexts/OnboardingContext';
import { StepManager } from './StepManager';

export const OnboaringPageContainer = styled('section')(() => ({
  display: 'flex',
  height: '100vh',
  minHeight: '600px',
  minWidth: '700px',
  position: 'relative',
  fontFamily: 'Montserrat, sans-serif',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}));
export const MainTitle = styled('h2')(() => ({
  fontWeight: 500,
  fontSize: '18px',
  textAlign: 'center',
  margin: 0,
  fontFamily: '"Montserrat", sans-serif',
}));

export const MainDescription = styled('p')(() => ({
  fontWeight: 400,
  fontSize: '12px',
  textAlign: 'center',
  marginBottom: '40px',
  fontFamily: '"Montserrat", sans-serif',
}));

export const ButtonsBox = styled('div')(() => ({
  display: 'flex',
  gap: '16px',
  justifyContent: 'center',
}));

export const StyledInput = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '20px',
    borderColor: '#D9D9D9',
    minHeight: '40px',
  },

  '& .MuiInputBase-input': {
    padding: '9px 14px',
  },
}));
export const StyledTelInput = styled(MuiTelInput)(() => ({
  '& .MuiTelInput-TextField': {
    borderRadius: '20px',
    borderColor: '#D9D9D9',
    height: '40px',
  },

  '& .MuiOutlinedInput-root': {
    borderRadius: '20px',
  },

  '& .MuiInputBase-input': {
    padding: '9px 14px',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
  },

  '& .MuiTelInput-FlagImg': {
    borderRadius: '20px',
    width: '15px',
    height: '15px',
  },
}));

export const OnboardingPage = () => {
  return (
    <OnboardingProvider>
      <StepManager />
    </OnboardingProvider>
  );
};
