import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { BasicTable } from '../components/BaseTable/BaseTable';
import { CommonContext } from '../contexts/CommonContext';
import Loading from '../components/Loading/Loading';
import EmptyContent from '../components/EmptyContent/EmptyContent';
import { ApiKeysApiClient } from '../api/ApiKeysApiClient';
import { AnyObject } from '../api/anyObjectTypes';
import { useAsyncError } from '../hooks/useAsyncError';
import TextWithCopyButton from '../components/TextWithCopyButton/TextWithCopyButton';
import { NotificationContext } from '../contexts/NotificationContext';
import localize from '../localize';
import { formatDateTime } from '../utils/time';
import { Button, Card, CardContent, CardHeader } from '@mui/material';

const InnerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '30%',
  minWidth: '600px',
}));

export const ApiKeysAddonCard: FC = () => {
  const { currentProject, currentUser } = useContext(CommonContext);
  const throwError = useAsyncError();
  const [rows, setRows] = useState<AnyObject[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { setNotification } = useContext(NotificationContext);

  const create = async (): Promise<void> => {
    setLoading(true);
    await ApiKeysApiClient.create(currentProject.id)
      .catch((e) => {
        throwError(e);
      })
      .finally(() => {
        setNotification({
          severity: 'success',
          message: localize.general.successCreateMessage,
        });
        preparePageData().finally(() => setLoading(false));
      });
  };

  const preparePageData = async (): Promise<void> => {
    const apiRows = await ApiKeysApiClient.getIndex(currentProject.id).catch(
      (e) => {
        throwError(e);
      }
    );

    setRows(apiRows || []);
  };

  useEffect(() => {
    setLoading(true);
    preparePageData().finally(() => setLoading(false));
  }, [currentProject]);

  return (
    <InnerContainer>
      <Card>
        <CardHeader
          title='Ключі доступу'
          titleTypographyProps={{ variant: 'h4' }}
          subheader='Дозволяє отримати АРІ доступ до системи'
          subheaderTypographyProps={{ variant: 'body2' }}
          action={
            <Button
              variant='contained'
              onClick={() => create()}
              disabled={loading}
            >
              Створити
            </Button>
          }
        />
        <CardContent>
          {loading ? (
            <Loading />
          ) : !!rows.length ? (
            <BasicTable
              rows={rows}
              columns={[
                {
                  field: 'id',
                  align: 'left',
                  headerName: 'Ідентифікатор',
                  width: '130px',
                },
                {
                  field: 'access_key',
                  align: 'left',
                  headerName: 'Ключ доступу',
                  renderCell: (row: any) => (
                    <TextWithCopyButton text={row.access_key} />
                  ),
                },
                {
                  field: 'created_at',
                  align: 'right',
                  headerName: 'Дата створення',
                  valueGetter: (row: any) =>
                    row.created_at && formatDateTime(row.created_at),
                },
              ]}
            />
          ) : (
            <EmptyContent
              title='Пусто'
              subtitle='Натисніть на ʼстворитиʼ щоб отримати перший ключ доступу'
            />
          )}
          <br />
        </CardContent>
      </Card>
    </InnerContainer>
  );
};

export default ApiKeysAddonCard;
