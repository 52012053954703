import { FC, useContext, useEffect, useMemo, useState } from 'react';
import Page, { PageAction } from '../../components/Page/Page';
import { Grid } from '@mui/material';
import { fieldsForTypes } from '../../utils/template';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import { preparedColumns } from '../../utils/table';
import { BasicTable } from '../../components/BaseTable/BaseTable';
import { CommonContext } from '../../contexts/CommonContext';
import { UserApiClient } from '../../api/UserApiClient';
import Loading from '../../components/Loading/Loading';
import { NotificationContext } from '../../contexts/NotificationContext';
import { AnyObject } from '../../api/anyObjectTypes';
import localize from '../../localize';

export const baseMainFields = [
  {
    field: 'name',
    headerName: localize.users.columns.name,
    align: 'left',
    type: 'string',
  },
  {
    field: 'discount',
    headerName: localize.users.columns.discount,
    align: 'left',
    type: 'string',
  },
  {
    field: 'role',
    headerName: localize.users.columns.role,
    align: 'left',
    type: 'string',
    valueGetter: (row: any) => localize.users.roles[row.role],
  },
  {
    field: 'manager',
    headerName: localize.users.columns.manager,
    type: 'string',
  },
];

export const UserShowPage: FC = () => {
  const { currentProject, currentUser, templates } = useContext(CommonContext);
  const [item, setItem] = useState<AnyObject>({});
  const [columns, setColumns] = useState<AnyObject[]>(baseMainFields || []);
  const [itemIdToDelete, setItemIdToDelete] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);
  const { setNotification } = useContext(NotificationContext);
  const navigate = useNavigate();
  const { userType, id } = useParams();

  const pageActions = useMemo(() => {
    const editAction = {
      title: localize.users.show.editButton,
      link: `/cp/users/${userType}/edit/${id}`,
    };
    const deleteAction = {
      title: localize.users.show.deleteButton,
      onClick: () => setItemIdToDelete(id),
    };
    const res: PageAction[] = [];

    if (currentUser.user_permissions.user?.update?.includes('all'))
      res.push(editAction);
    if (
      currentUser.user_permissions.user?.destroy?.length &&
      id !== currentUser.auth0_id
    )
      res.push(deleteAction);

    return res;
  }, [item, id, setItemIdToDelete, itemIdToDelete, userType]);

  const preparePageData = async (idToFetch: string): Promise<void> => {
    const res = await UserApiClient.getShow(currentProject.id, idToFetch);
    setItem(res);

    if (userType == 'unknown')
      navigate(
        `/cp/users/${res.role == 'Client' ? 'dropshipers' : 'admins'}/${res.auth0_id}`,
        { replace: true }
      );

    const customFields = fieldsForTypes(templates, ['User']);

    setColumns([...columns, ...preparedColumns(customFields || [])]);
  };

  const deleteItem = () => {
    if (itemIdToDelete)
      UserApiClient.remove(currentProject.id, itemIdToDelete).then(() => {
        setItemIdToDelete(undefined);
        setNotification({
          severity: 'success',
          message: localize.general.successDeleteMessage,
        });
        navigate(`/cp/users`);
      });
  };

  useEffect(() => {
    if (!id) return;

    setLoading(true);
    preparePageData(id).finally(() => setLoading(false));
  }, [id, userType, currentProject]);

  if (loading)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page title={localize.users.show.title} actions={pageActions}>
      <Grid
        container
        spacing={10}
        alignItems='flex-start'
        alignSelf='flex-start'
      >
        <Grid item xs={12}>
          <BasicTable
            rows={[{ ...item, id: item.auth0_id }]}
            columns={columns}
            colorRow
          />
        </Grid>
      </Grid>
      <ConfirmationDialog
        header={localize.users.show.deleteConfirmationHeading}
        open={!!itemIdToDelete}
        description={localize.users.show.deleteConfirmationText}
        action={() => deleteItem()}
        discard={() => setItemIdToDelete(undefined)}
        mainActionButtonText={localize.users.show.deleteConfirmationButtonText}
      />
    </Page>
  );
};

export default UserShowPage;
