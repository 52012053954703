import { useEffect, MutableRefObject } from 'react';

export const useCloseOnClickOutside = (
  elementRef: MutableRefObject<HTMLElement | null>,
  setOpen: (open: boolean, options?: any) => void
) => {
  return useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const element = event.target as HTMLElement;

      if (element.id === 'filter_btn') {
        setOpen(false, true);
        return;
      }

      if (
        elementRef.current &&
        !elementRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setOpen]);
};
