const getRandomHex = (length: number) => {
  const chars = 'abcdef0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
};

export const getRandomHexSegment = () => {
  let result = [
    getRandomHex(8),
    getRandomHex(4),
    getRandomHex(4),
    getRandomHex(4),
    getRandomHex(12),
  ].join('-');

  return result;
};
