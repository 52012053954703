import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  styled,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { UserApiClient } from '../../../api/UserApiClient';
import { CommonContext } from '../../../contexts/CommonContext';
import { useOnboardingMultiStep } from '../../../contexts/OnboardingContext';
import { useAsyncError } from '../../../hooks/useAsyncError';
import localize from '../../../localize';
import {
  ButtonsBox,
  MainDescription,
  MainTitle,
  OnboaringPageContainer,
} from '../OnboardingPage';
import ShowStepNumbers from '../ShowStepNumbers';

const PageInnerContainer = styled('div')(() => ({
  display: 'flex',
  maxWidth: '751px',
  width: '100%',
  margin: '0 auto',
  flexDirection: 'column',
  padding: '0 20px',
  alignItems: 'center',
  justifyContent: 'center',
}));

const HeadingBox = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '24px',
  padding: '0 32px',
}));

const UserForm = styled('form')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'column',
  marginBottom: '54px',
}));

export const DropshipperAdvInfo = () => {
  const { step, getTotalSteps, nextStep, prevStep } = useOnboardingMultiStep();
  const { currentUser } = useContext(CommonContext);
  const throwError = useAsyncError();
  const [advData, setAdvData] = useState<string[]>([]);

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    label: string
  ) => {
    const { checked } = event.target;

    setAdvData((prev) => {
      if (checked) {
        return [...prev, label];
      } else {
        return prev.filter((value) => value !== label);
      }
    });
  };

  const onSubmit = async () => {
    if (advData.length < 1) return nextStep();

    try {
      await UserApiClient.updateSelf(
        {
          user: {
            adv_source: advData.join('; '),
          },
        },
        currentUser.auth0_id
      );

      nextStep();
    } catch (e) {
      throwError(e);
    }
  };

  return (
    <OnboaringPageContainer>
      <PageInnerContainer>
        <ShowStepNumbers currentStep={step} totalSteps={getTotalSteps()} />
        <HeadingBox>
          <MainTitle>{localize.onboarding.advTitle}</MainTitle>
          <MainDescription sx={{ margin: '0' }}>
            {localize.onboarding.advDescription}
          </MainDescription>
          <UserForm sx={{ maxWidth: '510px', padding: '0 10px' }}>
            <FormControl>
              <FormGroup
                sx={{
                  width: '100%',
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                }}
              >
                {(localize.onboarding.advValues as string[]).map((label) => (
                  <FormControlLabel
                    key={label}
                    control={
                      <Checkbox
                        checked={advData.includes(label)}
                        onChange={(event) => handleCheckboxChange(event, label)}
                      />
                    }
                    label={label}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </UserForm>
        </HeadingBox>

        <ButtonsBox>
          <Button
            variant='rounded'
            onClick={onSubmit}
            sx={{
              padding: '14px 44px',
            }}
          >
            {localize.onboarding.indexPage.nextButton}
          </Button>
          <Button variant='transparent' onClick={prevStep}>
            {localize.onboarding.indexPage.prevButton}
          </Button>
        </ButtonsBox>
      </PageInnerContainer>
    </OnboaringPageContainer>
  );
};
