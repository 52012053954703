import { Button, Link, styled, Typography } from '@mui/material';
import localize from '../../../localize';
import freeIcon from '../../../assets/landing/price/free.png';
import standardIcon from '../../../assets/landing/price/standard.png';
import businessIcon from '../../../assets/landing/price/business.png';
import ContactModalImg from '../../../assets/landing/contact-matte.svg';
import { ReactComponent as blueCloud } from '../../../assets/landing/price/blue-cloud.svg';
import { ReactComponent as pinkCloud } from '../../../assets/landing/price/pink-cloud.svg';

import ArrowFigure from '../../../assets/landing/price/arrow-figure.svg';
import { StaticProgressBar } from './StaticProgressBar';
import { useState } from 'react';
import { ContactModal } from './ContactModal';
import { Badge } from './Badge';

const PriceSectionContainer = styled('section')(() => ({
  padding: '3% 0',
  color: '#0c0c0c',
}));

const PriceSectionInner = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 20px',
  justifyContent: 'normal',
  alignItems: 'center',
  width: '1280px',
  maxWidth: '100%',
  minWidth: '700px',
  margin: '0 auto',
}));

const MostPopularWord = styled('span')(() => ({
  fontOpticalSizing: 'auto',
  position: 'absolute',
  top: 'calc(50% - 130px)',
  left: '-40%',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#7B75CB',
  fontStyle: 'normal',
  fontWeight: '400',
  transform: 'rotate(-14deg)',
  fontFamily: '"Caveat", cursive',
  fontSize: '24px',
  lineHeight: '30px',

  '&::after': {
    content: 'url(' + ArrowFigure + ')',
    position: 'relative',
    top: '10px',
    width: '24px',
  },
}));

const PinkCloudSvg = styled(pinkCloud)(() => ({
  position: 'absolute',
  bottom: '0',
  left: '1%',
  width: '411px',
  height: 'auto',
  zIndex: 0,
}));

const BlueCloudSvg = styled(blueCloud)(() => ({
  position: 'absolute',
  top: '10%',
  right: '15%',
  width: '413px',
  height: 'auto',
  zIndex: 0,
}));

const NeedMoreWordGroup = styled('p')(() => ({
  fontOpticalSizing: 'auto',
  color: '#7B75CB',
  fontStyle: 'normal',
  fontWeight: '400',
  fontFamily: '"Caveat", cursive',
  fontSize: '24px',
  lineHeight: '30px',
}));

const PriceTitle = styled('h2')(({ theme }) => ({
  color: '#0c0c0c',
  alignSelf: 'flex-start',
  fontSize: '34px',
  fontWeight: '500',
  marginBottom: '5%',
  fontFamily: '"Montserrat", sans-serif',
}));

const PriceOptionListWrapper = styled('div')(() => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'normal',
  alignItems: 'flex-end',
  width: 'fit-content',
}));

const PriceOptionList = styled('ul')(() => ({
  display: 'flex',
  position: 'relative',
  padding: '24px 0',
  margin: 0,
  flexDirection: 'column',
  minWidth: '410px',
  width: '100%',
  gap: '24px',
  listStyle: 'none',
}));

const PriceOptionCard = styled('li')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: '20px',
  margin: '0 16px',
  padding: '20px 16px',
  backgroundColor: 'rgba(255, 255, 255, 0.3)',
  borderRadius: '24px',
  boxShadow: '0px 4px 20px 0px #C6B7FF4D',
  border: '1px solid #fff',

  '&:nth-of-type(2)': {
    margin: '0',
    border: '1px solid #DFDDF9',
    backgroundColor: '#fff',
  },
}));

const PriceOptionCardHeader = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  fontFamily: '"Montserrat", sans-serif',

  '& .price-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },

  'span.price': {
    color: '#5BB275',
    fontSize: '16px',
    fontWeight: '700',
    margin: '0',
    fontFamily: '"Montserrat", sans-serif',
  },

  '& .crossed-price': {
    textDecoration: 'line-through',

    '& .price': {
      color: 'rgb(12, 12, 12)',
      fontWeight: '400',
    },
  },

  '& .with-badge': {
    display: 'flex',
    alignItems: 'center',
  },
}));

const PriceHeaderLeft = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: '16px',
}));

const PriceHeaderRight = styled('span')(() => ({
  display: 'inline-flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
}));

const PriceOptionCardTitle = styled('h3')(() => ({
  color: '#7B75CB',
  textTransform: 'capitalize',
  alignSelf: 'flex-start',
  fontSize: '20px',
  fontWeight: '600',
  margin: '0',
  fontFamily: '"Montserrat", sans-serif',
}));

const PriceContent = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

const PriceTextContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
}));

const PlansTextGroup = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  rowGap: '46px',
  alignItems: 'flex-start',
}));

const PriceTextActions = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
}));

const PlansSubtitle = styled('h3')(() => ({
  color: '#7B75CB',
  textTransform: 'capitalize',
  alignSelf: 'flex-start',
  fontSize: '24px',
  fontWeight: '600',
  marginBottom: '32px',
  fontFamily: '"Montserrat", sans-serif',
}));

const StyledButtonLink = styled(Button)(({ theme }) => ({
  fontOpticalSizing: 'auto',
  color: '#7B75CB',
  fontStyle: 'normal',
  fontWeight: '400',
  fontFamily: '"Caveat", cursive',
  fontSize: '24px',
  lineHeight: '30px',
  textDecoration: 'underline',
  '&:hover': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    backgroundColor: 'transparent',
  },
}));

const PlansTextList = styled('ul')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '36px',
  maxWidth: '610px',
  listStyle: 'none',
  padding: 0,
  margin: 0,
  marginBottom: '10px',

  '& li': {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },

  '& h4': {
    fontWeight: 700,
    fontSize: '16px',
    color: '#0c0c0c',
    fontFamily: '"Montserrat", sans-serif',
  },

  '& p': {
    fontWeight: 400,
    fontSize: '16px',
    color: '#0c0c0c',
    fontFamily: '"Montserrat", sans-serif',
  },
}));

const benefitsTextList: { title: string; description: string }[] =
  localize.landing.priceSection.benefitItems;

type OptionCardItem = {
  title: string;
  price: string;
  oldPrice?: string;
  description: string;
  period: string | null;
  progressBarPercentage: number;
  progressBarColor: string;
};

const optionsListData: OptionCardItem[] =
  localize.landing.priceSection.optionCardItems;

const optionCardIcons = [
  {
    path: freeIcon,
  },
  {
    path: standardIcon,
  },
  {
    path: businessIcon,
  },
];

const optionListItems: (OptionCardItem & { path: string })[] =
  optionsListData.map((item, index) => ({
    ...item,
    path: optionCardIcons[index].path,
  }));

export const PriceSection = () => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <PriceSectionContainer id='price'>
      <PriceSectionInner>
        <PriceTitle>{localize.landing.priceSection.title}</PriceTitle>
        <PriceContent>
          <PinkCloudSvg />
          <BlueCloudSvg />
          <PriceTextContainer>
            <PlansTextGroup>
              <PlansSubtitle>
                {localize.landing.priceSection.subtitle}
              </PlansSubtitle>
              <PlansTextList>
                {benefitsTextList.map((item) => (
                  <li key={item.title}>
                    <Typography variant='h4'>{item.title}</Typography>
                    <Typography variant='body1' component={'p'}>
                      {item.description}
                    </Typography>
                  </li>
                ))}
              </PlansTextList>
              <PriceTextActions>
                <Button
                  component={Link}
                  href='/price'
                  sx={{ alignSelf: 'flex-start' }}
                  variant='rounded'
                >
                  {localize.landing.priceSection.CTAButton}
                </Button>
                <NeedMoreWordGroup>
                  {localize.landing.priceSection.needMore}{' '}
                  <StyledButtonLink onClick={() => setOpenModal(true)}>
                    {localize.landing.priceSection.contactUs}
                  </StyledButtonLink>
                  <ContactModal
                    open={openModal}
                    handleClose={() => setOpenModal(false)}
                    handleSubmit={() => setOpenModal(false)}
                    title={localize.landing.modal.titleContactUs}
                    imgPath={ContactModalImg}
                  />
                </NeedMoreWordGroup>
              </PriceTextActions>
            </PlansTextGroup>
          </PriceTextContainer>
          <PriceOptionListWrapper>
            <MostPopularWord>
              {localize.landing.priceSection.mostPopular}
            </MostPopularWord>
            <PriceOptionList>
              {optionListItems.map(
                ({
                  title,
                  price,
                  oldPrice,
                  description,
                  period,
                  progressBarPercentage,
                  progressBarColor,
                  path,
                }) => (
                  <PriceOptionCard key={title}>
                    <PriceOptionCardHeader>
                      <PriceHeaderLeft>
                        <PriceOptionCardTitle>{title}</PriceOptionCardTitle>
                        <Typography variant='body1'>{description}</Typography>
                      </PriceHeaderLeft>
                      <PriceHeaderRight>
                        {period ? (
                          <div className='price-container'>
                            <div className='crossed-price'>
                              <span className='price'>{oldPrice}</span>
                              {' / '}
                              <span className='period'>{period}</span>
                            </div>
                            <div className='with-badge'>
                              <Badge>Beta</Badge>
                              <span className='price'>&nbsp;{price}</span>
                              {' / '}
                              <span className='period'>{period}</span>
                            </div>
                          </div>
                        ) : (
                          <span className='price'>{price}</span>
                        )}
                      </PriceHeaderRight>
                    </PriceOptionCardHeader>
                    <StaticProgressBar
                      progress={progressBarPercentage}
                      color={progressBarColor}
                      iconPath={path}
                    />
                  </PriceOptionCard>
                )
              )}
            </PriceOptionList>
          </PriceOptionListWrapper>
        </PriceContent>
      </PriceSectionInner>
    </PriceSectionContainer>
  );
};
