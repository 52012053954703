import { List, ListItem, styled, Typography } from '@mui/material';
import React from 'react';
import localize from '../../../localize';

export const AdvantagesSectionList = () => {
  return (
    <AdvList>
      {localize.landing.advantagesSection.map(
        ({ title, description }: { title: string; description: string }) => (
          <ListItem key={title}>
            <Typography variant='h4'>{title}</Typography>
            <Typography
              variant='body1'
              component={'p'}
              sx={{
                maxWidth: '343px',
                fontFamily: '"Montserrat", sans-serif',
                fontSize: '12px',
              }}
            >
              {description}
            </Typography>
          </ListItem>
        )
      )}
    </AdvList>
  );
};

const AdvList = styled(List)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  h4: {
    fontWeight: 700,
  },
  '& li': {
    marginBottom: theme.spacing(1),
    padding: '0 20px',
    paddingLeft: '5%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    borderLeft: '1px solid #fff',
    gap: '25px',

    '&:first-of-type': {
      paddingLeft: 0,
      borderLeft: 'none',
    },
  },
}));
