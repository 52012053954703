import { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { Paper, Typography } from '@mui/material';
import { useMobile } from '../../hooks/useMobile';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  backgroundColor: 'white',
  borderRadius: '10px',
  padding: '20px',
  alignItems: 'center',
  '& + &': {
    marginLeft: '20px',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
      marginTop: '10px',
    },
  },
}));

const InfoBlock = styled('div')(({ theme }) => ({
  flex: '1 1 auto',
  paddingLeft: '20px',

  [theme.breakpoints.down('sm')]: {
    paddingLeft: '0px',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

type DataLineItem = {
  icon: ReactNode;
  title: string;
  value: ReactNode;
};

type DataLineProps = {
  items: DataLineItem[];
};

export const DataLine: FC<DataLineProps> = ({ items }) => {
  const mobile = useMobile();

  return (
    <Container>
      {items.map((item) => (
        <Item key={item.title} elevation={2}>
          {mobile ? null : item.icon}
          <InfoBlock>
            <Typography variant='body1' color='#8B8B8B'>
              {item.title}
            </Typography>
            <Typography variant='h4' sx={{ marginTop: '5px' }}>
              {item.value}
            </Typography>
          </InfoBlock>
        </Item>
      ))}
    </Container>
  );
};

export default DataLine;
