import { FC } from 'react';
import { DeliveryKeys, Order } from '../../api/OrderApiClient';
import { Typography } from '@mui/material';

type DeliveryDetailsCardProps = {
  order: Order;
};

const deliveryFieldsAndLabels: { [key in DeliveryKeys]: string } = {
  delivery_operator: 'Поштовий оператор',
  delivery_recipient_name: 'Отримувач',
  delivery_recipient_phone: 'Телефон',
  delivery_address: 'Адреса доставки',
  delivery_ttn: 'ТТН',
};

export const DeliveryDetailsCard: FC<DeliveryDetailsCardProps> = ({
  order,
}) => {
  return Object.keys(deliveryFieldsAndLabels).some(
    (el) => order[el as DeliveryKeys]
  ) ? (
    <>
      <br />
      <Typography variant='h4' pb={1}>
        Дані доставки
      </Typography>
      {Object.keys(deliveryFieldsAndLabels).map((el) =>
        order[el as DeliveryKeys] ? (
          <Typography variant='body1'>
            {deliveryFieldsAndLabels[el as DeliveryKeys]}:{' '}
            <b>{order[el as DeliveryKeys]}</b>
          </Typography>
        ) : null
      )}
    </>
  ) : null;
};

export default DeliveryDetailsCard;
