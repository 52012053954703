import { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { BaseApiClient } from '../../api/BaseApiClient';
import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import FormField from '../FormField/FormField';
import DeleteIcon from '@mui/icons-material/Delete';
import Loading from '../Loading/Loading';
import { AnyObject } from '../../api/anyObjectTypes';
import AddIcon from '@mui/icons-material/Add';
import localize from '../../localize';
import { CommonContext } from '../../contexts/CommonContext';

const Container = styled('form')(({ theme }) => ({
  width: '100%',
  maxWidth: '1024px',
}));

const MainItemContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: 'calc(100% - 50px)',
  marginBottom: '20px',
  background: '#FFFFFF',
  borderRadius: '10px',
  padding: '30px',

  '&>div': {
    width: '33%',
    minWidth: '200px',
  },
}));

const SubItemContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',

  '& + &': {
    paddingTop: '20px',
  },
}));

const SubItemTreeIndicator = styled('div')(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.primary.main}`,
  width: '20px',
  maxWidth: '20px',
  minWidth: '20px',
  marginRight: '20px',
  marginTop: '-20px',
  position: 'relative',

  '&:before': {
    content: "''",
    display: 'block',
    width: '7px',
    position: 'absolute',
    right: '-4px',
    top: 'calc(50% + 8px)',
    height: '7px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },

  '&:after': {
    content: "''",
    display: 'block',
    width: '20px',
    maxWidth: '20px',
    minWidth: '20px',
    position: 'absolute',
    right: '-22px',
    top: 'calc(50% + 11px)',
    height: '1px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const SubItemInnerContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  flex: '1 1 auto',
  background: '#fff',
  borderRadius: '10px',
  padding: '30px',

  '& + &': {
    marginTop: '20px',
  },

  '&>div': {
    width: '33%',
    minWidth: '200px',
  },
}));

const SubItemRemove = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '10px',
  width: '50px',
  minWidth: '50px',
  maxWidth: '50px',
}));

const AddSubItemContainer = styled('div')(({ theme }) => ({
  paddingTop: '50px',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',

  '&:before': {
    content: "''",
    borderRight: `1px solid ${theme.palette.primary.main}`,
    display: 'block',
    position: 'absolute',
    width: '20px',
    top: '0',
    height: '52px',
  },
}));

const AddSubItemNote = styled('div')(({ theme }) => ({
  marginLeft: '10px',
  color: '#8B8B8B',
  fontStyle: 'italic',
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: 'white',
  marginLeft: '2px',

  '&:hover': {
    background: theme.palette.primary.dark,
  },
}));

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: '20px',
  padding: '10px 50px 10px 10px',
  justifyContent: 'flex-end',
}));

type TemplateFormProps = {
  template: AnyObject;
  submitting?: boolean;
  description?: ReactNode;
  onSubmit(customFields: AnyObject[]): void;
};

export const TemplateForm: FC<TemplateFormProps> = ({
  submitting,
  template,
  description,
  onSubmit,
}) => {
  const [defaultFields, setDefaultFields] = useState<AnyObject[]>([]);
  const [items, setItems] = useState<AnyObject[]>([]);

  useEffect(() => {
    if (!template) return;

    setItems(template.custom_fields);

    setDefaultFields(
      [
        {
          internal_name: 'field_type',
          field_type: 'string',
          required: true,
          label: 'Тип поля',
          options: [
            'text',
            'string',
            'number',
            'boolean',
            'select',
            'static',
            'files',
          ],
        },
        {
          internal_name: 'label',
          field_type: 'string',
          required: true,
          label: 'Назва поля',
        },
        {
          internal_name: 'internal_name',
          field_type: 'string',
          required: true,
          label: 'Ключ поля',
        },
        {
          internal_name: 'required',
          field_type: 'boolean',
          required: false,
          label: 'Обовʼязкове',
        },
      ].map((el) => ({
        ...el,
        editable: true,
        hidden: false,
        parsable: false,
      }))
    );
  }, [template]);

  const handleItemInputChange = (
    e: { target: { name: any; value: any } },
    itemIndex: number
  ) => {
    const { name, value } = e.target;
    const newSubItems = items.slice();
    newSubItems[itemIndex] = { ...newSubItems[itemIndex], [name]: value };
    setItems(newSubItems);
  };

  const deleteSubItem = (itemIndex: number) => {
    const newSubItems = items.slice();
    newSubItems[itemIndex]['_destroy'] = true;
    setItems(newSubItems);
  };

  return (
    <Container
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(items);
      }}
    >
      <MainItemContainer>{description}</MainItemContainer>
      {items.map((item, i) => {
        if (item['_destroy']) return null;

        return (
          <SubItemContainer key={`${item.id}-${i}`}>
            <SubItemTreeIndicator />
            <SubItemInnerContainer elevation={2}>
              {defaultFields.map((df) => (
                <FormField
                  key={`${item.id}-${i}-${df.internal_name}`}
                  value={item[df.internal_name] || ''}
                  label={df.label}
                  name={df.internal_name}
                  required={df.required}
                  fieldType={df.field_type}
                  options={df.options}
                  localizedField={df.localized_field}
                  disabled={item.hidden}
                  onChange={(e) => handleItemInputChange(e, i)}
                />
              ))}
              {item.field_type === 'select' ? (
                <FormField
                  key={`${item.id}-${i}-${'options'}`}
                  value={
                    Array.isArray(item['options'])
                      ? item['options']?.join('\n')
                      : item['options'] || ''
                  }
                  label={'Опції'}
                  name={'options'}
                  required={true}
                  fieldType='text'
                  onChange={(e) => handleItemInputChange(e, i)}
                />
              ) : null}
              {item.field_type === 'static' ? (
                <FormField
                  key={`${item.id}-${i}-${'default_value'}`}
                  value={item['default_value'] || ''}
                  label={'Значення'}
                  name={'default_value'}
                  required={true}
                  fieldType={'string'}
                  onChange={(e) => handleItemInputChange(e, i)}
                />
              ) : null}
            </SubItemInnerContainer>
            <SubItemRemove>
              {items.filter((item) => !item['_destroy']).length > 1 ? (
                <IconButton
                  size='small'
                  onClick={(): void => {
                    deleteSubItem(i);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              ) : null}
            </SubItemRemove>
          </SubItemContainer>
        );
      })}

      <AddSubItemContainer>
        <StyledIconButton
          aria-label='expand row'
          size='small'
          onClick={() => setItems([...items, {}])}
        >
          <AddIcon />
        </StyledIconButton>
        <AddSubItemNote>
          <Typography variant='body1'>
            {localize.templates.form.addCustomField}
          </Typography>
        </AddSubItemNote>
      </AddSubItemContainer>
      <Actions>
        <Button
          size='large'
          type='submit'
          variant='contained'
          disabled={submitting}
        >
          {submitting ? (
            <CircularProgress size={26} thickness={6} color='primary' />
          ) : (
            localize.general.submit
          )}
        </Button>
      </Actions>
    </Container>
  );
};

export default TemplateForm;
