import { AnyObject } from './anyObjectTypes';
import { get, post, put } from './request';

export type StaticDataRecord = {
  //:json_data, :files_data, :signed_files_data, :updated_at
};

export class StaticDataRecordsApiClient {
  // якщо витягуєш дані тут і нема жодного рекорду, тоді при зберіганні будеш робити запит на create
  // якщо є хоч рекорд, то запит має йти на апдейт цього рекорду - не можна створювати ще один рекорд, треба просто апдейтити попередній
  // тобто бекенд в теорії дозволяє більш ніж один набір статичних даних, але по факту цього робити не треба,
  // я потім додам перевірку на бек яка не буде давати можливості створювати більш ніж один набір статичних даних
  static getIndex(projectId: string): Promise<AnyObject[]> {
    return get(`projects/${projectId}/static_data_records`);
  }

  static update(
    projectId: string,
    requestBody: any,
    itemId: string
  ): Promise<AnyObject> {
    return put(
      `projects/${projectId}/static_data_records/${itemId}`,
      requestBody
    );
  }

  static create(projectId: string, requestBody: any): Promise<AnyObject> {
    return post(`projects/${projectId}/static_data_records`, requestBody);
  }
}
