import { FC, useContext, useState, useRef, useMemo } from 'react';

import {
  Avatar,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { CommonContext } from '../../contexts/CommonContext';
import localize from '../../localize';
import SideBarListItem from '../SideBarListItem/SideBarListItem';
import { projectsNavItems } from '../../utils/navigationLinks';
import { useCloseOnClickOutside } from '../../hooks/useCloseOnClickOutside';

import logo from '../../assets/logoIcon.svg';

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
}));

const ProjectButton = styled(ListItemButton)(({ theme }) => ({
  minHeight: 82,
  width: 228,
  p: 2.5,
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#fff',
  borderRadius: '8px',
  boxShadow: '4px 8px 32px 0px #5043CF14',
  margin: '0 auto',
  border: '2px solid transparent',
  '&.Mui-focusVisible, &.Mui-selected, &.active, &:hover': {
    border: '2px solid #9B96EB',
    backgroundColor: '#fff',
  },
}));

const ProjectMenu = styled(List)(() => ({
  width: 228,
  padding: '4px',
  background: '#fff',
  borderRadius: '8px',
  border: '1px solid #AFABEF',
  position: 'absolute',
  zIndex: 10,
  top: '100%',
  left: '15px',
  '&.inner-items': {
    top: '0',
    left: '105%',
  },
  '&>a, &>li': {
    paddingLeft: '20px',
  },
}));

export const ProjectMenuItems: FC = () => {
  const { currentUser, currentProject, switchProject, projects } =
    useContext(CommonContext);
  const [openProjectsMenu, setOpenProjectsMenu] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const canAccessSettings = useMemo(
    () =>
      !['client', 'manager'].includes(currentUser?.user_permissions?.role_name),
    [currentUser?.user_permissions]
  );

  const closeMenu = (open?: boolean) => {
    setOpenProjectsMenu(!!open);
    const sidebar = document.getElementById('sidebar');
    sidebar && sidebar.classList.remove('visible');
  };

  useCloseOnClickOutside(menuRef, closeMenu);

  return (
    <Container ref={menuRef}>
      <List component='nav'>
        <ProjectButton
          className={openProjectsMenu ? 'active' : ''}
          onClick={() => {
            setOpenProjectsMenu((prev) => !prev);
            const sidebar = document.getElementById('sidebar');

            sidebar &&
              (!openProjectsMenu
                ? sidebar.classList.add('visible')
                : sidebar.classList.remove('visible'));
          }}
        >
          <ListItemAvatar
            sx={{
              width: '58px',
              height: '58px',
              mr: '5px',
            }}
          >
            <Avatar
              src={currentProject.logo_url || logo}
              variant='rounded'
              sx={{ width: '100%', height: '100%' }}
            />
          </ListItemAvatar>
          <ListItemText
            primary={currentProject.title}
            secondary={localize.users.roles[currentProject.user_role]}
            primaryTypographyProps={{
              fontFamily: 'Montserrat, sans-serif',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '17.07px',
              color: '#0C0C0C',
              mb: '5px',
            }}
            secondaryTypographyProps={{
              fontFamily: 'Montserrat, sans-serif',
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: '14.63px',
              color: '#464646',
            }}
          />
        </ProjectButton>
      </List>
      {openProjectsMenu && (
        <ProjectMenu>
          {projectsNavItems.map((item) => {
            if (item.name === 'projects_settings' && !canAccessSettings)
              return undefined;
            return (
              <SideBarListItem
                key={item.name}
                item={item}
                setSidebarOpen={setOpenProjectsMenu}
                className='inner-item'
                innerItems={
                  item.name === 'switch_projects' ? (
                    <ProjectMenu className='inner-items'>
                      {projects.map((item) => (
                        <ListItemButton
                          key={item.id}
                          sx={{
                            padding: '4px 12px',
                            minHeight: 32,
                            borderRadius: '8px',
                            cursor: 'pointer',
                          }}
                          selected={item.id === currentProject.id}
                          onClick={() =>
                            item.id !== currentProject.id
                              ? switchProject(item.id)
                              : closeMenu()
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: 1,
                              justifyContent: 'center',
                              color: '',
                            }}
                          >
                            <Avatar
                              src={item.logo_url || logo}
                              variant='rounded'
                              sx={{ width: 20, height: 20 }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={item.title}
                            primaryTypographyProps={{
                              variant: 'subtitle2',
                              color: '#464646',
                            }}
                          />
                        </ListItemButton>
                      ))}
                    </ProjectMenu>
                  ) : undefined
                }
              />
            );
          })}
        </ProjectMenu>
      )}
    </Container>
  );
};

export default ProjectMenuItems;
