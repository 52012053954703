import { get, destroy, post, put } from './request';
import { AnyObject } from './anyObjectTypes';

export type Stock = {
  [key: string]: any;
};

export class StockApiClient {
  static getIndex(projectId: string): Promise<Stock[]> {
    return get(`projects/${projectId}/stocks`);
  }

  static getShow(projectId: string, itemId: string): Promise<Stock> {
    return get(`projects/${projectId}/stocks/${itemId}`);
  }

  static remove(projectId: string, itemId: string): Promise<string> {
    return destroy(`projects/${projectId}/stocks/${itemId}`);
  }

  static create(projectId: string, requestBody: AnyObject): Promise<Stock> {
    return post(`projects/${projectId}/stocks`, requestBody);
  }

  static update(
    projectId: string,
    requestBody: AnyObject,
    itemId: string
  ): Promise<Stock> {
    return put(`projects/${projectId}/stocks/${itemId}`, requestBody);
  }
}
