import { get, destroy, post, put } from './request';
import { AnyObject } from './anyObjectTypes';

export type ProductModification = {
  id: string;
  price: string;
  title: string;
  additional_fields: AnyObject;
};

export type Project = {
  logo: string;
  logo_url: string;
  id: string;
  title: string;
};

export class ProjectApiClient {
  static getIndex(): Promise<Project[]> {
    return get(`projects`);
  }

  static getShow(projectId: string): Promise<Project> {
    return get(`projects/${projectId}`);
  }

  static remove(projectId: string): Promise<string> {
    return destroy(`projects/${projectId}`);
  }

  static create(requestBody: AnyObject): Promise<Project> {
    return post(`projects`, requestBody);
  }

  static update(projectId: string, requestBody: AnyObject): Promise<Project> {
    return put(`projects/${projectId}`, requestBody);
  }
}
