import { FC, useMemo } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { InfoOutlined as InfoIcon } from '@mui/icons-material';

type IconWithInfoProps = {
  text: string;
  icon?: typeof InfoIcon;
};

export const IconWithInfo: FC<IconWithInfoProps> = ({ text, icon }) => {
  const Icon = useMemo<typeof InfoIcon>(() => icon || InfoIcon, [icon]);
  return (
    <Tooltip title={text} placement='top'>
      <IconButton sx={{ padding: 0 }}>
        <Icon fontSize='small' />
      </IconButton>
    </Tooltip>
  );
};

export default IconWithInfo;
