import { styled } from '@mui/material';

export const Badge = styled('span')(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'fit-content',
  borderRadius: '20px',
  padding: '4px 16px',
  flexShrink: 0,
  backgroundColor: '#C8FFD7',
  color: '#464646',
  fontSize: '14px',
  fontWeight: 500,
  fontFamily: '"Montserrat", sans-serif',
}));
