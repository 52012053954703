import { FC, useEffect } from 'react';
import {
  Card,
  CardContent,
  Box,
  Grid,
  Typography,
  Button,
  Link,
} from '@mui/material';
import FormField from '../../components/FormField/FormField';
import { Customer } from '../../api/CustomerApiClient';
import { Delivery } from '../../api/OrderApiClient';
import { styled } from '@mui/material/styles';

type DeliveryFieldsProps = {
  customer?: Customer;
  delivery?: Delivery;
  setDelivery(user: Delivery): void;
};

const DataFillContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  minWidth: '100%',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '&>*': {
      width: '100%',
      maxWidth: '100%',
    },
  },
}));

export const DeliveryFields: FC<DeliveryFieldsProps> = ({
  customer,
  delivery,
  setDelivery,
}) => {
  const fillFromCustomerData = () => {
    if (!customer?.name && !customer?.phone_number) return;

    setDelivery({
      ...delivery,
      delivery_recipient_name: customer?.name,
      delivery_recipient_phone: customer?.phone_number,
    });
  };

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                minWidth: '100%',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  minWidth: '100%',
                  flexDirection: 'column',
                  p: 1,
                }}
              >
                <Typography variant='h4'>
                  Дані отримувача (необовʼязково)
                </Typography>
                {customer?.name || customer?.phone_number ? (
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      fillFromCustomerData();
                    }}
                  >
                    заповнити з даних покупця
                  </Link>
                ) : null}
              </Box>
              <DataFillContainer>
                <FormField
                  sx={{ flex: '1 1 auto', maxWidth: '100%' }}
                  value={delivery?.delivery_recipient_name || ''}
                  label='Імʼя отримувача'
                  fieldType='string'
                  name='delivery_recipient_name'
                  onChange={(e) =>
                    setDelivery({
                      ...(delivery || {}),
                      delivery_recipient_name: e.target.value,
                    })
                  }
                />
                <FormField
                  sx={{ maxWidth: '200px', width: '100%' }}
                  value={delivery?.delivery_recipient_phone || ''}
                  label='Телефон отримувача'
                  fieldType='string'
                  name='delivery_recipient_phone'
                  onChange={(e) =>
                    setDelivery({
                      ...(delivery || {}),
                      delivery_recipient_phone: e.target.value,
                    })
                  }
                />
              </DataFillContainer>
              <Box sx={{ display: 'flex', minWidth: '100%', p: 1 }}>
                <Typography variant='h4'>
                  Дані доставки (необовʼязково)
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', minWidth: '100%' }}>
                <FormField
                  sx={{ flex: '1 1 auto', maxWidth: '100%' }}
                  value={delivery?.delivery_operator || ''}
                  options={['Нова Пошта', 'Інший']}
                  label='Оператор доставки'
                  name='delivery_operator'
                  onChange={(e) =>
                    setDelivery({
                      ...(delivery || {}),
                      delivery_operator: e.target.value,
                    })
                  }
                />
              </Box>
              <DataFillContainer>
                <FormField
                  sx={{ flex: '1 1 auto', maxWidth: '100%' }}
                  value={delivery?.delivery_address || ''}
                  label='Адреса доставки'
                  fieldType='string'
                  name='delivery_address'
                  onChange={(e) =>
                    setDelivery({
                      ...(delivery || {}),
                      delivery_address: e.target.value,
                    })
                  }
                />
                <FormField
                  sx={{ flex: '1 1 auto', maxWidth: '100%' }}
                  value={delivery?.delivery_ttn || ''}
                  label='ТТН'
                  fieldType='string'
                  name='delivery_ttn'
                  onChange={(e) =>
                    setDelivery({
                      ...(delivery || {}),
                      delivery_ttn: e.target.value,
                    })
                  }
                />
              </DataFillContainer>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default DeliveryFields;
