import { get } from './request';

export type AuditableType = 'Order' | 'OrderItem';
export type AuditableAction = 'create' | 'update' | 'destroy';

export type Audit = {
  id: string;
  user: {
    auth0_id: string;
    friendly_id: string;
    name: string;
  };
  changes: { [key: string]: string[] | string };
  created_at: string;
  auditable_type: AuditableType;
  request_uuid: string;
  action: AuditableAction;
};

export class AuditsApiClient {
  static getIndex(
    projectId: string,
    resourceType: string,
    resourceId: string
  ): Promise<Audit[]> {
    return get(
      `projects/${projectId}/audits?resource_type=${resourceType}&resource_id=${resourceId}`
    );
  }

  static getShow(projectId: string, id: string): Promise<Audit> {
    return get(`projects/${projectId}/audits/${id}`);
  }
}
