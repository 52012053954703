import { Button, styled } from '@mui/material';
import React, { useState } from 'react';
import localize from '../../../localize';
import Saly2 from '../../../assets/landing/salySecond.svg';
import doodle1 from '../../../assets/landing/doodle1.png';
import doodle3 from '../../../assets/landing/doodle3.png';
import { ContactModal } from './ContactModal';
import CommentPurpleImg from '../../../assets/landing/comment-matte.svg';

const AboutUsSectionInner = styled('div')(() => ({
  display: 'flex',
  padding: '6% 20px 2% 0',
  position: 'relative',
  paddingTop: '6%',
  justifyContent: 'normal',
  overflowX: 'visible',
  alignItems: 'center',
  columnGap: '48px',
  marginTop: '0',
  background: `
  url(${doodle1}) 40% 0 no-repeat,
  url(${doodle3}) 3% 100% no-repeat`,
  width: '1280px',
  maxWidth: '100%',
  minWidth: '700px',
  marginRight: 'auto',
}));

const AboutUsSectionContainer = styled('section')(({ theme }) => ({
  position: 'relative',
  padding: '6% 0',
  color: '#0c0c0c',
  overflowX: 'hidden',
}));

const RoundedBg = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '274px',
  minWidth: '500px',

  '&::after': {
    content: "' '",
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    width: '100vw',
    backgroundColor: '#DFDDF9',
    borderTopRightRadius: '24px',
    borderBottomRightRadius: '24px',
    zIndex: '-1',
  },
}));

const RoudedBgInnerImg = styled('img')(() => ({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  bottom: '-4%',
}));

const AboutUsContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '40px',

  '& p': {
    margin: '0',
  },

  '& p.about-cta': {
    fontWeight: 500,
    color: '#464646',
  },

  '& span.first-word': {
    fontWeight: 500,
    color: '#7B75CB',
    fontSize: '18px',
  },
}));

export const AboutUsSection = () => {
  const [openModal, setOpenModal] = useState(false);
  const description: string = localize.landing.aboutSection.description;
  const firstWord: string = description.split(' ')[0];
  const restOfDescription: string =
    ' ' + description.split(' ').slice(1).join(' ');

  return (
    <AboutUsSectionContainer id='about'>
      <AboutUsSectionInner>
        <RoundedBg>
          <RoudedBgInnerImg src={Saly2} alt='saly' />
        </RoundedBg>
        <AboutUsContent>
          <p>
            <span className='first-word'>{firstWord}</span>
            <span className='rest-of-description'>{restOfDescription}</span>
          </p>
          <p className='about-cta'>{localize.landing.aboutSection.CTA}</p>

          <Button
            variant='rounded'
            onClick={() => setOpenModal(true)}
            sx={{ width: 'fit-content' }}
          >
            {localize.landing.aboutSection.CTAButton}
          </Button>
          <ContactModal
            open={openModal}
            description={localize.landing.modal.consultationDescription}
            handleClose={() => setOpenModal(false)}
            handleSubmit={() => setOpenModal(false)}
            title={localize.landing.modal.titleConsultation}
            imgPath={CommentPurpleImg}
            note={localize.landing.modal.consultationFormNoteText}
          />
        </AboutUsContent>
      </AboutUsSectionInner>
    </AboutUsSectionContainer>
  );
};
