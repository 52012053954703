import { get, destroy, post, put } from './request';
import { AnyObject } from './anyObjectTypes';

export type OrderComment = {
  id: string;
  comment: string;
  user_name: string;
  user_id: string;
  visibility: 'self' | 'management' | 'all';
  created_at: string;
  updated_at?: string;
};

export type DeliveryKeys =
  | 'delivery_operator'
  | 'delivery_recipient_name'
  | 'delivery_recipient_phone'
  | 'delivery_address'
  | 'delivery_ttn';

export type Delivery = {
  delivery_operator?: string;
  delivery_recipient_name?: string;
  delivery_recipient_phone?: string;
  delivery_address?: string;
  delivery_ttn?: string;
};

export class OrderCommentApiClient {
  static getIndex(projectId: string, orderId: string): Promise<OrderComment[]> {
    return get(`projects/${projectId}/orders/${orderId}/order_comments`);
  }

  static remove(
    projectId: string,
    orderId: string,
    itemId: string
  ): Promise<string> {
    return destroy(
      `projects/${projectId}/orders/${orderId}/order_comments/${itemId}`
    );
  }

  static create(
    projectId: string,
    orderId: string,
    requestBody: AnyObject
  ): Promise<OrderComment> {
    return post(
      `projects/${projectId}/orders/${orderId}/order_comments`,
      requestBody
    );
  }

  static update(
    projectId: string,
    orderId: string,
    requestBody: AnyObject,
    itemId: string
  ): Promise<OrderComment> {
    return put(
      `projects/${projectId}/orders/${orderId}/order_comments/${itemId}`,
      requestBody
    );
  }
}
