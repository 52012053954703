import { FC, useContext, useEffect, useState } from 'react';
import Page from '../../components/Page/Page';
import { Grid } from '@mui/material';
import { fieldsForTypes } from '../../utils/template';
import { useParams } from 'react-router-dom';
import { preparedColumns } from '../../utils/table';
import { BasicTable } from '../../components/BaseTable/BaseTable';
import { CommonContext } from '../../contexts/CommonContext';
import { ManufactureRequestApiClient } from '../../api/ManufactureRequestApiClient';
import Loading from '../../components/Loading/Loading';
import { AnyObject } from '../../api/anyObjectTypes';
import localize from '../../localize';

export const baseMainFields = [
  {
    field: 'count',
    headerName: localize.manufacture_requests.columns.count,
    type: 'number',
  },
  {
    field: 'user_name',
    headerName: localize.manufacture_requests.columns.user_name,
    type: 'string',
  },
];

export const ManufactureRequestShowPage: FC = () => {
  const { id } = useParams();
  const { currentProject, templates } = useContext(CommonContext);
  const [item, setItem] = useState<AnyObject>({});
  const [productColumns, setProductColumns] = useState<AnyObject[]>([]);
  const [productModificationColumns, setProductModificationColumns] = useState<
    AnyObject[]
  >([]);
  const [loading, setLoading] = useState(true);

  const preparePageData = async (id: string): Promise<void> => {
    const res = await ManufactureRequestApiClient.getShow(
      currentProject.id,
      id
    );
    setItem(res);

    const customProductFields = fieldsForTypes(templates, ['Product']);

    setProductColumns([...preparedColumns(customProductFields || [])]);

    const customProductModificationFields = fieldsForTypes(templates, [
      'Product modification',
    ]);

    setProductModificationColumns([
      ...preparedColumns(customProductModificationFields || []),
    ]);
  };

  useEffect(() => {
    if (!id) return;

    setLoading(true);
    preparePageData(id).finally(() => setLoading(false));
  }, [id, currentProject]);

  if (loading)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page title={localize.manufacture_requests.show.title}>
      <Grid
        container
        spacing={10}
        alignItems='flex-start'
        alignSelf='flex-start'
      >
        <Grid item xs={12}>
          <BasicTable rows={[{ ...item }]} columns={baseMainFields} colorRow />
        </Grid>
        <Grid item xs={12}>
          <BasicTable
            rows={[{ ...item.product }]}
            columns={productColumns}
            colorRow
          />
        </Grid>
        <Grid item xs={12}>
          <BasicTable
            rows={[{ ...item.product_modification }]}
            columns={productModificationColumns}
            colorRow
          />
        </Grid>
      </Grid>
    </Page>
  );
};

export default ManufactureRequestShowPage;
