import { get, destroy, post, put } from './request';
import { AnyObject } from './anyObjectTypes';

export type Permissions = {
  create: string[];
  destroy: string[];
  read: string[];
  update: string[];
};

export type UserPermissionsResponse = {
  role_name: 'owner' | 'administrator' | 'staff' | 'client' | 'manager';
  user: Permissions;
  product: Permissions;
};

export type User = {
  discount?: number;
  id?: string;
  email?: string;
  telegram_username?: string;
  chat_id?: string;
  locale: string;
  phone_number?: string;
  auth0_id: string;
  invitation_url: string;
  manager: string;
  manager_id: string;
  name: string;
  role: string;
  user_permissions: UserPermissionsResponse;
  user_role_id: string;
};

export class UserApiClient {
  static getIndex(projectId: string): Promise<User[]> {
    return get(`projects/${projectId}/users`);
  }

  static getShow(projectId: string, itemId: string): Promise<User> {
    return get(`projects/${projectId}/users/${itemId}`);
  }

  static getShowSelf(itemId: string): Promise<User> {
    return get(`users/${itemId}`);
  }

  static remove(projectId: string, itemId: string): Promise<string> {
    return destroy(`projects/${projectId}/users/${itemId}`);
  }

  static create(projectId: string, requestBody: AnyObject): Promise<User> {
    return post(`projects/${projectId}/users`, requestBody);
  }

  static update(
    projectId: string,
    requestBody: AnyObject,
    itemId: string
  ): Promise<User> {
    return put(`projects/${projectId}/users/${itemId}`, requestBody);
  }

  static updateSelf(requestBody: AnyObject, itemId: string): Promise<User> {
    return put(`users/${itemId}`, requestBody);
  }
}
