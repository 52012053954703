import { FC } from 'react';
import { Order } from '../../api/OrderApiClient';
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  Link,
  Button,
} from '@mui/material';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import { useNavigate } from 'react-router-dom';

type FilesCardProps = {
  order: Order;
};

export const FilesCard: FC<FilesCardProps> = ({ order }) => {
  const navigate = useNavigate();

  return (
    <Card sx={{ border: '1px solid #DFDDF9', borderRadius: '8px' }}>
      <CardHeader
        title='Файли'
        titleTypographyProps={{ variant: 'h4' }}
        subheader='Завантажені файли'
        subheaderTypographyProps={{ variant: 'body2' }}
        action={
          order.editable_by_current_user && (
            <Button
              variant='outlined'
              onClick={() => navigate(`/cp/orders/${order.id}/edit/files`)}
            >
              Редагувати
            </Button>
          )
        }
      />
      <CardContent>
        <List>
          {order.files?.length ? (
            order.files.map((el: any) => (
              <ListItem key={el.url} disablePadding>
                <Link href={el.url} target='_blank'>
                  {el.name}
                </Link>
              </ListItem>
            ))
          ) : (
            <EmptyContent title='' subtitle='Немає файлів' />
          )}
        </List>
      </CardContent>
    </Card>
  );
};

export default FilesCard;
