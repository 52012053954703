import React, { useEffect, useRef } from 'react';
import JSONEditor, { JSONEditorOptions } from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';
import './JSONEditor.css';

interface JsonEditorWrapperProps {
  jsonData: object;
  onChange?: (updatedJson: object) => void;
  mode?: 'code' | 'tree';
}

const JsonEditorWrapper: React.FC<JsonEditorWrapperProps> = ({
  jsonData,
  onChange,
  mode = 'code',
}) => {
  const editorRef = useRef<JSONEditor | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      const options: JSONEditorOptions = {
        mode: mode,
        onChange: () => {
          try {
            const updatedJson = editorRef.current?.get();
            if (updatedJson && onChange) onChange(updatedJson);
          } catch (err) {
            console.error('Invalid JSON format:', err);
          }
        },
      };

      editorRef.current = new JSONEditor(containerRef.current, options);

      if (jsonData) {
        editorRef.current.set(jsonData);
      }
    }

    return () => {
      if (editorRef.current) {
        editorRef.current.destroy();
      }
    };
  }, [jsonData, mode]);

  return <div ref={containerRef} style={{ height: '400px' }} />;
};

export default JsonEditorWrapper;
