export const locale = localStorage.getItem('locale') || 'ua';
const loc = {
  ua: {
    errors: {
      unknownError: 'Невідома помилка.',
      notFoundError: 'Не знайдено.',
      pleaseTryAgain: 'Cпробуйте, будь ласка, ще раз.',
      contactUs:
        'Якщо помилка повториться сконтактуйте з нами support@factorywise.com.ua',
    },
    general: {
      filterAction: 'пошук & фільтр',
      from: 'від',
      to: 'до',
      soon: 'Скоро',
      files: 'Виберіть файли',
      files_dropzone: 'Перетягніть сюди',
      or: 'або',
      save: 'Зберегти',
      submit: 'Зберегти',
      edit: 'Редагувати',
      cancel: 'Скасувати',
      goBack: 'назад',
      true: 'Так',
      false: 'Ні',
      successMessage: 'Дія успішно виконана',
      successUpdateMessage: 'Дані успішно оновлено',
      warningUpdateMessage:
        'Запис створений оновлено, але відповідь від сервера була з помилкою. Перевірте, будь ласка, чи всі дані збереглись коректно',
      successCreateMessage: 'Запис створений успішно',
      warningCreateMessage:
        'Запис створений успішно, але відповідь від сервера була з помилкою. Перевірте, будь ласка, чи всі дані збереглись коректно',
      successDeleteMessage: 'Запис видалений успішно',
      unexpectedError: 'Неочікувана помилка',
      startTyping: 'Почніть вводити...',
      typeNumber: 'Введіть число',
      time: {
        short: {
          year: 'р.',
          month: 'м.',
          hour: 'год.',
          day: 'д.',
          minute: 'хв.',
          second: 'с.',
        },
        long: {
          year: 'рік',
          years: 'роки',
          month: 'місяць',
          months: 'місяці',
          hour: 'година',
          hours: 'години',
          day: 'день',
          days: 'дні',
          minute: 'хвилина',
          minutes: 'хвилини',
          second: 'секунда',
          seconds: 'секунди',
        },
        ago: 'тому',
      },
    },
    filter: {
      title: 'Фільтр',
      reset: 'Очистити',
      apply: 'Застосувати',
    },
    pageMenu: {
      options: 'дії',
    },
    landing: {
      loginButton: 'Вхід',
      successSentMessage: 'Дані успішно відпралено!',
      signUpButton: 'Реєстрація',
      heading: 'Разом до',
      headingWord: 'успіху',
      subText:
        "Вітаємо у FactoryWise, де виробники та дропшипери об'єднуються для досягнення нових вершин бізнесу.",
      headCTA: 'Спробуй безкоштовно',
      navLinks: {
        about: 'Про нас',
        advantages: 'Особливості',
        price: 'Прайс',
      },
      advantagesSection: [
        {
          title: 'Зростання ринку',
          description:
            'За даними останніх досліджень, ринок дропшипінгу в світі виріс на 23% за останні 5 років, досягнувши $162.44 мільярдів доларів.',
        },
        {
          title: 'Управління запасами',
          description:
            'Виробники, які використовують спеціалізовані платформи для управління запасами, зазвичай знижують свої операційні витрати на 20% і підвищують ефективність роботи на 30%.',
        },
        {
          title: 'Переваги для малих бізнесів',
          description:
            'Малі підприємства отримують численні переваги від дропшипінгу, включаючи низькі стартові витрати, мінімальні ризики та можливість масштабування без значних інвестицій.',
        },
      ],
      aboutSection: {
        description:
          "FactoryWise — це портал, що об'єднує виробників та дропшиперів. Виробники можуть реєструвати свої продукти, запрошувати менеджерів та керувати запасами. Дропшипери отримують доступ до широкого асортименту товарів, які вони можуть пропонувати своїм клієнтам. Наша платформа створена для оптимізації бізнес-процесів та підвищення продуктивності.",
        CTA: 'Цікавитесь, як наші рішення можуть допомогти вашому бізнесу? Запишіться на безкоштовну консультацію.',
        CTAButton: 'Консультація',
      },
      offersSection: {
        title: 'Ми пропонуємо',
        items: [
          {
            title: 'Простота',
            description:
              'Легка реєстрація та початок роботи без складних процедур. Заощаджуйте час і зусилля, зосереджуючись на своєму бізнесі.',
          },
          {
            title: 'Інтеграція',
            description:
              'API для безшовної інтеграції вашого вебсайту з платформою FactoryWise. Забезпечте плавну синхронізацію даних та управління замовленнями.',
          },
          {
            title: 'Статистика',
            description:
              'Отримуйте важливі аналітичні дані для прийняття обґрунтованих рішень. Підвищуйте продуктивність та ефективність вашого бізнесу.',
          },
          {
            title: 'Гнучкість',
            description:
              'Безкоштовні та преміум-плани для різних потреб. Вибирайте пакет, який найкраще відповідає вашому бізнесу та масштабам.',
          },
        ],
      },
      featuresSection: {
        title: 'Особливості',
        items: [
          {
            title: 'Інвентаризація',
            description:
              'Керуйте запасами легко та ефективно. Наші інструменти допоможуть вам підтримувати актуальний стан запасів.',
          },
          {
            title: 'Замовлення',
            description:
              'Відстежуйте замовлення від отримання до доставки. Забезпечте своєчасне виконання та задоволення клієнтів.',
          },
          {
            title: 'Комунікація',
            description:
              'Гладка комунікація між виробниками та дропшиперами. Спрощуйте співпрацю та забезпечуйте ефективний обмін інформацією.',
          },
          {
            title: 'Документація',
            description:
              'Автоматизована генерація документів для замовлень та інвентаризації. Скоротіть паперову роботу та підвищіть ефективність.',
          },
        ],
      },
      priceSection: {
        title: 'Прайс',
        subtitle: 'Кожен план включає',
        benefitItems: [
          {
            title: 'Повний доступ до функцій',
            description:
              'Всі функції доступні для вас. Ви отримуєте доступ до всіх можливостей нашої платформи, навіть на базовому тарифі.',
          },
          {
            title: 'Безкоштовні інтеграції',
            description:
              'Використовуйте всі наші інтеграції з іншими сервісами без додаткових витрат.',
          },
          {
            title: 'Розширені аналітичні звіти',
            description:
              'Доступ до детальних звітів і аналітики для оптимізації вашого бізнесу.',
          },
        ],
        optionCardItems: [
          {
            title: 'Базовий',
            price: '<Безкоштовно',
            description: '≤70 замовлень/місяць',
            period: null,
            progressBarPercentage: 65,
            progressBarColor: '#F7D257',
          },
          {
            title: 'Стандартний',
            price: '$9',
            oldPrice: '$18',
            description: '≤250 замовлень/місяць',
            period: 'місяць',
            progressBarPercentage: 80,
            progressBarColor: '#93D562',
          },
          {
            title: 'Бізнес',
            price: '$29',
            oldPrice: '$58',
            description: '≤1000 замовлень/місяць',
            period: 'місяць',
            progressBarPercentage: 100,
            progressBarColor: '#62B127',
          },
        ],
        CTAButton: 'Детальніше',
        needMore: 'Треба більше?',
        contactUs: "Зв'яжіться з нами!",
        mostPopular: 'Найбiльш популярний',
      },
      knowMoreSection: {
        title: 'Бажаєте дізнатися більше?',
        description:
          "Якщо у вас залишилися питання або ви зацікавлені в індивідуальному тарифі, заповніть форму, і ми з вами зв'яжемося!",
        CTAButton: 'Залишити заявку',
      },
      footer: {
        updatesPhrase: 'Будь в курсі наших оновлень',
        termsOfService: 'Правила використання',
        privacyPolicy: 'Політика конфіденційності',
        submitWord: 'Відправити',
        phoneNumber: {
          text: '+38 (098) 978-8252',
          link: 'tel:+38098788252',
        },
        email: {
          text: 'support@factorywise.com.ua',
          link: 'mailto:support@factorywise.com.ua',
        },
      },
      modal: {
        titleContactUs: 'Звʼяжіться з нами',
        titleConsultation: 'Отримати консультацію',
        consultationDescription:
          'Заповніть форму нижче, і наші спеціалісти звʼяжуться з вами протягом 24 годин',
        consultationFormNoteText:
          '* Якщо у вас виникли питання щодо наших тарифних планів, будь ласка, надайте якомога більше деталей, щоб ми могли краще вам допомогти.',
        formLabels: {
          name: 'Імʼя',
          emailOrPhone: 'Email або номер телефону',
          message: 'Ваше питання',
          submit: 'Відправити',
        },
      },
      pricingPage: {
        tarrifsSectionTitle: 'Тарифні плани та ліміти',
        tarrifsSectionDescription:
          'Наші тарифи розроблені для максимізації вашої ефективності та зручності',
        tarrifsSectionSubtext:
          'Ми в бета-версії! Тестуйте платформу зі знижкою -50% на всі тарифи!',
        everyPlanSectionTitle: 'В кожному плані',
        everyPlanSectionDescription:
          'Отримуйте доступ до всіх можливостей порталу навіть на Базовому тарифі',

        period: {
          month: 'місяць',
          year: 'рік',
        },
        consultSectionTitle:
          'Не знаєте, який тариф обрати? Ми тут, щоб допомогти!',
        consultSectionDescription: 'Отримайте безкоштовну консультацію зараз!',

        consultSectionCTAButton: 'Консультація',
        features: [
          {
            id: 1,
            title: 'Необмежена кількість дропшиперів',
            description:
              'Підключайте стільки дропшиперів, скільки потрібно для вашого бізнесу.',
          },
          {
            id: 2,
            title: 'Необмежена кількість менеджерів',
            description:
              'Додавайте безлімітну кількість менеджерів для ефективного управління.',
          },
          {
            id: 3,
            title: 'Необмежена кількість бронювань',
            description:
              'Управляйте будь-якою кількістю бронювань без обмежень.',
          },
          {
            id: 4,
            title: 'Контроль залишків (приходи та списання)',
            description: 'Легко відстежуйте рух товарів та контролюйте запаси.',
          },
          {
            id: 5,
            title: 'API доступ',
            description:
              'Інтегруйте вашу систему з іншими сервісами через API.',
          },
          {
            id: 6,
            title: 'Документи',
            description:
              'Генеруйте та управляйте всіма необхідними документами для вашого бізнесу.',
          },
          {
            id: 7,
            title: 'Інтеграції',
            description:
              'Підключайтеся до різних платформ та інструментів для розширення можливостей.',
          },
          {
            id: 8,
            title: 'Без стартових платіжів',
            description: 'Жодних початкових внесків для початку роботи.',
          },
          {
            id: 9,
            title: 'Без комісії з продаж',
            description: 'Прозорі умови без прихованих комісій.',
          },
          {
            id: 10,
            title: 'Варіанти товарів',
            description:
              'Створюйте та керуйте різними варіантами ваших товарів.',
          },
          {
            id: 11,
            title: 'Розширена аналітика',
            description:
              'Отримуйте важливі аналітичні дані для прийняття обґрунтованих рішень. Підвищуйте продуктивність та ефективність вашого бізнесу.',
          },
        ],
        pricingPlansTable: {
          headers: {
            feature: 'Вартість',
            plans: ['Базовий', 'Стандартний', 'Бізнес', 'Індивідуальний'],
          },
          features: [
            {
              feature: 'Кількість замовлень на місяць',
              tooltip: 'Кількість замовлень на місяць',
              basic: 70,
              standard: 250,
              business: 1000,
              custom: 'Необмежено',
            },
            {
              feature: 'Кількість продуктів',
              basic: 100,
              standard: 1000,
              business: 3000,
              custom: 'Необмежено',
            },
            {
              feature: "Об'єм дискового простору",
              tooltip: "Об'єм дискового простору",
              basic: '1 ГБ',
              standard: '3 ГБ',
              business: '10 ГБ',
              custom: 'Необмежено',
            },
            {
              feature: 'Трансфер даних через API на місяць',
              tooltip: 'Трансфер даних через API на місяць',
              basic: 'До 1 ГБ',
              standard: 'До 10 ГБ',
              business: 'До 100 ГБ',
              custom: 'Необмежено',
            },
          ],
          costs: {
            monthly: {
              basic: {
                currentPrice: '0$',
              },
              standard: {
                oldPrice: '18$',
                currentPrice: '9$',
              },
              business: {
                oldPrice: '58$',
                currentPrice: '29$',
              },
              custom: {
                currentPrice: 'договірна',
              },
            },
            yearly: {
              basic: {
                currentPrice: '0$',
              },
              standard: {
                oldPrice: '18$',
                currentPrice: '9$',
              },
              business: {
                oldPrice: '58$',
                currentPrice: '29$',
              },
              custom: {
                currentPrice: 'договірна',
              },
            },
          },
        },
      },
    },
    onboarding: {
      indexPage: {
        title: 'Як ви плануєте користуватися платформою?',
        mainDescription:
          'Будь ласка, виберіть, як ви плануєте користуватися нашою платформою. Це допоможе нам надати вам найкращі інструменти та підтримку для вашого бізнесу.',
        nextButton: 'Далі',
        prevButton: 'Повернутися назад',
        cancelButton: 'Скасувати',
        dropshipper: {
          title: 'Дропшипер',
          description:
            'Якщо ви є дропшипером, наш сайт надає вам можливість розширити свій бізнес без необхідності інвестувати в запаси товарів.',
        },
        manufacturer: {
          title: 'Виробник',
          description:
            'Якщо ви є виробником, наш сайт пропонує платформу для збуту ваших товарів безпосередньо споживачам або через мережу дропшиперів.',
        },
      },
      formBottomText:
        '* (ЄДРПОУ та  Рахунок IBAN) можна буде ввести пізніше в меню налаштування проєкту)',
      placementMainTitle:
        'Хочете поділитися інформацією про ваші умови співпраці та майданчики для продажу?',
      placementFormSubText:
        'Більшість виробників хочуть знати, де буде продаватись їх товар. Якщо у вас є якісь майданчики, на яких ви продаєте товар, вкажіть їх тут через кому',
      catalogDescriptionSubText:
        'Вкажіть чим займається ваше виробництво та ваші умови співпраці з дропшиперами',
      companyInfoTitle: 'Заповніть інформацію про вашу компанію',
      companyInfoDescription:
        'Щоб допомогти нам краще зрозуміти ваш бізнес та його потреби, будь ласка, надайте детальну інформацію про вашу компанію.',
      fopTovDescription:
        'Оберіть, чи ви товариство з обмеженою відповідальністю (ТОВ) чи фізична особа-підприємець (ФОП), та надайте необхідну інформацію для продовження',
      welcomeTitle: 'Давайте познайомимось!',
      welcomeDescription:
        "Ваші дані допоможуть нам зв'язатися з вами та забезпечити найкращий користувацький досвід.",
      advTitle: 'Як ви дізналися про нас?',

      advDescription:
        'Допоможіть нам зрозуміти, як ви дізналися про нашу платформу. Це допоможе нам покращити наші маркетингові зусилля та надавати кращий досвід для наших користувачів.',
      advValues: [
        'Google',
        'Підслухав у черзі на каву',
        'Соціальні мережі',
        'Youtube',
        'Рекомендації',
        'Новини та статті',
        'Реклама',
        'Інше',
      ],
      categoryData: [
        {
          label: 'Агропромисловість',
          description:
            'Виробництво продуктів харчування, сільськогосподарської продукції, переробка сільськогосподарських продуктів.',
        },
        {
          label: 'Будматеріали',
          description:
            'Виробництво цементу, бетону, цегли, утеплювачів та інших будівельних матеріалів.',
        },
        {
          label: 'Деревообробка',
          description:
            "Виробництво меблів, дерев'яних виробів, столярних та теслярських конструкцій.",
        },
        {
          label: 'Електроніка',
          description:
            'Виробництво електронних пристроїв, побутової техніки, електротехнічних компонентів.',
        },
        {
          label: 'Металообробка',
          description:
            'Виробництво металевих виробів, машин, механізмів та обладнання.',
        },
        {
          label: 'Паперова промисловість',
          description: 'Виробництво паперу, картону, целюлозних виробів.',
        },
        {
          label: 'Текстиль та швейне',
          description:
            'Виробництво тканин, одягу, текстильних виробів, взуття та аксесуарів.',
        },
        {
          label: 'Хімія та фармацевтика',
          description:
            'Виробництво хімікатів, добрив, фармацевтичних препаратів, косметики.',
        },
        {
          label: 'Пластмаси та полімери',
          description:
            'Виробництво полімерних виробів, пластмас, упаковки, композитних матеріалів.',
        },
        {
          label: 'Iнше',
          description: 'Щось нестандартне, якщо немає вибраної категорії.',
        },
      ],

      formLabels: {
        name: 'Ім’я',
        surname: 'Прізвище',
        contactName: 'Контактна особа',
        tovLegalName: 'Назва ТОВ',
        phoneNumber: 'Номер телефону',
        iban: 'Рахунок IBAN',
        edrpou: 'ЄДРПОУ',
        fopLegalName: 'Назва ФОП',
        logo: 'Логотип',
        category: 'Категорії діяльності',
        projectName: 'Назва проєкту',
        placement: 'Умови співпраці та майданчики',
        description: 'Опис для каталогу',
        notRequired: '(Не обов’язково)',
        notRequiredButPreferred: '(Не обов’язково але бажано)',
      },

      finalScreen: {
        title: 'Вітаємо з успішною реєстрацією!',
        description:
          'Дякуємо, що приєдналися до нашої платформи. Тепер ви готові розпочати співпрацю з нами та скористатися всіма перевагами нашого сервісу.',
        productButton: 'Додати продукти',
        manufacturerButton: 'До роботи!',
        finishButton: 'Завершити',
      },
    },
    sidebar: {
      searchEmpty: 'Нічого не знайдено',
      search: 'Пошук',
      menu: 'Меню',
      analytics: 'Аналітика',
      sales: 'Продажі',
      warehouse: 'Склад',
      integration: 'Інтеграції',
      API: 'API доступ',
      telegram: 'Telegram',
      delivery: 'Нова пошта',
      banking: 'Банкінг',
      products: 'Продукти',
      orders: 'Замовлення',
      promotions: 'Спеціальні пропозиції',
      clients: 'Клієнти',
      bookings: 'Бронювання',
      users: 'Користувачі',
      manufacture: 'Виробництво',
      // profile: 'Профіль користувача',
      logOut: 'Вийти',
      settings: 'Налаштування',
      projectsSettings: 'Налаштування',
      switchProjects: 'Перемикнути проєкт',
      createProject: 'Створити проєкт',
      projectsCatalogue: 'Каталог проєктів',
      projectEdit: 'Редагувати',
      projectDelete: 'Видалити',
      usersApplications: 'Заявки',
      usersDrop: 'Дропшипери',
      usersAdmin: 'Менеджери',
    },
    profile: {
      show: {
        title: 'Профіль користувача',
        editButton: 'редагувати',
      },
      edit: {
        title: 'Редагувати профіль користувача',
        fields: {
          name: 'Імʼя',
          email: 'Поштова адреса',
          locale: 'Мова',
        },
        infoLine1:
          'Щоб змінити ваш пароль скористайтесь функцією ʼЗабули пароль?ʼ при логуванні.',
        infoLine2:
          "Після натискання на 'зберегти' ви будете направлення на сторінку входу.",
      },
    },
    projects: {
      switch: {
        title: 'Змінити активний проєкт',
        buttonAdd: 'Створити проєкт',
      },
      create: {
        title: 'Створити проєкт',
        description:
          'Щоб створити новий проєкт вам достатньо ввести лише його назву.',
      },
      edit: {
        title: 'Налаштування проєкту',
      },
      columns: {
        logo: 'Лого',
        title: 'Назва проєкту',
      },
    },
    templates: {
      edit: {
        title: 'Редагувати шаблон',
        description: {
          product:
            'Тут ви можете добавити нові поля до вашого продукту, та змінити існуючі. Спробуйте різні типи полів щоб раптом не опустити щось що вам прекрасно підходить.',
          product_modification:
            'Тут ви можете добавити нові поля до вашого продукту, та змінити існуючі. Спробуйте різні типи полів щоб раптом не опустити щось що вам прекрасно підходить.',
          order:
            'Тут ви можете добавити нові поля до вашого продукту, та змінити існуючі. Спробуйте різні типи полів щоб раптом не опустити щось що вам прекрасно підходить.',
          order_item:
            'Тут ви можете добавити нові поля до вашого продукту, та змінити існуючі. Спробуйте різні типи полів щоб раптом не опустити щось що вам прекрасно підходить.',
        },
      },
      tabs: {
        product: 'Шаблон продукту',
        product_modification: 'Шаблон модифікації продукту',
        order: 'Шаблон замовлення',
        order_item: 'Шаблон елементу замовлення',
      },
      form: {
        addCustomField: 'додати поле',
      },
    },
    products: {
      index: {
        title: 'Продукти',
        addButton: 'Додати',
        searchPlaceholder: 'Пошук по продуктах',
        dataLineTotalModifications: 'Загалом модифікацій',
        dataLineTotalOutOfStock: 'Відсутньо позицій',
        dataLineTotalSum: 'Доступно товару на суму',
      },
      show: {
        title: 'Перегляд продукту',
        editButton: 'Редагувати',
        deleteButton: 'Видалити',
        editStock: 'редагувати',
        deleteConfirmationText:
          'Видаливши цей продукт ви одразу видалите всі модифікації і повʼязані сутності. Чи впевнені ви у виконуваній дії і чи бажаєте продовжити?',
        deleteConfirmationHeading: 'Ви впевнені?',
        deleteConfirmationButtonText: 'Так, видалити',
      },
      edit: {
        title: 'Редагувати продукт',
      },
      create: {
        title: 'Створити продукт',
      },
      delete: {
        title: 'Ви впевнені, що хочете видалити',
        text: 'Цю дію не можна буде скасувати.',
        deleteBtn: 'Видалити',
      },
      form: {
        addModification: 'додати модифікацію продукту',
      },
      columns: {
        name: 'Назва',
        count: 'Кількість',
        price: 'Ціна',
        availableCount: 'Доступно (к-сть)',
        actualCount: 'Фактично (к-сть)',
        actions: 'Дія',
      },
      general: {
        withPhoto: 'з фото',
        withoutPhoto: 'без фото',
      },
    },
    users: {
      index: {
        title: 'Користувачі',
        addButton: 'Додати користувача',
      },
      show: {
        title: 'Перегляд користувача',
        editButton: 'Редагувати',
        deleteButton: 'Видалити доступ',
        deleteConfirmationText:
          'Ви справді хочете забрати доступ до вашого проєкту у користувача?',
        deleteConfirmationHeading: 'Ви впевнені?',
        deleteConfirmationButtonText: 'Так, видалити',
      },
      edit: {
        title: 'Редагувати користувача',
      },
      create: {
        title: 'Запросити користувача',
      },
      messages: {
        invitationSent: 'Запрошення відправлено',
        alreadyExists: 'Такий користувач вже є на проєкті',
      },
      form: {},
      columns: {
        inviteeEmail: 'Поштова адреса',
        name: 'Імʼя',
        role: 'Роль',
        manager: 'Менеджер',
        discount: 'Розмір знижки (%)',
      },
      roles: {
        Staff: 'Системний адмін',
        Owner: 'Власник',
        Client: 'Дропшипер',
        Manager: 'Менеджер',
        Producer: 'Керівник виробництва',
        Administrator: 'Адміністратор',
        Stockman: 'Логістичний менеджер',
      },
    },
    stocks: {
      edit: {
        title: 'Редагувати склад',
      },
      columns: {
        count: 'Кількість',
      },
    },
    orders: {
      index: {
        title: 'Замовлення',
        addButton: 'створити замовлення',
        dataLineTotalPending: 'В обробці на суму',
        dataLineTotalCanceled: 'Відмінено на суму',
        dataLineTotalComplete: 'Завершено на суму',
      },
      create: {
        title: 'Створити замовлення',
      },
      show: {
        title: 'Перегляд замовлення',
        editButton: 'редагувати',
        deleteButton: 'видалити',
        manageStatusButton: 'керувати статусом',
        viewHistory: 'історія змін статусу',
        deleteConfirmationHeading: 'Ви впевненні?',
        statusChangeConfirmationHeading: 'Ви впевненні?',
        statusChangeConfirmationButtonText: 'Так, продовжити',
        deleteConfirmationText:
          'Замовлення буде видалене разом зі всіма бронюваннями.',
        deleteConfirmationButtonText: 'Так, видалити',
        clientChangeStatusToPending: 'відправити менеджеру',
        changeStatusToReady: 'позначити як готове до відправки',
        changeStatusToPending: 'в обробку',
        changeStatusToComplete: 'завершити',
        changeStatusToCanceled: 'відмінити',
        changeStatusToBlocked: 'заблокувати',
        bookTemporaryConfirmationHeading: 'Ви впевненні?',
        bookTemporaryConfirmationButtonText: 'Так, забронювати',
        bookTemporaryConfirmationDescription:
          'Створиться тимчасове бронювання на 24 години для кожного елементу замовлення. Після редагування замовлення це тимчасове бронювання буде скидатись.',
        changeStatusToCompleteWithQueue:
          'Увага: на деякий товар є більше одного замовлення, впевніться що товар є в наявності і ви не перестрибуєте свою позицію в черзі перед тим як завершити замовлення.',
        queueAttention:
          'Увага: на деякий товар є більше одного замовлення, зверніть увагу на чергу за товаром.',
        queue: 'черга за товаром',
        bookTemporary: 'забронювати на 24 години',
      },
      edit: {
        title: 'Редагувати замовлення',
      },
      form: {
        addItem: 'додати ще',
      },
      columns: {
        product_modification_id: 'Виберіть продукт',
        id: 'Ідентифікатор',
        user_id: 'Виберіть користувача',
        user_name: 'Імʼя (клієнта)',
        manager_name: 'Імʼя (менеджера)',
        index: 'Номер в черзі',
        product_title: 'Назва продукту',
        product_modification_title: 'Назва модифікації',
        sum: 'Сума замовлення',
        last_status: 'Останній статус',
        files: 'Файли',
        sent_to_manager_at: 'Дата першої відправки менеджеру',
        updated_at: 'Дата останнього оновлення',
      },
    },
    order_statuses: {
      index: {
        title: 'Історія змін статусу',
      },
      values: {
        All: 'Усі',
        Draft: 'Чернетка',
        Pending: 'Обробляється',
        'In production': 'У виробництві',
        Ready: 'Готово до відправки',
        'Loading (car)': 'Вантажиться (в машину)',
        'Loaded (car)': 'Завантажено (в машину)',
        'Sent (car)': 'Відправлено (машину)',
        Complete: 'Завершено',
        Canceled: 'Відмінено',
        Blocked: 'Заблоковано',
      },
      values_info: {
        Pending_client:
          'УВАГА: ви більше не зможене редагувати це замовлення. На цьому етапі автоматично створюється бронювання для кожного елементу замовлення. А саме замовлення стає видиме для менеджера. Якщо ви захочете змінити або ж відмінити замовлення вам потрібно буде сконтактуйтуватись зі своїм менеджером.',
        Pending:
          'На цьому етапі автоматично створюється (робиться активним) бронювання для кожного елементу замовлення.',
        'In production':
          'Змінивши статус на ʼу виробництвіʼ ви автоматично створюєте запит на виробництво щодо виготовлення товару якого немає в наявності на складі.',
        Ready: '',
        'Loading (car)': '',
        'Loaded (car)': '',
        'Sent (car)':
          'Всі бронювання які були активними для замовлення будуть завершені і зі складу автоматично відніметься кількість товару яка була у замовленні.',
        Complete:
          'Всі бронювання які були активними для замовлення будуть завершені і зі складу автоматично відніметься кількість товару яка була у замовленні.',
        Canceled:
          'Замовлення закривається без повернення до клієнта для редагування.',
        Blocked: 'Замовлення повертається до клієнта для редагування.',
      },
    },
    bookings: {
      index: {
        title: 'Всі бронювання',
        addButton: 'зробити бронь',
      },
      show: {
        title: 'Перегляд бронювання',
        updateStatusButton: 'оновити склад',
        showOrderButton: 'перейти до замовлення',
        editButton: 'Редагувати',
        deleteButton: 'Видалити',
        deleteConfirmationHeading: 'Ви впевненні що хочете зняти бронювання?',
        deleteConfirmationText:
          'Бронювання буде знято і дана кількість товару буде в числі доступного товару для замовлення.',
        deleteConfirmationButtonText: 'Так, видалити',
        updateConfirmationHeading:
          'Ви впевненні що хочете позначити дану кількість товару як забрану зі складу?',
        updateConfirmationText:
          'Роблячи це ви зменшуєте кількість товару на складі.',
        updateConfirmationButtonText: 'Так, оновити',
        nonActive: 'Бронювання не активне',
        stockUpdated: 'Товар забрано зі складу',
        disabled: 'Дані про товар на складі не змінились',
      },
      edit: {
        title: 'Редагувати бронювання',
      },
      create: {
        title: 'Створити бронювання',
      },
      status: {
        All: 'Усі',
        Active: 'Активне',
        Disabled: 'Неактивне',
        Completed: 'Склад оновлено',
        'Stock updated': 'Склад оновлено',
      },
      columns: {
        enddate: 'Статус / ще часу',
        booked_for_hours: 'На який час (годин)',
        select_user: 'Виберіть користувача',
        user_name: 'Імʼя користувача',
        count: 'Кількість',
        product_title: 'Назва продукту',
        product_modification_title: 'Назва модифікації',
        comment: 'Коментар',
        product_modification_id: 'Модифікація продукту',
      },
    },
    manufacture_requests: {
      index: {
        title: 'Всі виробничі запити (приходи)',
        addButton: 'оформити прихід',
      },
      show: {
        title: 'Перегляд виробничого приходу',
      },
      create: {
        title: 'Оформити прихід (додати на склад)',
      },
      columns: {
        user_name: 'Імʼя користувача',
        count: 'Кількість',
        product_title: 'Назва продукту',
        product_modification_title: 'Назва модифікації',
        comment: 'Коментар',
        product_modification_id: 'Модифікація продукту',
      },
    },
    promotions: {
      index: {
        title: 'Спеціальні пропозиції',
        addButton: 'створити',
      },
      show: {
        title: 'Перегляд спеціальної пропозиції',
        deleteConfirmationText:
          'Видаливши цю спеціальну пропозицію ви одразу видалите всі повʼязані сутності. Чи впевнені ви у виконуваній дії і чи бажаєте продовжити?',
        deleteConfirmationHeading: 'Ви впевнені?',
        deleteConfirmationButtonText: 'Так, видалити',
      },
      create: {
        title: 'Створення спеціальної пропозиції',
      },
      columns: {
        title: 'Назва',
        promo: 'Промокод',
        is_one_time_use_only: 'Діє один раз',
        discount: 'Розмір знижки',
        discount_type: 'Тип знижки',
        affects_all: 'На всі продукти',
        starts_at: 'Діє від',
        expires_at: 'Діє до',
      },
    },
    api_integration: {
      title: 'API доступ',
      nav_links: {
        static_data: 'Редагування статичних даних',
        request_form: 'Перегляд форм-заявок',
        manage: 'Управління API ключами',
      },
      static_data: {
        info_box_1:
          'При завантажені файлів вкажіть інформативні ключі за якими вони будуть доступні в фінальному JSON обʼєкті, для легшої ідентифікацїї оскільки назва файлу не зберігається і буде замінена на випадковий хеш.',
        info_box_2:
          'Обʼєкт отриманий через API матиме формат “ключ: посилання”, в якому як ключ буде те що ви вказали при завантажені файлу, а як посилання буде шлях за яким ваш файл буде доступним. Майте на увазі що посилання працює протягом однієї години після отримання обʼєкту, через одну годину дані треба буде оновити.',
        info_box_3:
          'Ви можете надати будь який валідний JSON обʼєкт і він буде доступний для вас через API.',
        api_documentation_link: 'Документація API',
        download_btn: 'Завантажити файл',
        files_box: 'Файли',
        json_box: 'Інші дані',
      },
      request_form: {
        tabs_btn: {
          all: 'Усі',
          new: 'Нові',
          done: 'Опрацьовані',
        },
        table_fields: {
          name: "Ім'я",
          email: 'Електрона пошта',
          phone_number: 'Телефон',
          status: 'Статус',
          comment: 'Опис',
        },
        status: {
          new: 'Новий',
          done: 'Опрацьований',
        },
      },
      api_keys: {
        isCopied: 'Скопійовано!',
        title: ' Список існуючих ключів',
        table_fields: {
          id: 'Ідентифікатор',
          access_key: 'Ключ доступу',
          created_at: 'Дата створення',
          expires_at: 'Востаннє використано',
        },
      },
    },
  },
  en: {
    errors: {
      unknownError: 'Unknown Error.',
      notFoundError: 'Not Found.',
      pleaseTryAgain: 'Please try again.',
      contactUs:
        'If the issue persists please contact us support@factorywise.com.ua',
    },
    general: {
      filterAction: 'search & filter',
      from: 'from',
      to: 'to',
      soon: 'Soon',
      files: "Drag 'n' drop some files here",
      or: 'or',
      files_dropzone: 'select files',
      save: 'Save',
      submit: 'Submit',
      cancel: 'Cancel',
      edit: 'Edit',
      goBack: 'go back',
      true: 'Yes',
      false: 'No',
      successMessage: 'Action was comleted successfuly',
      successUpdateMessage: 'Record updated successfuly',
      warningUpdateMessage:
        'Record updated successfuly, but seems like we got some unexpected behaviour. Please check if all the data looks like it should',
      successCreateMessage: 'Record created successfuly',
      warningCreateMessage:
        'Record created successfuly, but seems like we got some unexpected behaviour. Please check if all the data looks like it should',
      successDeleteMessage: 'Record deleted successfuly',
      unexpectedError: 'Unexpected error occurred',
      startTyping: 'Start typing...',
      typeNumber: 'Type a number',
      time: {
        short: {
          year: 'y',
          month: 'm',
          hour: 'h',
          day: 'd',
          minute: 'm',
          second: 's',
        },
        long: {
          year: 'year',
          years: 'years',
          month: 'month',
          months: 'monthes',
          hour: 'hour',
          hours: 'hours',
          day: 'day',
          days: 'days',
          minute: 'minute',
          minutes: 'minutes',
          second: 'second',
          seconds: 'seconds',
        },
        ago: 'тому',
      },
    },
    filter: {
      title: 'Filter',
      reset: 'Reset',
      applied: 'applied',
    },
    pageMenu: {
      options: 'Actions',
    },
    landing: {
      loginButton: 'Log in',
      signUpButton: 'Sign up',
      heading: 'Together to',
      headingWord: 'success',
      subText:
        'Welcome to FactoryWise, where manufacturers and dropshippers come together to reach new heights of business.',
      headCTA: 'Try for free',
      navLinks: {
        about: 'About us',
        advantages: 'Advantages',
        price: 'Price',
      },
      advantagesSection: [
        {
          title: 'Market Growth',
          description:
            'According to recent research, the global dropshipping market has grown by 23% over the past 5 years, reaching $162.44 billion.',
        },
        {
          title: 'Inventory Management',
          description:
            'Manufacturers using specialized inventory management platforms typically reduce their operational costs by 20% and increase work efficiency by 30%.',
        },
        {
          title: 'Benefits for Small Businesses',
          description:
            'Small businesses gain numerous benefits from dropshipping, including low startup costs, minimal risks, and the ability to scale without significant investments.',
        },
      ],
      aboutSection: {
        description:
          'FactoryWise is a portal that connects manufacturers and dropshippers. Manufacturers can register their products, invite managers, and manage inventory. Dropshippers get access to a wide range of products that they can offer to their customers. Our platform is designed to optimize business processes and increase productivity.',
        CTA: 'Interested in how our solutions can help your business? Schedule a free consultation.',
        CTAButton: 'Consultation',
      },
      offersSection: {
        title: 'We Offer',
        items: [
          {
            title: 'Simplicity',
            description:
              'Easy registration and start without complex procedures. Save time and effort by focusing on your business.',
          },
          {
            title: 'Integration',
            description:
              'API for seamless integration of your website with the FactoryWise platform. Ensure smooth data synchronization and order management.',
          },
          {
            title: 'Statistics',
            description:
              "Receive essential analytical data for making informed decisions. Increase your business's productivity and efficiency.",
          },
          {
            title: 'Flexibility',
            description:
              'Free and premium plans for different needs. Choose the package that best suits your business and scale.',
          },
        ],
      },
      featuresSection: {
        title: 'Features',
        items: [
          {
            title: 'Inventory',
            description:
              'Manage your stock easily and efficiently. Our tools will help you keep your inventory up-to-date.',
          },
          {
            title: 'Orders',
            description:
              'Track orders from receipt to delivery. Ensure timely fulfillment and customer satisfaction.',
          },
          {
            title: 'Communication',
            description:
              'Smooth communication between manufacturers and dropshippers. Simplify collaboration and ensure effective information exchange.',
          },
          {
            title: 'Documentation',
            description:
              'Automated document generation for orders and inventory. Reduce paperwork and increase efficiency.',
          },
        ],
      },
      priceSection: {
        title: 'Pricing',
        subtitle: 'Each plan includes',
        benefitItems: [
          {
            title: 'Full access to features',
            description:
              'All features are available to you. You get access to all the capabilities of our platform, even on the basic plan.',
          },
          {
            title: 'Free integrations',
            description:
              'Use all our integrations with other services at no additional cost.',
          },
          {
            title: 'Advanced analytical reports',
            description:
              'Access to detailed reports and analytics to optimize your business.',
          },
        ],
        optionCardItems: [
          {
            title: 'Basic',
            price: '<Free',
            description: '≤70 orders/month',
            period: null,
            progressBarPercentage: 65,
            progressBarColor: '#F7D257',
          },
          {
            title: 'Standard',
            price: '$17',
            description: '≤250 orders/month',
            period: 'month',
            progressBarPercentage: 80,
            progressBarColor: '#93D562',
          },
          {
            title: 'Business',
            price: '$58',
            description: '≤1000 orders/month',
            period: 'month',
            progressBarPercentage: 100,
            progressBarColor: '#62B127',
          },
        ],
        CTAButton: 'Learn More',
        needMore: 'Need more?',
        contactUs: 'Contact us',
        mostPopular: 'Most popular',
      },
      knowMoreSection: {
        title: 'Want to know more?',
        description:
          'If you have any questions or are interested in a custom plan, fill out the form and we will get in touch with you!',
        CTAButton: 'Submit request',
      },
      footer: {
        updatesPhrase: 'Stay updated with our news',
        termsOfService: 'Terms of Service',
        privacyPolicy: 'Privacy Policy',
        submitWord: 'Submit',
        phoneNumber: {
          text: '+38 (098) 978-8252',
          link: 'tel:+38098788252',
        },
        email: {
          text: 'support@factorywise.com.ua',
          link: 'mailto:support@factorywise.com.ua',
        },
      },
      modal: {
        titleContactUs: 'Contact Us',
        titleConsultation: 'Get a Consultation',
        consultationDescription:
          'Fill out the form below, and our specialists will contact you within 24 hours',
        consultationFormNoteText:
          '* If you have any questions about our tariff plans, please provide as many details as possible so we can better assist you.',
        formLabels: {
          name: 'Name',
          emailOrPhone: 'Email or Phone Number',
          message: 'Your Question (optional)',
          submit: 'Submit',
        },
      },
      pricingPage: {
        tarrifsSectionTitle: 'Pricing Plans and Limits',
        tarrifsSectionDescription:
          'Our tariffs are designed to maximize your efficiency and convenience.',
        tarrifsSectionSubtext:
          'We are in beta! Test the platform with a 50% discount on all plans!',
        everyPlanSectionTitle: 'Included in Every Plan',
        everyPlanSectionDescription:
          'Get access to all portal features even with the Basic plan.',

        period: {
          month: 'month',
          year: 'year',
        },
        consultSectionTitle:
          "Not sure which plan to choose? We're here to help!",
        consultSectionDescription: 'Get a free consultation now!',

        consultSectionCTAButton: 'Consultation',
        features: [
          {
            id: 1,
            title: 'Unlimited Dropshippers',
            description: 'Connect as many dropshippers as your business needs.',
          },
          {
            id: 2,
            title: 'Unlimited Managers',
            description:
              'Add an unlimited number of managers for efficient management.',
          },
          {
            id: 3,
            title: 'Unlimited Bookings',
            description: 'Manage any number of bookings without restrictions.',
          },
          {
            id: 4,
            title: 'Stock Control (Incoming and Outgoing)',
            description:
              'Easily track inventory movement and control stock levels.',
          },
          {
            id: 5,
            title: 'API Access',
            description:
              'Integrate your system with other services through API.',
          },
          {
            id: 6,
            title: 'Documents',
            description:
              'Generate and manage all the necessary documents for your business.',
          },
          {
            id: 7,
            title: 'Integrations',
            description:
              'Connect to various platforms and tools to expand your capabilities.',
          },
          {
            id: 8,
            title: 'No Startup Payments',
            description: 'No upfront payments to get started.',
          },
          {
            id: 9,
            title: 'No Sales Commission',
            description: 'Transparent terms with no hidden commissions.',
          },
          {
            id: 10,
            title: 'Product Variants',
            description:
              'Create and manage different variants of your products.',
          },
          {
            id: 11,
            title: 'Advanced Analytics',
            description:
              'Receive critical analytical data to make informed decisions. Enhance your business’s productivity and efficiency.',
          },
        ],
        pricingPlansTable: {
          headers: {
            feature: 'Feature',
            plans: ['Basic', 'Standard', 'Business', 'Custom'],
          },
          features: [
            {
              feature: 'Number of Orders per Month',
              tooltip: 'Number of orders per month',
              basic: 70,
              standard: 200,
              business: 1000,
              custom: 'Unlimited',
            },
            {
              feature: 'Number of Products',
              basic: 100,
              standard: 1000,
              business: 3000,
              custom: 'Unlimited',
            },
            {
              feature: 'Disk Space',
              tooltip: 'Disk space',
              basic: '1 GB',
              standard: '3 GB',
              business: '10 GB',
              custom: 'Unlimited',
            },
            {
              feature: 'Data Transfer via API per Month',
              tooltip: 'Data transfer via API per month',
              basic: 'Up to 1 GB',
              standard: 'Up to 10 GB',
              business: 'Up to 100 GB',
              custom: 'Unlimited',
            },
          ],
          costs: {
            monthly: {
              basic: {
                currentPrice: '0$',
              },
              standard: {
                oldPrice: '18$',
                currentPrice: '9$',
              },
              business: {
                oldPrice: '58$',
                currentPrice: '29$',
              },
              custom: {
                currentPrice: 'negotiable',
              },
            },
            yearly: {
              basic: {
                currentPrice: '0$',
              },
              standard: {
                oldPrice: '18$',
                currentPrice: '9$',
              },
              business: {
                oldPrice: '58$',
                currentPrice: '29$',
              },
              custom: {
                currentPrice: 'negotiable',
              },
            },
          },
        },
      },
    },
    onboarding: {
      indexPage: {
        title: 'How do you plan to use the platform?',
        mainDescription:
          'Please choose how you plan to use our platform. This will help us provide you with the best tools and support for your business.',
        nextButton: 'Next',
        prevButton: 'Go Back',
        cancelButton: 'Cancel',
        dropshipper: {
          title: 'Dropshipper',
          description:
            'If you are a dropshipper, our site provides you the opportunity to expand your business without the need to invest in inventory.',
        },
        manufacturer: {
          title: 'Manufacturer',
          description:
            'If you are a manufacturer, our site offers a platform for selling your products directly to consumers or through a network of dropshippers.',
        },
      },
      placementMainTitle:
        'Would you like to share information about your cooperation terms and sales platforms?',

      formBottomText:
        '* (EDRPOU and IBAN Account) can be entered later in the project settings menu)',
      placementFormSubText:
        'Most manufacturers want to know where their product will be sold. If you have any platforms where you sell your products, please list them here, separated by commas.',
      catalogDescriptionSubText:
        'Indicate what your production specializes in and your terms of cooperation with dropshippers.',
      companyInfoTitle: 'Fill in your company information',
      companyInfoDescription:
        'To help us better understand your business and its needs, please provide detailed information about your company.',
      fopTovDescription:
        'Choose whether you are a Limited Liability Company (LLC) or a Sole Proprietor (FOP) and provide the necessary information to continue.',
      welcomeTitle: "Let's get acquainted!",
      welcomeDescription:
        'Your information will help us get in touch with you and provide the best user experience.',
      advTitle: 'How did you hear about us?',

      advDescription:
        'Help us understand how you found out about our platform. This will help us improve our marketing efforts and provide a better experience for our users.',
      advValues: [
        'Google',
        'Overheard in a coffee queue',
        'Social networks',
        'YouTube',
        'Recommendations',
        'News and articles',
        'Advertising',
        'Other',
      ],
      categoryData: [
        {
          label: 'Agriculture',
          description:
            'Production of food, agricultural products, processing of agricultural products.',
        },
        {
          label: 'Building Materials',
          description:
            'Production of cement, concrete, bricks, insulation, and other building materials.',
        },
        {
          label: 'Woodworking',
          description:
            'Production of furniture, wooden products, carpentry, and joinery constructions.',
        },
        {
          label: 'Electronics',
          description:
            'Production of electronic devices, household appliances, electrical components.',
        },
        {
          label: 'Metalworking',
          description:
            'Production of metal products, machines, mechanisms, and equipment.',
        },
        {
          label: 'Paper Industry',
          description: 'Production of paper, cardboard, cellulose products.',
        },
        {
          label: 'Textile and Sewing',
          description:
            'Production of fabrics, clothing, textile products, footwear, and accessories.',
        },
        {
          label: 'Chemistry and Pharmaceuticals',
          description:
            'Production of chemicals, fertilizers, pharmaceutical products, cosmetics.',
        },
        {
          label: 'Plastics and Polymers',
          description:
            'Production of polymer products, plastics, packaging, composite materials.',
        },
        {
          label: 'Other',
          description: 'Something unconventional if no category is selected.',
        },
      ],

      formLabels: {
        name: 'First Name',
        surname: 'Last Name',
        contactName: 'Contact Person',
        tovLegalName: 'LLC Name',
        phoneNumber: 'Phone Number',
        iban: 'IBAN Account',
        edrpou: 'EDRPOU',
        fopLegalName: 'FOP Name',
        logo: 'Logo',
        category: 'Activity Categories',
        projectName: 'Project Name',
        placement: 'Cooperation Terms and Platforms',
        description: 'Catalog Description',
        notRequired: '(Optional)',
        notRequiredButPreferred: '(Not required but preferred)',
      },

      finalScreen: {
        title: 'Congratulations on your successful registration!',
        description:
          'Thank you for joining our platform. Now you are ready to start working with us and take advantage of all the benefits our service offers.',
        productButton: 'Add Products',
        manufacturerButton: 'Manufacturer Catalog',
        finishButton: 'Finish',
      },
    },
    sidebar: {
      menu: 'Menu',
      searchEmpty: 'Not found',
      search: 'Search',
      analytics: 'Analytics',
      sales: 'Sales',
      warehouse: 'Warehouse',
      integration: 'Integration',
      products: 'Products',
      orders: 'Orders',
      bookings: 'Bookings',
      users: 'Users',
      manufacture: 'Manufacture',
      profile: 'Profile',
      logOut: 'Log out',
      settings: 'Settings',
      projectsSettings: 'Settings',
      switchProjects: 'Switch project',
      createProject: 'Create project',
      projectsCatalogue: 'Catalogue of projects',
      projectEdit: 'Edit',
      projectDelete: 'Delete',
      usersApplications: 'Applications',
      usersDrop: 'Client',
      usersAdmin: 'Manager',
    },
    profile: {
      show: {
        title: 'Profile page',
        editButton: 'edit my profile',
      },
      edit: {
        title: 'Edit Profile page',
        fields: {
          name: 'Name',
          email: 'Email',
          locale: 'Locale',
        },
        infoLine1:
          "To change your password please use 'Forgot Password' feature when you login.",
        infoLine2: "After clicking on 'submit' you will need to login again.",
      },
    },
    projects: {
      switch: {
        title: 'Switch Project',
        buttonAdd: 'Create Project',
      },
      create: {
        title: 'Create Project',
        description:
          'To create a new project you just need to fill in the fields below and click on Submit.',
      },
      columns: {
        title: 'Title',
        logo: 'Logo',
      },
    },
    templates: {
      edit: {
        title: 'Edit template',
        description: {
          product: 'Here you can edit selected template',
          product_modification: 'Here you can edit selected template',
          order: 'Here you can edit selected template',
          order_item: 'Here you can edit selected template',
        },
      },
      tabs: {
        product: 'Product',
        product_modification: 'Product modification',
        order: 'Order',
        order_item: 'Order item',
      },
      form: {
        addCustomField: 'add custom field',
      },
    },
    products: {
      index: {
        title: 'Products',
        addButton: 'add',
        searchPlaceholder: 'Search for products',
        dataLineTotalModifications: 'Total modifications',
        dataLineTotalOutOfStock: 'Out of stock modifications',
        dataLineTotalSum: 'Avalilable products total value',
      },
      show: {
        title: 'View product',
        editButton: 'edit',
        deleteButton: 'delete',
        editStock: 'edit',
        deleteConfirmationText:
          'By deleting this item you will lose  all data releted to this item, are you sure you want to proceed?',
        deleteConfirmationHeading: 'Are you sure?',
        deleteConfirmationButtonText: 'Yes, delete',
      },
      edit: {
        title: 'Edit product',
      },
      create: {
        title: 'Create product',
      },
      delete: {
        title: 'Are you sure you want to delete',
        text: 'This action cannot be undone.',
        deleteBtn: 'Delete',
      },
      form: {
        addModification: 'add product modification',
      },
      columns: {
        name: 'Name',
        count: 'Count',
        availableCount: 'Available (count)',
        actualCount: 'Actual (count)',
        actions: 'Action',
      },
      general: {
        withPhoto: 'with photo',
        withoutPhoto: 'without photo',
      },
    },
    users: {
      index: {
        title: 'Users',
        addButton: 'Add user',
      },
      show: {
        title: 'View user',
        editButton: 'Edit',
        deleteButton: 'revoke access',
        deleteConfirmationText:
          'By deleting this item you will lose all data releted to this item, are you sure you want to proceed?',
        deleteConfirmationHeading: 'Are you sure?',
        deleteConfirmationButtonText: 'Yes, delete',
      },
      edit: {
        title: 'Edit user',
      },
      create: {
        title: 'Invite user',
      },
      messages: {
        invitationSent: 'Invitation sent',
        alreadyExists: 'The record already exists',
      },
      form: {},
      columns: {
        inviteeEmail: 'Invitee email',
        name: 'Name',
        role: 'Role',
        manager: 'Manager name',
      },
      roles: {
        Staff: 'Staff',
        Owner: 'Owner',
        Client: 'Client',
        Manager: 'Manager',
        Producer: 'Producer',
        Administrator: 'Administrator',
        Stockman: 'Stockman',
      },
    },
    stocks: {
      edit: {
        title: 'Edit stock',
      },
      columns: {
        count: 'Count',
      },
    },
    orders: {
      index: {
        title: 'Orders',
        addButton: 'Create order',
        dataLineTotalPending: 'Total sum of pending orders',
        dataLineTotalCanceled: 'Total sum of canceled orders',
        dataLineTotalComplete: 'Total sum of completed orders',
      },
      create: {
        title: 'Create order',
      },
      show: {
        title: 'View order',
        editButton: 'edit',
        deleteButton: 'delete',
        manageStatusButton: 'Manage Status',
        viewHistory: 'View history of a status change',
        deleteConfirmationHeading: 'Are you sure?',
        deleteConfirmationText:
          'Order will be destroyed as well as all relared items and bookings.',
        deleteConfirmationButtonText: 'yes, delete',
        clientChangeStatusToPending: 'Send to a manager',
        changeStatusToReady: 'Mark as Ready for shipment',
        changeStatusToPending: 'Mark as Pending',
        changeStatusToComplete: 'Mark as Complete',
        changeStatusToCanceled: 'Mark as Canceled',
        changeStatusToBlocked: 'Mark as Blocked',
        bookTemporaryConfirmationHeading: 'Are you sure?',
        bookTemporaryConfirmationButtonText: 'Yes, book',
        bookTemporaryConfirmationDescription:
          'Temporary booking for 24h will be created for each order item. After any change to the order the booking will be disabled.',
        changeStatusToCompleteWithQueue:
          'Attention: some of order items is in a queue. Please make sure that you close this order in your turn and not before it.',
        queueAttention:
          'Attention: the queue is currently applying for some products.',
        queue: 'Product Queue',
        bookTemporary: 'Book for 24 hours',
      },
      edit: {
        title: 'Edit order',
      },
      form: {
        addItem: 'add more',
      },
      columns: {
        product_modification_id: 'Choose product',
        id: 'Identifier',
        user_id: 'Choose user',
        user_name: 'Name (client)',
        manager_name: 'Name (manager)',
        index: 'Queue number',
        product_title: 'Product Title',
        product_modification_title: 'Modification Title',
        sum: 'Order total (sum)',
        last_status: 'Last status',
        files: 'Files',
        sent_to_manager_at: 'Sent to manager at',
        updated_at: 'Updated at',
      },
    },
    order_statuses: {
      index: {
        title: 'Status history',
      },
      values: {
        All: 'All',
        Draft: 'Draft',
        Pending: 'Pending',
        'In production': 'In production',
        Ready: 'Ready',
        'Loading (car)': 'Loading (car)',
        'Loaded (car)': 'Loaded (car)',
        'Sent (car)': 'Sent (car)',
        Complete: 'Complete',
        Canceled: 'Canceled',
        Blocked: 'Blocked',
      },
      values_info: {
        Pending_client:
          "Attention: you won't be able to edit this order anymore. It will be automatically send to your managed, all bookings related to this order will be created.",
        Pending: 'All required items are getting booked.',
        'In production': 'Some items are getting produced.',
        Ready: '',
        'Loading (car)': '',
        'Loaded (car)': '',
        'Sent (car)': 'All bookings become completed - stock gets updated.',
        Complete: 'All bookings become completed - stock gets updated.',
        Canceled:
          'Order is closed and the related bookings become disabled - stock does not get updated, all the items become available to order again.',
        Blocked: 'Order is returned to the client for edition.',
      },
    },
    bookings: {
      index: {
        title: 'View all bookings',
        addButton: 'Add Booking',
      },
      show: {
        showOrderButton: 'go to order',
        title: 'View Booking',
        editButton: 'Edit',
        deleteButton: 'Delete',
        deleteConfirmationHeading: 'Are you sure?',
        deleteConfirmationText:
          'By deleting this item you will lose all data releted to this item, are you sure you want to proceed?',
        deleteConfirmationButtonText: 'Yes, delete',
        updateConfirmationHeading: 'Are you sure you want to update stock?',
        updateConfirmationText:
          'By doing so, you will update stock valued for this product and modification.',
        updateConfirmationButtonText: 'Yes, update',
        updateStatusButton: 'Update stock',
        nonActive: 'Booking is not active',
        stockUpdated: 'Stock updated (-products)',
        disabled: 'Stock was not updated',
      },
      edit: {
        title: 'edit booking',
      },
      create: {
        title: 'Create Booking',
      },
      status: {
        All: 'All',
        Active: 'Active',
        Disabled: 'Disabled',
        Completed: 'Stock updated',
        'Stock updated': 'Stock updated',
      },
      columns: {
        enddate: 'Status / time left',
        booked_for_hours: 'For (hours)',
        select_user: 'Select User',
        user_name: 'User Name',
        count: 'Count',
        product_title: 'Product Title',
        product_modification_title: 'Product Modification Title',
        comment: 'Comment',
        product_modification_id: 'Product Modification',
      },
    },
    manufacture_requests: {
      index: {
        title: 'All manufacture requests (receipt)',
        addButton: 'add to stock (register receipt)',
      },
      show: {
        title: 'View manufacture request (receipt)',
      },
      create: {
        title: 'Receipt registration',
      },
      columns: {
        user_name: 'User Name',
        count: 'Count',
        product_title: 'Product Title',
        product_modification_title: 'Product Modification Title',
        product_modification_id: 'Product Modification',
      },
    },
    api_integration: {
      title: 'API доступ',
      nav_links: {
        static_data: 'Редагування статичних даних',
        request_form: 'Перегляд форм-заявок',
        manage: 'Управління API ключами',
      },
      static_data: {
        info_box_1:
          'При завантажені файлів вкажіть інформативні ключі за якими вони будуть доступні в фінальному JSON обʼєкті, для легшої ідентифікацїї оскільки назва файлу не зберігається і буде замінена на випадковий хеш.',
        info_box_2:
          'Обʼєкт отриманий через API матиме формат “ключ: посилання”, в якому як ключ буде те що ви вказали при завантажені файлу, а як посилання буде шлях за яким ваш файл буде доступним. Майте на увазі що посилання працює протягом однієї години після отримання обʼєкту, через одну годину дані треба буде оновити.',
        info_box_3:
          'Ви можете надати будь який валідний JSON обʼєкт і він буде доступний для вас через API.',
        api_documentation_link: 'Документація API',
        download_btn: 'Завантажити файл',
        files_box: 'Файли',
        json_box: 'Інші дані',
      },
      request_form: {
        tabs_btn: {
          all: 'Усі',
          new: 'Нові',
          done: 'Опрацьовані',
        },
        table_fields: {
          name: "Ім'я",
          email: 'Електрона пошта',
          phone_number: 'Телефон',
          status: 'Статус',
          comment: 'Опис',
        },
        status: {
          new: 'Новий',
          done: 'Опрацьований',
        },
      },
      api_keys: {
        isCopied: 'Скопійовано!',
        title: ' Список існуючих ключів',
        table_fields: {
          id: 'Ідентифікатор',
          access_key: 'Ключ доступу',
          created_at: 'Дата створення',
          expires_at: 'Востаннє використано',
        },
      },
    },
  },
};

export default loc[locale] || loc['ua'];
