import { FC, useContext, useEffect, useMemo, useState } from 'react';
import Page from '../../components/Page/Page';
import { fieldsForTypes, templatesForTypes } from '../../utils/template';
import Form from '../../components/Form/Form';
import { useNavigate, useParams } from 'react-router-dom';
import { NotificationContext } from '../../contexts/NotificationContext';
import { preparedRequestBody } from '../../utils/form';
import { CommonContext } from '../../contexts/CommonContext';
import { ProductApiClient } from '../../api/ProductApiClient';
import Loading from '../../components/Loading/Loading';
import { AnyObject } from '../../api/anyObjectTypes';
import localize from '../../localize';
import { ProjectUploadApiClient } from '../../api/ProjectUploadApiClient';
import { uploadBlobToAws } from '../../utils/aws';

export const ProductEditPage: FC = () => {
  const { id } = useParams();
  const [values, setValues] = useState<AnyObject>({});
  const [mainFields, setMainFields] = useState<AnyObject[]>([]);
  const [subItemFields, setSubItemFields] = useState<AnyObject[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const { setNotification } = useContext(NotificationContext);
  const { currentProject, templates } = useContext(CommonContext);
  const navigate = useNavigate();
  const template = useMemo(
    () => templatesForTypes(templates, ['Product'])[0],
    [templates]
  );

  const prepareData = async (): Promise<void> => {
    if (id) {
      const res = {
        ...(await ProductApiClient.getShow(currentProject.id, id)),
      } as AnyObject;
      Object.keys(res['additional_fields'] || {}).forEach((afKey) => {
        res[afKey] = res['additional_fields'][afKey];
      });
      if (Array.isArray(res['product_modifications'])) {
        res['product_modifications'].map((siarr: any) =>
          Object.keys(siarr['additional_fields'] || {}).forEach((afKey) => {
            siarr[afKey] = siarr['additional_fields'][afKey];
          })
        );
      } else {
        Object.keys(
          res['product_modifications']['additional_fields'] || {}
        ).forEach((afKey) => {
          res[afKey] = res['additional_fields'][afKey];
        });
      }

      setValues(res);
    }
  };

  const onSubmit = (mainItem: AnyObject, subItems: AnyObject[]) => {
    if (!id) return;

    const filesInternalNames = template?.custom_fields
      ?.filter((el: any) => el?.field_type === 'files')
      ?.map((el: any) => el?.internal_name) as string[];

    const mainItemData = Object.keys(mainItem).reduce((result: any, key) => {
      if (!filesInternalNames.includes(key)) {
        result[key] = mainItem[key];
      }
      return result;
    }, {});

    setSubmitting(true);
    ProductApiClient.update(
      currentProject.id,
      {
        product: preparedRequestBody({
          mainItem: mainItemData,
          mainFields,
          subItems,
          subItemFields,
          subItemsKey: 'product_modifications_attributes',
        }),
        template_id: template?.id,
      },
      id
    ).then((updatedProduct) => {
      Promise.all(
        filesInternalNames.map((intName: string) =>
          uploadBlobToAws({
            projectId: currentProject.id,
            mainItemId: updatedProduct.id,
            additionalParams: `product_id=${updatedProduct.id}&field_name=${intName}`,
            ApiClient: ProjectUploadApiClient,
            files: mainItem[intName].filter((el: any) => !el.url),
          }).then((response) => ({ response, intName }))
        )
      ).then((value) => {
        const filesFields: any = {};
        value.map(
          (res) =>
            (filesFields[res.intName] = [
              ...res.response.errors.filter((el) => !!el),
              ...res.response.success,
              ...mainItem[res.intName]
                .filter((el: any) => el.url)
                .map((el: any) => el.path),
            ])
        );

        if (Object.values(filesFields).filter((v: any) => v?.length)?.length) {
          ProductApiClient.update(
            currentProject.id,
            {
              product: {
                additional_fields: {
                  ...updatedProduct.additional_fields,
                  ...filesFields,
                },
              },
              template_id: template?.id,
            },
            updatedProduct.id
          ).then(() => {
            if (value.filter((v) => v.response.errors.length).length > 0) {
              setNotification({
                severity: 'warning',
                message: localize.general.warningUpdateMessage,
              });
            } else {
              setNotification({
                severity: 'success',
                message: localize.general.successUpdateMessage,
              });
            }
            setSubmitting(false);
            navigate(`/cp/products/${updatedProduct.id}`);
          });
        } else {
          setSubmitting(false);
          navigate(`/cp/products/${updatedProduct.id}`);
        }
      });
    });
  };

  useEffect(() => {
    if (!templates?.length) return;

    setLoading(true);
    prepareData().finally(() => setLoading(false));
  }, [templates, currentProject]);

  useEffect(() => {
    setMainFields([...fieldsForTypes(templates, ['Product'])]);
    setSubItemFields([...fieldsForTypes(templates, ['Product modification'])]);
  }, [templates, currentProject]);

  if (loading || !Object.keys(values).length)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page title={localize.products.edit.title} actions={[]}>
      <Form
        mainFields={mainFields}
        subItemFields={subItemFields}
        defaultMainValues={values}
        defaultSubItemValues={values['product_modifications']}
        submitting={submitting}
        addModificationText={localize.products.form.addModification}
        onSubmit={onSubmit}
      />
    </Page>
  );
};

export default ProductEditPage;
