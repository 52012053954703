import { apiCallWrapper } from './apiCallWrapper';

type RequestFormDetails = {
  name?: string;
  phone_number?: string;
  email?: string;
  comment?: string;
};

export const create = (details: RequestFormDetails) => {
  return apiCallWrapper(`/request_forms`, {
    method: 'POST',
    body: JSON.stringify({
      request_form: {
        ...details,
      },
    }),
  });
};
