import { AnyObject } from '../api/anyObjectTypes';

export const templatesForTypes = (
  templates: AnyObject,
  templateTypes: string[]
) =>
  templates.filter((template: any) =>
    templateTypes.includes(template?.template_type)
  );

export const fieldsForTypes = (templates: AnyObject, templateTypes: string[]) =>
  templatesForTypes(templates, templateTypes)
    .map((el: AnyObject) => el?.custom_fields)
    .flat();

export const toTemplateType = (entity: string) => {
  const res = entity
    .split('_')
    .map((s, i) => `${i === 0 ? s[0].toUpperCase() : s[0]}${s.slice(1)}`)
    .join(' ');

  return res.endsWith('s') ? res.slice(0, -1) : res;
};
