import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Tooltip from '@mui/material/Tooltip';

import { ReactComponent as ContentCopyIconSvg } from '../../assets/copy-api-key.svg';
import localize from '../../localize';

interface TextWithCopyButtonProps {
  text: string;
}

const TextWithCopyButton: React.FC<TextWithCopyButtonProps> = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const handleButtonClick = async () => {
    try {
      const textarea = document.createElement('textarea');
      textarea.value = text;
      textarea.style.position = 'fixed'; // Prevent scrolling to the textarea
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy'); // Copy the selected text

      // Clean up the temporary textarea element
      document.body.removeChild(textarea);

      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1500);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    } catch (error) {
      console.error('Failed to copy text:', error);
    }
  };

  return (
    <span>
      <span>
        {text.slice(0, 3)}*******{text.slice(-3)}
      </span>
      <Tooltip
        title={localize.api_integration.api_keys.isCopied}
        open={copied}
        placement='top'
      >
        <IconButton
          sx={{
            width: '32px',
            height: '32px',
            background: '#DFDDF9',
            padding: '0',
            ml: '10px',
          }}
          onClick={handleButtonClick}
          aria-label='Copy to clipboard'
        >
          {copied ? (
            <CheckCircleOutlineIcon color='success' />
          ) : (
            <ContentCopyIconSvg fill='#5B4D8F' />
          )}
        </IconButton>
      </Tooltip>
    </span>
  );
};

export default TextWithCopyButton;
