import { FC } from 'react';
import { CustomerKeys, Order } from '../../api/OrderApiClient';
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Link,
} from '@mui/material';
import IconWithInfo from '../../components/IconWithInfo';
import { useNavigate } from 'react-router-dom';
import DeliveryDetailsCard from './DeliveryDetailsCard';
import CustomerDetailsCard from './CustomerDetailsCard';

type ClientDetailsCardProps = {
  order: Order;
};

export const ClientDetailsCard: FC<ClientDetailsCardProps> = ({ order }) => {
  const navigate = useNavigate();

  return (
    <Card sx={{ border: '1px solid #DFDDF9', borderRadius: '8px' }}>
      <CardHeader
        titleTypographyProps={{ variant: 'h4' }}
        subheader={
          order.user_is_client
            ? 'Інформація про дропишпера, замовника, доставку та знижки'
            : 'Інформація про менеджера, замовника, доставку та знижки'
        }
        subheaderTypographyProps={{ variant: 'body2', maxWidth: '300px' }}
        action={
          order.editable_by_current_user && (
            <Button
              variant='outlined'
              onClick={() => navigate(`/cp/orders/${order.id}/edit/client`)}
            >
              Редагувати
            </Button>
          )
        }
      />
      <CardContent>
        {order.user_is_client ? (
          <>
            <Typography variant='body1'>
              Імʼя дропишпера: <b>{order.user_name}</b>
            </Typography>
            <Typography variant='body1'>
              Знижка (маржа){' '}
              <IconWithInfo text='Фіксована знижка дропшипера саме на це замовлення. Ця знижка використовувалась для обрахунку суми. Актуальну знижку дропшипера на даний момент ви можете переглянути в профілі користувача.' />
              : <b>{order.fixed_client_discount || 0}%</b>
            </Typography>
          </>
        ) : null}
        <Typography variant='body1'>
          Імʼя менеджера:{' '}
          <b>
            {order.user_is_client ? order.user_maneger_name : order.user_name}
          </b>
        </Typography>
        {order.promotion_id ? (
          <Typography variant='body1'>
            Застосована промо-акція:{' '}
            <b>
              <Link href={`/cp/promotions/${order.promotion_id}`}>
                {order.promotion_title}
              </Link>
            </b>
          </Typography>
        ) : null}
        <CustomerDetailsCard order={order} />
        <DeliveryDetailsCard order={order} />
      </CardContent>
    </Card>
  );
};

export default ClientDetailsCard;
