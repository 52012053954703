import { styled } from '@mui/material';
import React from 'react';
import { AdvantagesSectionList } from './AdvantagesSectionList';

const AdvantagesSectionContainer = styled('section')(({ theme }) => ({
  backgroundColor: '#AFABEF',
  padding: '3% 0',
  color: '#fff',
}));

const AdvantagesSectionInner = styled('div')(() => ({
  display: 'flex',
  padding: '0 20px',
  justifyContent: 'normal',
  alignItems: 'center',
  width: '1280px',
  maxWidth: '100%',
  minWidth: '700px',
  margin: '0 auto',
}));

export const AdvantagesSection = () => {
  return (
    <AdvantagesSectionContainer>
      <AdvantagesSectionInner>
        <AdvantagesSectionList />
      </AdvantagesSectionInner>
    </AdvantagesSectionContainer>
  );
};
