import { styled, Typography } from '@mui/material';
import React from 'react';
import localize from '../../../localize';
import simplicity from '../../../assets/landing/offers/simplicity.png';
import integration from '../../../assets/landing/offers/integration.png';
import statistics from '../../../assets/landing/offers/statistics.png';
import flexibility from '../../../assets/landing/offers/flexibility.png';

const OffersSectionContainer = styled('section')(({ theme }) => ({
  padding: '3% 0',
  color: '#0c0c0c',
}));

const OffersSectionInner = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 20px',
  justifyContent: 'normal',
  alignItems: 'center',
  width: '1280px',
  maxWidth: '100%',
  minWidth: '700px',
  margin: '0 auto',
}));

const OffersTitle = styled('h2')(({ theme }) => ({
  color: '#0c0c0c',
  alignSelf: 'flex-start',
  fontSize: '34px',
  fontWeight: '500',
  marginBottom: '5%',
  fontFamily: '"Montserrat", sans-serif',
}));

const offersImg = [
  {
    path: simplicity,
  },
  {
    path: integration,
  },
  {
    path: statistics,
  },
  {
    path: flexibility,
  },
];

const offersSectionLoc: { title: string; description: string }[] =
  localize.landing.offersSection.items;

const offersSectionData = offersSectionLoc.map((item, index) => ({
  ...item,
  path: offersImg[index].path,
}));

const OffersList = styled('ul')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '24px',
  justifyContent: 'normal',
  alignItems: 'center',
  padding: 0,
  margin: 0,
  listStyle: 'none',

  '& li': {
    display: 'flex',
    gap: '32px',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    borderRadius: '24px',
    boxShadow: '0px 4px 20px 0px rgba(198, 183, 255, 0.3)',

    padding: '24px 20px 48px 20px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '& h4': {
      fontWeight: 700,
      fontSize: '16px',
      color: '#7B75CB',
      fontFamily: '"Montserrat", sans-serif',
    },

    '& img': {
      maxWidth: '137px',
    },

    '& p': {
      textAlign: 'center',
    },
  },
}));

export const OffersSection = () => {
  return (
    <OffersSectionContainer>
      <OffersSectionInner>
        <OffersTitle>{localize.landing.offersSection.title}</OffersTitle>
        <OffersList>
          {offersSectionData.map(({ title, description, path }) => (
            <li key={title}>
              <img src={path} alt={title} />
              <Typography variant='h4'>{title}</Typography>
              <Typography
                variant='body1'
                component={'p'}
                sx={{
                  maxWidth: '343px',
                  fontFamily: '"Montserrat", sans-serif',
                  fontSize: '12px',
                }}
              >
                {description}
              </Typography>
            </li>
          ))}
        </OffersList>
      </OffersSectionInner>
    </OffersSectionContainer>
  );
};
