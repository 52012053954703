import { FC } from 'react';
import { List, ListItem, styled, Link } from '@mui/material';
import { useLocation } from 'react-router-dom';
type NavigationProps = {
  links: { name: string; path: string }[];
};

const StyledNavLink = styled(Link)(({ theme }) => ({
  padding: '10px 0',
  borderBottom: `2px solid transparent`,
  textDecoration: 'none',
  whiteSpace: 'nowrap',
  fontWeight: 500,
  transition: 'border .25s ease-in-out',
  color: '#0c0c0c',
  '&:hover': {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  '&.active': {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
}));
export const Navigation: FC<NavigationProps> = ({ links }) => {
  const location = useLocation();

  return (
    <nav>
      <List sx={{ display: 'inline-flex', padding: 0, marginRight: 2 }}>
        {links.map(({ name, path }) => (
          <ListItem sx={{ flex: '1 1 auto' }} key={name}>
            <StyledNavLink
              className={path === location.pathname ? 'active' : ''}
              href={path}
            >
              {name}
            </StyledNavLink>
          </ListItem>
        ))}
      </List>
    </nav>
  );
};
