import React, { Component, ErrorInfo, ReactNode } from 'react';
import { ErrorApiClient } from './api/ErrorApiClient';
import ErrorContainer from './components/ErrorContainer/ErrorContainer';
import localize from './localize';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  code: number;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null, code: 0 };
  }

  static getDerivedStateFromError(error: Error): State {
    return {
      hasError: true,
      error,
      code: parseInt(error?.message || '') || 500,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    ErrorApiClient.create({
      error: {
        message: error?.message,
      },
    });
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorContainer
          code={this.state.code}
          message={
            this.state.code === 404
              ? localize.errors.notFoundError
              : localize.errors.unknownError +
                ' ' +
                localize.errors.pleaseTryAgain
          }
        />
      );
    }

    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
