import { FC, useContext, useEffect, useState } from 'react';
import Page from '../../components/Page/Page';
import Form from '../../components/Form/Form';
import { useNavigate, useParams } from 'react-router-dom';
import { NotificationContext } from '../../contexts/NotificationContext';
import { preparedRequestBody } from '../../utils/form';
import { CommonContext } from '../../contexts/CommonContext';
import { StockApiClient } from '../../api/StockApiClient';
import Loading from '../../components/Loading/Loading';
import { AnyObject } from '../../api/anyObjectTypes';
import localize from '../../localize';

export const StockEditPage: FC = () => {
  const basicMainLabels: AnyObject = {
    count: localize.stocks.columns.count,
  };
  const { id } = useParams();
  const [values, setValues] = useState<AnyObject>({});
  const [mainFields, setMainFields] = useState<AnyObject[]>([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const { setNotification } = useContext(NotificationContext);
  const { currentProject } = useContext(CommonContext);
  const navigate = useNavigate();

  const prepareData = async (): Promise<void> => {
    if (id) {
      const res = await StockApiClient.getShow(currentProject.id, id);
      Object.keys(res['additional_fields'] || {}).forEach((afKey) => {
        res[afKey] = res['additional_fields'][afKey];
      });

      setValues(res);
    }
  };

  const onSubmit = (mainItem: AnyObject, subItems: AnyObject[]) => {
    if (!id) return;

    setSubmitting(true);
    StockApiClient.update(
      currentProject.id,
      {
        stock: preparedRequestBody({
          mainItem,
          mainFields,
          basicMainLabels,
        }),
      },
      id
    ).then(() => {
      setNotification({
        severity: 'success',
        message: localize.general.successUpdateMessage,
      });
      setSubmitting(false);
      navigate(`/cp/products/${mainItem.product?.id}`);
    });
  };

  useEffect(() => {
    if (!id) return;

    setLoading(true);
    prepareData().finally(() => setLoading(false));
  }, [id, currentProject]);

  useEffect(() => {
    setMainFields([
      ...Object.keys(basicMainLabels || {}).map((el) => ({
        editable: true,
        hidden: true,
        field_type: 'number',
        internal_name: el,
        label: basicMainLabels[el],
        parsable: false,
        required: true,
      })),
    ]);
  }, [currentProject]);

  if (loading || !Object.keys(values).length)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page title={localize.stocks.edit.title} actions={[]}>
      <Form
        mainFields={mainFields}
        defaultMainValues={values}
        submitting={submitting}
        onSubmit={onSubmit}
      />
    </Page>
  );
};

export default StockEditPage;
