import { FC, PropsWithChildren, ReactNode } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import localize from '../../localize';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '.MuiPaper-root': {
    borderRadius: '32px',
    border: '1px solid #AFABEF',
    background: '#fff',
    padding: '48px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '48px',
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  flex: 1,
  padding: '0',
}));

type ConfirmationDialogProps = {
  header: ReactNode;
  image?: string;
  open: boolean;
  description: ReactNode;
  mainActionButtonText: string;
  submitting?: boolean;
  disabled?: boolean;
  action(): void;
  discard(): void;
};

export const ConfirmationDialog: FC<
  PropsWithChildren & ConfirmationDialogProps
> = ({
  header,
  image,
  open,
  description,
  action,
  disabled,
  discard,
  mainActionButtonText,
  children,
  submitting = false,
}) => {
  return (
    <StyledDialog
      open={open}
      fullWidth={true}
      onClose={() => !submitting && discard()}
    >
      {image && (
        <Box width={120} height={120}>
          <img
            src='/delete-image.svg'
            alt='delete'
            style={{
              objectFit: 'contain',
              width: '100%',
              height: '100%',
              display: 'block',
            }}
          />
        </Box>
      )}
      <StyledDialogContent>
        <Typography variant='h2' color='#0C0C0C' mb='16px'>
          {header}
        </Typography>
        <Typography variant='h5' component='p' color='#464646' mb='16px'>
          {description}
        </Typography>
        {children}
        <Box display='flex' gap='16px' alignItems='center'>
          <Button
            variant='rounded'
            onClick={() => action()}
            disabled={submitting || disabled}
          >
            {submitting ? (
              <CircularProgress size={26} thickness={6} color='primary' />
            ) : (
              mainActionButtonText
            )}
          </Button>
          <Button
            variant='transparent'
            onClick={() => discard()}
            disabled={submitting}
          >
            {localize.general.cancel}
          </Button>
        </Box>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default ConfirmationDialog;
