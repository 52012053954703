import { FC, useMemo } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

type EmptyContentProps = {
  fullPage?: boolean;
  title?: string;
  subtitle?: string;
};

const InnerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
  justifySelf: 'center',
  flexDirection: 'column',
  minWidth: '100%',
  minHeight: '80%',
}));

const FullPageContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  minHeight: '100vh',
  minWidth: '100vw',
}));

export const EmptyContent: FC<EmptyContentProps> = ({
  title = 'Nothing here',
  subtitle = 'Just an empty page.',
  fullPage = false,
}) => {
  const Container = useMemo(
    () => (fullPage ? FullPageContainer : InnerContainer),
    [fullPage]
  );

  return (
    <Container>
      <Typography variant='h1' sx={{ opacity: '0.4' }}>
        {title}
      </Typography>
      <Typography variant='h4' sx={{ opacity: '0.4' }}>
        {subtitle}
      </Typography>
    </Container>
  );
};

export default EmptyContent;
