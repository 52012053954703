import React, { useState } from 'react';

import {
  InputBase,
  Paper,
  styled,
  IconButton,
  FormControlLabel,
  Switch,
} from '@mui/material';

import localize from '../../localize';

import { ReactComponent as SearchIconSvg } from '../../assets/search.svg';
import { ReactComponent as FilterIconSvg } from '../../assets/filter.svg';
import { ReactComponent as CheckboxIconSvg } from '../../assets/checkbox.svg';

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '30px',
}));

const StyledIconButton = styled(IconButton)(() => ({
  padding: '8px',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#fff',
  borderRadius: '8px',
  border: '1px solid #DFDDF9',
  '&.active': {
    backgroundColor: '#7B75CB',
  },
  '&:hover, &:focus': {
    border: '1px solid #7B75CB',
  },
}));

interface SearchBarProps {
  onSearch: (searchQuery: string, filters: string[]) => void;
  onFilterButtonClick: () => void;
  anyFiltersApplied?: boolean;
  onCheckboxButtonClick?: () => void;
  onChangeWithPhoto?: (isWithPhoto: boolean) => void;
  isWithPhoto?: boolean;
  isCheckbox?: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({
  onSearch,
  onFilterButtonClick,
  anyFiltersApplied,
  onCheckboxButtonClick,
  onChangeWithPhoto,
  isCheckbox,
  isWithPhoto,
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <Root>
      <Paper
        component='form'
        sx={{
          height: 40,
          mr: '20px',
          display: 'flex',
          alignItems: 'center',
          width: 228,
          backgroundColor: '#fff',
          borderRadius: '8px',
          border: '1px solid #DFDDF9',
          '&:hover, &:focus': {
            border: '1px solid #AFABEF',
          },
        }}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <IconButton type='button' aria-label='search' sx={{ p: '8px' }}>
          <SearchIconSvg />
        </IconButton>
        <InputBase
          sx={{ flex: 1 }}
          placeholder={localize.sidebar.search}
          name={localize.sidebar.search}
          value={searchQuery}
          onChange={(event) => {
            setSearchQuery(event.target.value);
            onSearch(event.target.value, []);
          }}
        />
      </Paper>
      <StyledIconButton
        type='button'
        aria-label='filter'
        onClick={onFilterButtonClick}
        sx={{ mr: '10px' }}
        className={anyFiltersApplied ? 'active' : ''}
      >
        <FilterIconSvg
          id='filter_btn'
          fill={anyFiltersApplied ? '#fff' : '#464646'}
        />
      </StyledIconButton>
      {/* <StyledIconButton
        type='button'
        aria-label='checkbox'
        sx={{ mr: 'auto' }}
        onClick={onCheckboxButtonClick}
        className={isCheckbox ? 'active' : ''}
      >
        <CheckboxIconSvg fill={isCheckbox ? '#fff' : '#464646'} />
      </StyledIconButton>
      <FormControlLabel
        control={
          <Switch
            checked={isWithPhoto}
            onChange={() => {
              onChangeWithPhoto && onChangeWithPhoto(!isWithPhoto);
            }}
          />
        }
        label={
          isWithPhoto
            ? localize.products.general.withPhoto
            : localize.products.general.withoutPhoto
        }
      /> */}
    </Root>
  );
};

export default SearchBar;
