import { FC, useContext } from 'react';
import { Drawer as MuiDrawer, IconButton } from '@mui/material';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { RightSideBarContext } from '../../contexts/RightSideBarContext';

type RightSideBarProps = {
  variant?: 'permanent' | 'persistent' | 'temporary';
};

const drawerWidth = 350;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: 'visible',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflow: 'hidden',
  width: 0,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '20px',
  position: 'absolute',
  left: '-40px',
  padding: 0,
  borderRadius: '50% 0 0 50%',
  backgroundColor: theme.palette.primary.light,
}));

const DrawerBottom = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  flex: '1 1 auto',
  width: '100%',
  paddingBottom: '15px',
}));

const DrawerContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  maxHeight: '100vh',
  overflow: 'auto',
  padding: '22px 20px 20px',
}));

const Toogler = styled('div')(({ theme }) => ({
  display: 'flex',
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  zIndex: 1,
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      backgroundColor: '#f6f6f6',
      border: 0,
      ...openedMixin(theme),
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      backgroundColor: '#f6f6f6',
      border: 0,
      ...closedMixin(theme),
    },
  }),
}));

export const RightSideBar: FC<RightSideBarProps> = ({
  variant = 'permanent',
}) => {
  const { open, setOpen, content } = useContext(RightSideBarContext);

  return (
    <Drawer variant={variant} open={open} anchor='right'>
      <DrawerHeader>
        <Toogler>
          <IconButton onClick={(e) => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Toogler>
      </DrawerHeader>
      <DrawerContent>{content}</DrawerContent>
      <DrawerBottom></DrawerBottom>
    </Drawer>
  );
};

export default RightSideBar;
